import React, { useState, useEffect, useContext } from "react";

// Whatsapp Button
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import { Container, Tooltip } from "@material-ui/core";

// Custom components
import Footer from "../../components/Footer";
import Header from "../../components/NavBar/Header";
import HeaderLinks from "../../components/NavBar/HeaderLinks";
import HeaderLinksMobile from "../../components/NavBar/HeaderLinksMobile";
import { beigeColor } from "../../styles/Style";
import ModalPricing from "../../components/Modal/ModalPricing";
import Typography from "../../components/Typography";

// Material-UI components
import Card from "./Card";
import { Grid, Paper } from "@material-ui/core";
import fire from "../../firebase/fire";
import { useSelector } from "react-redux";
import { selectUser } from "../../feature/userSlice";
import { Redirect } from "react-router-dom";
import { AuthContext } from "./../../components/Routing/Auth";
import {
  firebaseNewGetChallenge,
  firebaseNewGetProgress,
  getChallengeByTittle,
} from "../../firebase/firebaseChallenge";
import { useParams } from "react-router-dom";

// Images
const Whatsapp =
  "https://firebasestorage.googleapis.com/v0/b/sekilasaja-999fd.appspot.com/o/Website_Images%2FWeb_Picture_Components%2FWhatsapp.png?alt=media&token=88483bb9-b9d3-4aa8-9f14-9b7f91682861";

export default function Challenge({ history }) {
  const useParam = useParams();

  const [progress, setProgress] = React.useState(0);
  // Redux

  // useState Hooks
  const [pricingModal, setPricingModal] = useState(false);

  const [newListBook, setNewListBook] = React.useState([]);
  const [detailChallenge, setDetailChallenge] = React.useState({});

  const userData = useSelector(selectUser);
  const email = userData.user && userData.user.email;
  const currentUsers = React.useContext(AuthContext);

  const getProgress = async () => {
    const newGetChallengeByTittle = await getChallengeByTittle(
      useParam.category
    );
    const progress = await firebaseNewGetProgress({
      email,
      challenge: newGetChallengeByTittle.id,
    });
    setProgress(progress);
  };

  const refreshData = () => {
    getProgress();
  };

  React.useEffect(async () => {
    getProgress();
  }, []);

  React.useState(async () => {
    let listBook = [];
    await fire
      .firestore()
      .collection("books")
      .onSnapshot((snapshot) => {
        listBook = snapshot.docs.map((doc) => ({
          ...doc.data(),
        }));
      });
    const newGetChallengeByTittle = await getChallengeByTittle(
      useParam.category
    );
    const Challenge = await firebaseNewGetChallenge(
      history.location.state
        ? history.location.state.challenge
        : newGetChallengeByTittle.id
    );

    setDetailChallenge(Challenge);
    let filtersData = [];
    if (listBook.length > 0) {
      listBook.map((book) => {
        if (Challenge.books.includes(book.book_title)) {
          filtersData.push(book);
        }
      });
    }

    setNewListBook(filtersData);
  }, []);
  if (!currentUsers) {
    return <Redirect to="/login" />;
  }

  return (
    <div style={{ backgroundColor: beigeColor }}>
      <ModalPricing open={pricingModal} setOpen={setPricingModal} />

      <div style={{ marginTop: "70px" }} />
      <Header
        history={history}
        rightLinks={<HeaderLinks history={history} />}
        rightLinksMobile={<HeaderLinksMobile history={history} />}
        fixed
        color="white"
      />
      <Container maxWidth="md">
        <br />
        <br />

        <Typography style={{ marginBottom: 0 }} type="italic">
          Progress {progress.done || 0} of {newListBook.length}
        </Typography>
        <Typography style={{ marginTop: 0 }} size="heading">
          {useParam.category}
        </Typography>
        <Typography>
          {detailChallenge.descsription || "Deskripsi Category"}
        </Typography>

        <br />

        <Grid container spacing={3}>
          {newListBook &&
            newListBook.map((data, index) => {
              return (
                <Card
                  day={index + 1}
                  history={history}
                  isClear={data}
                  data={{
                    ...data,
                    detailUser: { ...userData },
                    length: newListBook.length,
                    category: useParam.category,
                  }}
                  refresh={() => refreshData()}
                />
              );
            })}
        </Grid>
      </Container>

      {/*---------------------------------------------------------------*/}
      {/*---------------------- WHATSAPP FIXED NAV ---------------------*/}
      {/*---------------------------------------------------------------*/}
      <a href="https://wa.me/6281291176795" target="_blank">
        <Tooltip
          title={
            <div
              style={{
                display: "flex",
                alignItems: "center",
                fontSize: "14px",
                lineHeight: "20px",
              }}
            >
              <WhatsAppIcon fontSize="large" style={{ marginRight: "10px" }} />
              Klik tombol ini dan langsung hubungi kami di Whatsapp bila ada
              pertanyaan!
            </div>
          }
          placement="right"
        >
          <img
            src={Whatsapp}
            style={{
              position: "fixed",
              bottom: 15,
              left: 15,
              width: "60px",
              "&:hover": {
                filter: "brightness(150%)",
              },
            }}
          />
        </Tooltip>
      </a>

      <Footer />
    </div>
  );
}
