import React, { useState, useContext } from "react";
import { Redirect, withRouter } from "react-router";

// Material UI components
import { makeStyles, TextField } from "@material-ui/core";
import { Alert } from "@material-ui/lab";

// router
import { Link } from "react-router-dom";

// Custom components
import Button from "../../components/Button";
import Typography from "../../components/Typography";
import MultiUseMobile from "../../styles/MultiUseMobile";
import { AuthContext } from "../../components/Routing/Auth";

//Import firebase for login function
import fire from "../../firebase/fire";

// context
import { ContextReact } from "../../context";
import { setModalAuth } from "../../context/action";

const auth = fire.auth();

//Form for login, including all other methods relevant to login
const LoginForm = ({ history, IconCloseModal, switchModal }) => {
  // Styles
  const multi = MultiUseMobile();

  // useState hooks
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");

  // Auth
  const { currentUser } = useContext(AuthContext);

  // context
  const context = React.useContext(ContextReact);
  const { dispatch, data } = context;

  const handleSubmit = (e) => {
    e.preventDefault();

    console.log("Logging in...");
    auth
      .signInWithEmailAndPassword(email, password)
      .then((resp) => {
        console.log("Firebase login suceeded!");
        localStorage.setItem("isLogedIn", true);
        closeModal();
      })
      .catch((err) => {
        var errorCode = err.code;
        var errorMessage = err.message;
        return setError("ERROR (" + errorCode + "): " + "\n\n" + errorMessage);
      });
  };

  const closeModal = () => {
    const { type } = data.modalAuth;
    dispatch(setModalAuth({ type, modal: false }));
  };

  if (currentUser) {
    if (window.location.pathname === "/pricing" || "/home") {
      window.scrollTo(0, 0);
      return <Redirect to={context.data.redirectTo || "/library"} />;
    } else {
      return <Redirect to="/library" />;
    }
  }

  // cek if status modal
  const isModal = IconCloseModal;

  return (
    <>
      {/* show this if is modal */}
      {isModal && <IconCloseModal />}

      <form className={multi.textFieldRoot} onSubmit={handleSubmit}>
        <div style={{ textAlign: "center" }}>
          <Typography size="subheading">Login to SekilasAja!</Typography>
        </div>

        {error && (
          <div className={multi.alertRoot}>
            <Alert severity="error">{error}</Alert>
          </div>
        )}
        <TextField
          label="Email"
          variant="filled"
          type="email"
          required
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          fullWidth
        />
        <TextField
          label="Password"
          variant="filled"
          type="password"
          required
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          fullWidth
        />
        <Button type="submit" color="primary" fullWidth round>
          Login
        </Button>

        {isModal && (
          <Typography style={{ textAlign: "center" }}>
            Belum punya akun?{" "}
            <Link
              to={isModal ? "/" : "/signup"}
              className={multi.link}
              onClick={() => {
                if (isModal) {
                  switchModal("signup");
                } else {
                  // history.push("/signup");
                  window.scrollTo(0, 0);
                }
              }}
            >
              Daftar Sekarang!
            </Link>
          </Typography>
        )}

        <Typography style={{ textAlign: "center" }}>
          <Link
            to={"/lupa-password"}
            className={multi.link}
            onClick={() => {
              closeModal();
              window.scrollTo(0, 0);
            }}
          >
            Lupa Password?
          </Link>
        </Typography>
      </form>
    </>
  );
};

export default withRouter(LoginForm);
