import React from "react";
import PropTypes from "prop-types";

// Material-UI components
import { IconButton, Backdrop, Paper, Dialog } from "@material-ui/core";
import { Close } from "@material-ui/icons";

// Springweb
import { useSpring, animated } from "@react-spring/web"; // web.cjs is required for IE 11 support

const Fade = React.forwardRef(function Fade(props, ref) {
  const { in: open, children, onEnter, onExited, ...other } = props;
  const style = useSpring({
    from: { opacity: 0 },
    to: { opacity: open ? 1 : 0 },
    onStart: () => {
      if (open && onEnter) {
        onEnter();
      }
    },
    onRest: () => {
      if (!open && onExited) {
        onExited();
      }
    },
  });

  return (
    <animated.div ref={ref} style={style} {...other}>
      {children}
    </animated.div>
  );
});

Fade.propTypes = {
  children: PropTypes.element,
  in: PropTypes.bool.isRequired,
  onEnter: PropTypes.func,
  onExited: PropTypes.func,
};

export default function ModalSekilasAja({
  statusModal,
  toggleModal,
  children,
}) {
  // Functions
  const handleClose = () => {
    toggleModal(false);
  };

  return (
    <Dialog
      open={statusModal}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
      scroll={"body"}
    >
      <Fade in={statusModal}>
        <IconButton
          onClick={() => {
            handleClose();
          }}
          style={{ position: "absolute", right: 0 }}
        >
          <Close />
        </IconButton>
        <Paper
          style={{
            padding: 20,
            height: "auto",
          }}
        >
          {children && children}
        </Paper>
      </Fade>
    </Dialog>
  );
}
