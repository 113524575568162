import fire from "./fire";

const firebaseTrackPayment = (data) => {
  return new Promise((resolve, reject) => {
    fire
      .firestore()
      .collection("dashboard")
      .doc("track_record")
      .set(
        {
          payment: {
            [data.date.getTime()]: data
          }
        },
        { merge: true }
      );
    fire
      .firestore()
      .collection("payments")
      .add(data)
      .then((res) => {
        resolve({
          status: "success",
          isSuccess: true,
          isFailed: false,
          message: "success upload payment"
        });
      })
      .catch((err) => {
        reject({
          status: "failed",
          isSuccess: false,
          isFailed: true,
          message: "failed upload payment, please check your connection"
        });
      });
  });
};

const firebaseTrackSignUp = (data) => {
  fire
    .firestore()
    .collection("dashboard")
    .doc("track_record")
    .set(
      {
        sign_up: {
          [data.date.getTime()]: data
        }
      },
      { merge: true }
    );
};

const firebaseGetSubscribeByDate = () => {
  return new Promise((resolve, reject) => {
    fire
      .firestore()
      .collection("payments")
      .where("type", "==", "tracking")
      .get()
      .then((res) => {
        let results = res.docs.map((cb) => {
          return { ...cb.data(), id: cb.id };
        });
        resolve({
          data: results,
          is_success: true,
          is_failed: false
        });
      })
      .catch((err) => {
        reject({
          data: null,
          is_success: false,
          is_failed: true
        });
      });
  });
};
export {
  firebaseTrackPayment,
  firebaseTrackSignUp,
  firebaseGetSubscribeByDate
};
