import React from "react";

// @material-ui/core components
import { makeStyles, Grid } from "@material-ui/core";
import { WhatsApp, VideoLabel } from "@material-ui/icons";

// Custom components
import Button from "../Button";
import Typography from "../Typography";

const ContactBackground =
  "https://firebasestorage.googleapis.com/v0/b/sekilasaja-999fd.appspot.com/o/Website_Images%2FWeb_Picture_Components%2Fcontact.jpg?alt=media&token=3ab740bd-1640-4479-b9d3-651bc9235d91";

export default function MentorCard({
  history,
  mentor,
  isSubscribed,
  currentUser,
}) {
  return (
    <Grid item md={4} xs={12}>
      <img
        style={{
          width: "100%",
          borderRadius: 10,
          height: 300,
          objectFit: "cover",
        }}
        src={mentor.photo || ContactBackground}
        alt={mentor.name}
      />

      <div>
        <Typography style={{ marginBottom: 0 }} size="subheading" type="bold">
          {mentor.title}
        </Typography>
        <Typography style={{ marginBottom: 0 }} type="bold">
          {mentor.name}
        </Typography>

        <Typography style={{ marginTop: 0 }} type="italic">
          {mentor.job}
        </Typography>

        <Typography>{mentor.description}</Typography>

        <br />

        <div
          style={{ border: "1px solid #41444b", borderRadius: 8, padding: 10 }}
        >
          <Typography type="bold">📅 {mentor.date}</Typography>
          <Typography type="bold">🕑 {mentor.time}</Typography>
        </div>

        <br />

        <Grid
          container
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
        >
          <Grid item>
            {mentor.videoLink !== "" ? (
              <Button
                color="secondary"
                round
                onClick={
                  isSubscribed && currentUser
                    ? () => {
                      history.push(`/mentoring/${mentor.id}`);
                      window.scrollTo(0, 0);
                    }
                    : () => {
                      history.push(`/pricing`);
                      window.scrollTo(0, 0);
                    }
                }
              >
                <VideoLabel /> Tonton Rekaman Ulang
              </Button>
            ) : (
              <Button round href={mentor.joinLink} target={"_blank"}>
                Join Group Mentoring
              </Button>
            )}
          </Grid>
        </Grid>
      </div>
    </Grid>
  );
}
