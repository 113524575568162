import React, { useState, useEffect } from "react";

// Custom components
import BookCard from "../../../components/Cards/BookCard";
import InfoStyles from "../../../styles/InfoAreaStyle";
import Loading from "../../Utilities/Loading";

// Other components
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

// Redux
import { useSelector, useDispatch } from "react-redux";
import { selectAllBooks, setAllBooks } from "../../../feature/allBooksSlice";

// Firebase components
import fire from "../../../firebase/fire";

// Material UI components
import { makeStyles } from "@material-ui/core";
import { CustomDot } from "../../../components/Carousel/CustomDot";

// Styles
const useInfoStyles = makeStyles(InfoStyles);
const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 4,
    slidesToSlide: 4, // optional, default to 1.
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 3,
    slidesToSlide: 3, // optional, default to 1.
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 2,
    slidesToSlide: 2, // optional, default to 1.
  },
};

export default function BooksBlock({}) {
  // Auth
  const db = fire.firestore();

  //For searching (Using the all books for searching)
  const dispatch = useDispatch();
  const allBooks = useSelector(selectAllBooks);

  // useState hooks
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    db.collection("books").onSnapshot((snapshot) => {
      setProducts(
        snapshot.docs.map((doc) => ({
          ...doc.data(),
        }))
      );

      //Add dispatch to store all books info for searching
      if (allBooks.length < 1) {
        dispatch(
          setAllBooks(
            snapshot.docs.map((doc) => ({
              ...doc.data(),
            }))
          )
        );
      }
    });

    return function cleanup() {
      setProducts([]);
      setLoading(true);
    };
  }, []);

  useEffect(() => {
    setLoading(false);
  }, [products]);

  if (loading) {
    return (
      <>
        <Loading />
      </>
    );
  }

  return (
    <div style={{ textAlign: "left" }}>
      <Carousel
        arrows={false}
        showDots={true}
        infinite={true}
        autoPlay={true}
        autoPlaySpeed={2500}
        ssr={true}
        responsive={responsive}
        customDot={<CustomDot />}
      >
        {products
          .filter(
            (product) => product.category.includes("New Release!") === true
          )
          .map((categorisedProduct, index) => (
            <BookCard
              chosenCategory={"New Release!"}
              key={index}
              product={categorisedProduct}
              extraSpace={<div style={{ marginTop: "20px" }} />}
            />
          ))}
      </Carousel>
    </div>
  );
}
