import React from "react";
import MultiUseMobile from "../../styles/MultiUseMobile";

export const CustomDot = ({ onClick, active }) => {
  const classes = MultiUseMobile();

  return (
    <button
      className={classes.buttonDot}
      style={{
        background: active ? "#FFE05D" : "#C4C4C4",
      }}
      onClick={() => onClick()}
    />
  );
};
