import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { Redirect } from "react-router";

// Custom pages
import LoginPage from "./pages/Login/Login";
import SignUpPage from "./pages/SignUp/SignUp";
import Home from "./pages/Home/Home";
import FourOFourPage from "./pages/Utilities/404page";
import PricingPage from "./pages/Pricing/Pricing";
import BookDetailsPage from "./pages/BookDetails/BookDetailsPage";
import KilasPage from "./pages/KilasPage/KilasPage";
import AccountsPage from "./pages/Accounts/Accounts";
import PaymentTwelveMobilePage from "./pages/Payment/paymentMobile/PaymentTwelveMobilePage";

import Library from "./pages/Library/Library";
import ForgotPassword from "./pages/ForgotPassword/ForgotPassword";
import SearchResults from "./pages/SearchResults/SearchResults";
import Blog from "./pages/Blog/Blog";
import Post from "./pages/Blog/Post";
import PostMobile from "./pages/Blog/PostMobile";
import FAQPage from "./pages/FAQ/FAQ";
import Landing from "./pages/Home/Landing";
import MentorPage from "./pages/Mentoring/MentorPage";
import MentorVideoPage from "./pages/Mentoring/MentorVideoPage";
import ProductLanding from "./pages/Home/ProductLanding";
import WebinarLanding from "./pages/Home/WebinarLanding";
import Challenge from "./pages/Challenge";
import DashboardSekilasAja from "./dashboard/page/index";
import PrivacyPolicyPage from "./pages/PrivacyPolicy/PrivacyPolicyPage";

// Routing componentns
import PrivateRoute from "./components/Routing/PrivateRoute";
import { AuthContext, AuthProvider } from "./components/Routing/Auth";

// context
import { ContextReact, initialState, reducerReact } from "./context";
import ShowAllByCategory from "./pages/Library/ShowAllByCategory";
import { setRedirectTo } from "./context/action";
import MainDrawerDashboard from "./componentDashboard/Drawer";
import BookDashboard from "./dashboard/page/book";

function App() {
  const [data, dispatch] = React.useReducer(reducerReact, initialState);
  const [isClear, setClear] = React.useState(false);

  React.useEffect(() => {
    if (!isClear) {
      clearCacheData();
    }
    setInterval(() => {
      if (
        window.location.pathname === "/payment-twelve" ||
        window.location.pathname === "/payment-three"
      ) {
        dispatch(setRedirectTo(false));
      }
    }, 1000);
  }, []);

  const clearCacheData = () => {
    var isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

    setClear(true);
  };

  const currentUser = React.useContext(AuthContext);

  let { pathname } = window.location;

  let dashboardRoute = [
    { pathname: "/dashboard", component: DashboardSekilasAja },
    { pathname: "/dashboard/book", component: BookDashboard }
  ];

  return (
    <>
      {dashboardRoute.find((pn) => pn.pathname === pathname) ? (
        <>
          <ContextReact.Provider value={{ data, dispatch }}>
            <AuthProvider>
              <Router>
                <Switch>
                  <MainDrawerDashboard>
                    <Route
                      exact={true}
                      path="/dashboard/book"
                      component={DashboardSekilasAja}
                    />
                    <Route
                      exact={true}
                      path="/dashboard"
                      component={DashboardSekilasAja}
                    />
                    {/* <Route component={FourOFourPage} /> */}
                  </MainDrawerDashboard>
                </Switch>
              </Router>
            </AuthProvider>
          </ContextReact.Provider>
        </>
      ) : (
        <>
          <ContextReact.Provider value={{ data, dispatch }}>
            <AuthProvider>
              <Router>
                <Switch>
                  {/* Private Routes (Only logged in users can see) */}
                  <PrivateRoute
                    exact
                    path="/accounts"
                    component={AccountsPage}
                  />
                  <PrivateRoute
                    exact
                    path="/kilas/:book_title"
                    component={KilasPage}
                  />
                  <PrivateRoute
                    exact
                    path="/challenge/:category"
                    component={Challenge}
                  />
                  <Route
                    exact
                    path="/payment-three-mobile/:email"
                    component={PaymentTwelveMobilePage}
                  />
                  <Route
                    exact
                    path="/payment-three-mobile-ios/:email"
                    component={PaymentTwelveMobilePage}
                  />
                  <Route
                    exact
                    path="/payment-twelve-mobile/:email"
                    component={PaymentTwelveMobilePage}
                  />
                  <Route
                    exact
                    path="/payment-twelve-mobile-ios/:email"
                    component={PaymentTwelveMobilePage}
                  />
                  {/* <PrivateRoute
                    exact
                    path="/payment-three"
                    component={PaymentThree}
                  />
                  <PrivateRoute
                    exact
                    path="/payment-twelve"
                    component={PaymentTwelve}
                  />
                  <Route
                    exact
                    path="/payment-three-new-user"
                    component={PaymentThreeNewUser}
                  />
                  <Route
                    exact
                    path="/payment-twelve-new-user"
                    component={PaymentTwelveNewUser}
                  />
                  <Route
                    exact
                    path="/payment-webinar-new-user"
                    component={PaymentWebinarNewUser}
                  />
                  <Route
                    exact
                    path="/payment-webinar"
                    component={PaymentWebinar}
                  />
                  <Route
                    exact
                    path="/payment-success-webinar/:email"
                    component={PaymentSuccessWebinar}
                  />
                  <Route
                    exact
                    path="/payment-success-webinar-2/:email"
                    component={PaymentSuccessWebinar2}
                  />
                  <Route
                    exact
                    path="/payment-three-mobile/:email"
                    component={PaymentThreeMobile}
                  />
                  <Route
                    exact
                    path="/payment-three-mobile-ios/:email"
                    component={PaymentThreeMobileIos}
                  />
                  <Route
                    exact
                    path="/payment-twelve-mobile/:email"
                    component={PaymentTwelveMobile}
                  />
                  <Route
                    exact
                    path="/payment-twelve-mobile-ios/:email"
                    component={PaymentTwelveMobileIos}
                  /> */}


                  {/* <Route
                    exact
                    path="/payment-success-three"
                    component={PaymentSuccess}
                  />
                  <Route
                    exact
                    path="/payment-success-twelve"
                    component={PaymentSuccess}
                  />
                  <Route
                    exact
                    path="/payment-success-twelve/:email"
                    component={PaymentSuccessByXendit}
                  />
                  <Route
                    exact
                    path="/payment-success-three/:email"
                    component={PaymentSuccessByXendit}
                  />
                  <Route
                    exact
                    path="/payment-success-mobile-three/:email"
                    component={PaymentSuccessMobileXendit}
                  />
                  <Route
                    exact
                    path="/payment-success-mobile-twelve/:email"
                    component={PaymentSuccessMobileXendit}
                  />
                  <Route
                    exact
                    path="/payment-success-mobile-three"
                    component={PaymentSuccessMobile}
                  />
                  <Route
                    exact
                    path="/payment-success-mobile-twelve"
                    component={PaymentSuccessMobile}
                  /> */}

                  {/* Public Routes (Everybody can see) */}
                  <Route exact path="/artikel" component={Blog} />
                  <Route path="/artikel/:slug" component={Post} />
                  <Route path="/blog/:slug" component={PostMobile} />
                  <Route exact path="/mentoring" component={MentorPage} />
                  <Route exact path="/library" component={Library} />
                  <Route
                    exact
                    path="/mentoring/:id"
                    component={MentorVideoPage}
                  />
                  <Route exact path="/faq" component={FAQPage} />
                  <Route
                    exact
                    path="/login"
                    component={
                      currentUser ? <Redirect to="/library" /> : LoginPage
                    }
                  />
                  <Route exact path="/signup" component={SignUpPage} />
                  <Route
                    exact
                    path="/lupa-password"
                    component={ForgotPassword}
                  />
                  <Route
                    exact
                    path="/searchResults/:searchValue"
                    component={SearchResults}
                  />
                  <Route
                    exact
                    path="/show-by-category/:category_name"
                    component={ShowAllByCategory}
                  />
                  <Route exact path="/pricing" component={PricingPage} />
                  <Route
                    exact
                    path="/privacy-policy"
                    component={PrivacyPolicyPage}
                  />
                  <Route
                    exact
                    path="/book-details/:book_title"
                    component={BookDetailsPage}
                  />

                  <Route
                    exact
                    path="/"
                    component={() => <Redirect to={"/home"} />}
                  />
                  <Route exact path="/home" component={Landing} />
                  <Route exact path="/home-sam" component={ProductLanding} />
                  <Route exact path="/landing1" component={ProductLanding} />
                  <Route exact path="/landing2" component={Landing} />
                  <Route exact path="/landing3" component={WebinarLanding} />

                  <Route component={FourOFourPage} />
                </Switch>
              </Router>
            </AuthProvider>
          </ContextReact.Provider>
        </>
      )}
    </>
  );
}

export default App;
