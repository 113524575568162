import React, { useContext } from "react";
import { Route, Redirect } from "react-router-dom";

// Firebase components
import { AuthContext } from "./Auth";

const PrivateRoute = ({ component: RouteComponent, ...rest }) => {
  const { currentUser } = useContext(AuthContext);

  if (!currentUser) return <Redirect to="/login" />;

  return (
    <Route
      {...rest}
      render={(routeProps) => <RouteComponent {...routeProps} />}
    />
  );
};

export default PrivateRoute;
