import React, { useState, useEffect } from "react";

// Custom components
import CategoriesStyle from "../../styles/CategoriesStyle";
import CategoryButton from "./CategoryButton";
import Button from "../Button";

// Material UI components
import { Grid } from "@material-ui/core";

//nodejs library to set properties for components
import classNames from "classnames";

//Import firebase function to get books based on filter
import * as firebaseGetBookCategories from "../.././firebase/firebaseGetBookCategories.js";

function CategoryBarFilter({
  chosenCategory,
  setChosenCategory,
  setIsChosenCategory,
}) {
  // Styles
  const classes = CategoriesStyle();
  const mobileClass = classNames({
    [classes.sectionMobile]: true,
  });
  const desktopClass = classNames({
    [classes.sectionDesktop]: true,
  });

  // useState hooks
  const [categories, setCategories] = useState([]);
  const [show, setShow] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      const results = await firebaseGetBookCategories.getBookCategories();
      setCategories(results);
    };
    fetchData();
  }, []);

  const cekArray = (arr) => {
    if (Array.isArray(arr) && arr.length > 0) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <div className="CategoryBarFilter-Panel">
      <div className={desktopClass}>
        <Grid container justifyContent="center" spacing={1}>
          {cekArray(categories) &&
            categories.map((categoryName, index) => (
              <CategoryButton
                hideCategory={categoryName == "" ? true : false}
                categoryIcon={categoryName}
                chosenCategory={chosenCategory}
                categoryName={categoryName}
                setChosenCategory={setChosenCategory}
                setIsChosenCategory={setIsChosenCategory}
              />
            ))}
        </Grid>
      </div>

      <div className={mobileClass}>
        {cekArray(categories) &&
          categories.map((categoryName, index) => (
            <>
              {index <= 3 ? (
                <CategoryButton
                  hideCategory={categoryName == "" ? true : false}
                  categoryIcon={categoryName}
                  chosenCategory={chosenCategory}
                  categoryName={categoryName}
                  setChosenCategory={setChosenCategory}
                  setIsChosenCategory={setIsChosenCategory}
                />
              ) : (
                <></>
              )}
            </>
          ))}

        {show === false && (
          <Button
            style={{ fontWeight: 500, textDecoration: "underline" }}
            onClick={() => setShow(true)}
            fullWidth
            color="transparent"
          >
            Lihat Semua Kategori ▼
          </Button>
        )}

        {show && (
          <>
            {cekArray(categories) &&
              categories.map((categoryName, index) => (
                <>
                  {index > 3 ? (
                    <CategoryButton
                      hideCategory={categoryName == "" ? true : false}
                      categoryIcon={categoryName}
                      chosenCategory={chosenCategory}
                      categoryName={categoryName}
                      setChosenCategory={setChosenCategory}
                      setIsChosenCategory={setIsChosenCategory}
                    />
                  ) : (
                    <></>
                  )}
                </>
              ))}
          </>
        )}

        {show === true && (
          <Button
            style={{ fontWeight: 500, textDecoration: "underline" }}
            onClick={() => setShow(false)}
            fullWidth
            color="transparent"
          >
            Tutup Kembali ▲
          </Button>
        )}
      </div>
    </div>
  );
}

export default CategoryBarFilter;
