import React, { useContext, useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";

// Whatsapp Button
import MultiUseMobile from "../../styles/MultiUseMobile";

// Custom components
import Typography from "../../components/Typography";
import Footer from "../../components/Footer";
import Header from "../../components/NavBar/Header";
import HeaderLinks from "../../components/NavBar/HeaderLinks";
import HeaderLinksMobile from "../../components/NavBar/HeaderLinksMobile";
import Button from "../../components/Button";
import InfoStyle from "../../styles/InfoAreaStyle";
import BenefitsBlock from "./BenefitsBlock/BenefitsBlock";
import NewKilasBlock from "./NewKilas/NewKilasBlock";
import Loading from "../Utilities/Loading";
import CategoryBlock from "./CategoryBlock/CategoryBlock";
import ComingSoonBlock from "./ComingSoonBlock/ComingSoonBlock";
import TestimonialBlock from "./TestimonialBlock/TestimonialBlock";
import CombiningTextWithUnderline from "../../components/CombiningTextWithUnderline";
import ModalPricing from "../../components/Modal/ModalPricing";
import ModalSignOut from "../../components/Modal/ModalSignOut";
import LandingCarousel from "./LandingCarousel/LandingCarousel";

// Firebase components
import { AuthContext } from "../../components/Routing/Auth";
import fire from "../../firebase/fire";

// Material-UI components
import { Container, Grid, makeStyles, Hidden } from "@material-ui/core";

// Redux
import { useSelector } from "react-redux";
import { selectUser } from "../../feature/userSlice";

// context
import { ContextReact } from "../../context";
import getBookInfoByTitle from "../../firebase/firebaseGetBookInfoByTitle";
import {
  setAudioLink,
  setBookDetail,
  setCoverLink,
  setModalAuth,
  setLogoutModal,
} from "./../../context/action";
import getBookCoverImageURL from "../../firebase/firebaseGetBookCoverImageURL";
import getBookAudioTrialURL from "../../firebase/firebaseGetBookAudioTrialURL";
import { AuthenticationArea } from "../../utils";
import { primaryColor } from "../../styles/Style";

// Images
const Whatsapp =
  "https://firebasestorage.googleapis.com/v0/b/sekilasaja-999fd.appspot.com/o/Website_Images%2FWeb_Picture_Components%2FWhatsapp.png?alt=media&token=88483bb9-b9d3-4aa8-9f14-9b7f91682861";
const AtomicHabits =
  "https://firebasestorage.googleapis.com/v0/b/sekilasaja-999fd.appspot.com/o/Book_Dashboard_Images%2FAtomic%20Habits.png?alt=media&token=bf6b087c-ce51-4b90-b329-3b91bfe6a1e3";
const TheLittleBook =
  "https://firebasestorage.googleapis.com/v0/b/sekilasaja-999fd.appspot.com/o/Book_Dashboard_Images%2FThe%20Little%20Book%20of%20Common%20Sense%20Investing.png?alt=media&token=fd281a56-a2e0-4986-a1bd-b20bafe4b069";
const PsychologyMoney =
  "https://firebasestorage.googleapis.com/v0/b/sekilasaja-999fd.appspot.com/o/Book_Cover_Images_JPG%2FGRIT%20Cover.jpg?alt=media&token=81753002-3fc6-4f57-afb5-66859069c074";
const Logo =
  "https://firebasestorage.googleapis.com/v0/b/sekilasaja-999fd.appspot.com/o/Website_Images%2FWeb_Picture_Components%2Fdark-logo.png?alt=media&token=cfd7dc4d-1687-473e-a272-4d7c66b97467";
const HomeAds =
  "https://firebasestorage.googleapis.com/v0/b/sekilasaja-999fd.appspot.com/o/Website_Images%2FWeb_Picture_Components%2Fpixel-kilas.png?alt=media&token=fdcf6052-0570-4afb-98b2-e7cafd2ac774";
const GooglePlayButton =
  "https://firebasestorage.googleapis.com/v0/b/sekilasaja-999fd.appspot.com/o/Website_Images%2FWeb_Picture_Components%2Fgoogle-play-button.png?alt=media&token=21459f8a-8481-47ee-8f91-b38e22f04a7d";
const AppStoreButton = "https://firebasestorage.googleapis.com/v0/b/sekilasaja-999fd.appspot.com/o/Website_Images%2FWeb_Picture_Components%2Fdownload-app-store.png?alt=media&token=a1ce18c2-c36f-4e53-8cc7-e56d9c3ae1c0";
const ProductArticle =
  "https://firebasestorage.googleapis.com/v0/b/sekilasaja-999fd.appspot.com/o/Website_Images%2FWeb_Picture_Components%2Fproduct-article.png?alt=media&token=1e2a7e44-1a01-4fb6-814c-81f49187dd2a";
const ProductVideo =
  "https://firebasestorage.googleapis.com/v0/b/sekilasaja-999fd.appspot.com/o/Website_Images%2FWeb_Picture_Components%2Fproduct-video.png?alt=media&token=e1fdf1db-3b02-40d1-8dd8-e0e2fb705a49";
const ProductRangkuman =
  "https://firebasestorage.googleapis.com/v0/b/sekilasaja-999fd.appspot.com/o/Website_Images%2FWeb_Picture_Components%2Fproduct-rangkuman.png?alt=media&token=0bd9c38b-2518-431c-bea4-0a913bc7de1f";
const ProductMentoring =
  "https://firebasestorage.googleapis.com/v0/b/sekilasaja-999fd.appspot.com/o/Website_Images%2FWeb_Picture_Components%2Fproduct-mentoring.png?alt=media&token=e31d8b87-725d-4da7-be0d-5ae7eb866240";
const ProductAudio =
  "https://firebasestorage.googleapis.com/v0/b/sekilasaja-999fd.appspot.com/o/Website_Images%2FWeb_Picture_Components%2Fproduct-audio.png?alt=media&token=3ca37ce5-49f5-4215-82e1-12a8bb9fa0db";
const ProductKomunitas =
  "https://firebasestorage.googleapis.com/v0/b/sekilasaja-999fd.appspot.com/o/Website_Images%2FWeb_Picture_Components%2Fproduct-komunitas.png?alt=media&token=82f4f823-73fa-4416-b745-37f47dd6b5d4";

// Styles
const useStyles = makeStyles(InfoStyle);

export default function Landing({ history }) {
  // Styles
  const books = useStyles();
  const multi = MultiUseMobile();

  // Auth
  const { currentUser } = useContext(AuthContext);
  const userData = useSelector(selectUser);
  const db = fire.firestore();

  // useState hooks
  const [pending, setPending] = useState(true);
  const [products, SetProducts] = useState([]);
  const [pricingModal, setPricingModal] = useState(false);
  const [localLogoutModal, setLocalLogoutModal] = useState(false);

  const context = React.useContext(ContextReact);
  const { dispatch } = context;

  useEffect(() => {
    // fetching book
    const fetchBook = async (nameBook) => {
      // detailbBook
      const book_ = await getBookInfoByTitle(nameBook);
      dispatch(setBookDetail({ [nameBook]: book_ }));

      // coverLink
      const cover = await getBookCoverImageURL(nameBook);
      dispatch(setCoverLink({ [nameBook]: cover }));

      // coverLink
      const audio = await getBookAudioTrialURL(nameBook);
      dispatch(setAudioLink({ [nameBook]: audio }));
    };
    const book = ["Atomic Habits", "The Little Book of Common Sense Investing"];
    book.map((data) => {
      fetchBook(data);
    });

    // Get books info
    db.collection("books").onSnapshot((snapshot) => {
      SetProducts(
        snapshot.docs.map((doc) => ({
          ...doc.data(),
        }))
      );
    });

    setLocalLogoutModal(context.data.logoutModal);
    dispatch(setLogoutModal(false));
  }, []);

  if (products && pending) {
    setPending(false);
  }

  const categoryRef = useRef();

  function handleCategoryClick() {
    categoryRef.current.scrollIntoView({ behavior: "smooth" });
  }

  if (pending) {
    return (
      <>
        <Loading />
      </>
    );
  }

  // open modal
  const redirect = () => {
    if (AuthenticationArea()) {
      history.push("/signup");
    } else {
      dispatch(setModalAuth({ type: "signup", modal: true }));
    }
  };

  const redirectPricing = () => {
    history.push("/pricing");
  };

  const onClickPricingModal = () => {
    setPricingModal(true);
  };

  return (
    <div>
      <Header
        history={history}
        rightLinks={<HeaderLinks history={history} />}
        rightLinksMobile={<HeaderLinksMobile history={history} />}
        fixed
        color="transparent"
        changeColorOnScroll={{
          height: 20,
        }}
      />

      <ModalSignOut open={localLogoutModal} setOpen={setLocalLogoutModal} />
      <ModalPricing open={pricingModal} setOpen={setPricingModal} />

      <div style={{ marginTop: "100px" }} />

      <Container maxWidth="md">
        <Grid container alignItems="center" spacing={3}>

          <Grid item md={4} xs={12}>
            {/* <img src={HomeAds} className={books.imgHomeAds} /> */}

            <Hidden smDown>
              <img src={HomeAds} className={books.imgHomeAds} />
            </Hidden>
            <Hidden mdUp>
              <div style={{ textAlign: "center" }}>
                <img src={HomeAds} className={books.imgHomeAds} />
              </div>
            </Hidden>
          </Grid>

          <Grid item md={8} xs={12}>
            <div style={{ display: "flex" }}>
              <a
                href="https://play.google.com/store/apps/details?id=com.sekilasaja&hl=en_US&gl=US"
                target="_blank"
              >
                <img
                  style={{
                    maxWidth: 180,
                  }}
                  src={GooglePlayButton}
                />
              </a>

              <a
                href="https://apps.apple.com/us/app/sekilasaja/id1606074048"
                target="_blank"
              >
                <img
                  style={{
                    maxWidth: 180,
                  }}
                  src={AppStoreButton}
                />
              </a>
            </div>
            <Typography size="heading">
              Aplikasi{" "}
              <CombiningTextWithUnderline
                text={{ underline: "Rangkuman Buku Terbaik Dunia" }}
                position="back"
              />
            </Typography>
            <Typography>
              Belajar{" "}
              <b>rangkuman buku bisnis, investasi, dan pengembangan diri</b>{" "}
              terbaik dunia hanya dalam <b>15 MENIT.</b>
            </Typography>
            {userData.user && userData.user.is_subscribed && currentUser ? (
              <></>
            ) : (
              <Button
                onClick={() => {
                  history.push("/pricing");
                  window.scrollTo(0, 0);
                }}
                round
              >
                Berlanggan Sekarang
              </Button>
            )}
          </Grid>
        </Grid>
      </Container>

      {userData.user && userData.user.is_subscribed && currentUser ? (
        <>
          <div style={{ marginTop: 30 }} />
          <Container maxWidth="md">
            <Typography style={{ textAlign: "center" }} size="heading">
              <CombiningTextWithUnderline
                text={{ underline: "Baca Buku Gratismu Sekarang!" }}
                position="back"
              />{" "}
            </Typography>
            <Grid container alignItems="center">
              <Grid item md={4} xs={12}>
                <Link
                  to={`/book-details/GRIT`}
                  onClick={() => {
                    window.scrollTo(0, 0);
                  }}
                >
                  <img
                    src={PsychologyMoney}
                    className={
                      books.imgRounded +
                      " " +
                      books.imgFluid +
                      " " +
                      books.imgNewHomeBook
                    }
                  />
                </Link>
              </Grid>
              <Grid item md={8} xs={12}>
                <Typography size="subheading">GRIT</Typography>

                <Typography>Dalam buku ini Angela Duckworth membahas penelitiannya tentang karakteristik orang-orang sukses. Dia berpendapat bahwa untuk sukses, kemauan untuk bertahan meskipun kesulitan dan gagal lebih penting dari bakat bawaan.</Typography>

                <br />

                <Button
                  onClick={() => {
                    history.push(`/book-details/GRIT`);
                    window.scrollTo(0, 0);
                  }}
                  round
                >
                  Baca Sekarang
                </Button>
              </Grid>
            </Grid>
          </Container>
          <div style={{ marginTop: 30 }} />
          <div ref={categoryRef}>
            <CategoryBlock />
          </div>
          <div style={{ marginTop: 30 }} />
          <NewKilasBlock products={products} />
          <div style={{ marginTop: 30 }} />
          <TestimonialBlock />
        </>
      ) : (
        <>
          <BenefitsBlock
            logo={
              <img
                src={Logo}
                style={{ marginBottom: -23 }}
                className={books.imgLogoText}
              />
            }
          />

          <Container maxWidth="md">
            {currentUser ? (
              <Typography style={{ textAlign: "center" }} size="heading">
                <CombiningTextWithUnderline
                  text={{ underline: "Baca Buku Gratismu Sekarang!" }}
                  position="back"
                />{" "}
              </Typography>) : (
              <Typography style={{ textAlign: "center" }} size="heading">
                <CombiningTextWithUnderline
                  text={{ underline: "Dapatkan Buku Gratis Dengan Mendaftar!" }}
                  position="back"
                />{" "}
              </Typography>)}

            <Grid container alignItems="center">
              <Grid item md={4} xs={12}>
                <Link
                  to={`/book-details/GRIT`}
                  onClick={() => {
                    window.scrollTo(0, 0);
                  }}
                >
                  <img
                    src={PsychologyMoney}
                    className={
                      books.imgRounded +
                      " " +
                      books.imgFluid +
                      " " +
                      books.imgNewHomeBook
                    }
                  />
                </Link>
              </Grid>
              <Grid item md={8} xs={12}>
                <Typography size="subheading">GRIT</Typography>

                <Typography>Dalam buku ini Angela Duckworth membahas penelitiannya tentang karakteristik orang-orang sukses. Dia berpendapat bahwa untuk sukses, kemauan untuk bertahan meskipun kesulitan dan gagal lebih penting dari bakat bawaan.</Typography>

                <br />

                {currentUser ? (
                  <Button
                    onClick={() => {
                      history.push(`/book-details/GRIT`);
                      window.scrollTo(0, 0);
                    }}
                    round
                  >
                    Baca Sekarang
                  </Button>) : (
                  <Button
                    onClick={() => {
                      redirect();
                    }}
                    round
                  >
                    Daftar Sekarang
                  </Button>)}
              </Grid>
            </Grid>
          </Container>

          <div style={{ marginTop: 30 }} />

          <div ref={categoryRef}>
            <CategoryBlock />
          </div>

          <div style={{ marginTop: 30 }} />

          <NewKilasBlock products={products} />
          {/* 
          <div style={{ marginTop: 30 }} />

          <ComingSoonBlock products={products} /> */}

          <div style={{ marginTop: 30 }} />

          <Container maxWidth="md" style={{ textAlign: "center" }}>
            <div
              // style={{
              //   border: "2px solid #41444b",
              //   borderRadius: 30,
              //   padding: 20,
              // }}
              elevation={0}
            >
              <Typography size="heading">
                <CombiningTextWithUnderline
                  text={{ underline: "Kamu Akan Mendapatkan Semua Ini" }}
                  position="back"
                />
              </Typography>

              <Grid container justifyContent="center" spacing={5}>
                <Grid item xs={12} md={6}>
                  <img src={ProductRangkuman} className={multi.picSize} />
                  <Typography size="subheading">
                    ✅ 100+ RANGKUMAN BUKU
                  </Typography>{" "}
                  <Typography>
                    Kami sudah merangkum 100+ buku bisnis, investasi, pengembangan
                    diri, dan berbagai kategori lainnya terbaik dunia hanya dalam 15
                    menit!
                  </Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                  <img src={ProductVideo} className={multi.picSize} />
                  <Typography size="subheading">✅ 50+ VIDEO</Typography>{" "}
                  <Typography>
                    Buat kamu yang visual learner, kami juga menyediakan versi video
                    di buku top seller dunia!
                  </Typography>
                </Grid>

                <Grid item xs={12} md={6}>
                  <img src={ProductAudio} className={multi.picSize} />
                  <Typography size="subheading">✅ 100+ AUDIO</Typography>{" "}
                  <Typography>
                    Dengan 100+ audio, kamu bisa habiskan satu buku samabil nyetir,
                    nge-gym, masak, lari, ngapain aja deh pokoknya!
                  </Typography>
                </Grid>

                {/* <Grid item xs={12} md={6}>
                  <img src={ProductMentoring} className={multi.picSize} />
                  <Typography size="subheading">✅ GROUP MENTORING</Typography>
                  <Typography>
                    Group mentoring secara LIVE dan bisa tanya jawab langsung
                    bersama orang-orang hebat. Rekamannya juga disimpen kok kalo
                    kamu nggak bisa dateng!
                  </Typography>
                  <Typography type="bold">Rp. 3.000.000</Typography>
                </Grid> */}

                <Grid item xs={12} md={6}>
                  <img src={ProductKomunitas} className={multi.picSize} />
                  <Typography size="subheading">
                    ✅ KOMUNITAS EKSKLUSIF
                  </Typography>{" "}
                  <Typography>
                    Males belajar? Ada komunitas eksklusif yang bisa mendorong
                    motivasi kamu setiap hari. Kesuksesan dipengaruhi oleh dengan
                    siapa kamu bergaul!
                  </Typography>
                </Grid>

                <Grid item xs={12} md={6}>
                  <img src={ProductArticle} className={multi.picSize} />
                  <Typography size="subheading">
                    ✅ 50+ ARTIKEL PEMBELAJARAN
                  </Typography>
                  <Typography>
                    Selain baca buku, terdapat berbagai artikel yang bisa menambah
                    wawasan kamu juga!
                  </Typography>
                </Grid>

                <Grid item xs={12}>
                  <Button onClick={() => {
                    history.push("/pricing");
                    window.scrollTo(0, 0);
                  }} round>
                    Berlanggan Sekarang!
                  </Button>
                </Grid>
              </Grid>
            </div>
          </Container>

          <div style={{ marginTop: 30 }} />

          <TestimonialBlock />

          {/* <Container style={{ textAlign: "center" }}>
            <div style={{ marginTop: 30 }} />
            <Grid container>
              <Grid md={3} xs={12}>
                <Hidden smDown>
                  <Link
                    to={`/book-details/The Little Book of Common Sense Investing`}
                    onClick={() => {
                      window.scrollTo(0, 0);
                    }}
                  >
                    <img
                      src={TheLittleBook}
                      className={
                        books.imgRounded +
                        " " +
                        books.imgFluid +
                        " " +
                        books.imgNewHomeBook
                      }
                    />
                  </Link>
                </Hidden>

                <Grid container>
                  <Grid item xs={12}>
                    <Hidden mdUp>
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <Link
                          to={`/book-details/The Little Book of Common Sense Investing`}
                          onClick={() => {
                            window.scrollTo(0, 0);
                          }}
                        >
                          <img
                            src={TheLittleBook}
                            className={
                              books.imgRounded +
                              " " +
                              books.imgFluid +
                              " " +
                              books.imgNewHomeBook
                            }
                          />
                        </Link>
                        <Link
                          to={`/book-details/Atomic Habits`}
                          onClick={() => {
                            window.scrollTo(0, 0);
                          }}
                        >
                          <img
                            src={AtomicHabits}
                            className={
                              books.imgRounded +
                              " " +
                              books.imgFluid +
                              " " +
                              books.imgNewHomeBook
                            }
                          />
                        </Link>
                      </div>
                    </Hidden>
                  </Grid>
                </Grid>
              </Grid>

              <Grid md={6} xs={12}>
                <Typography size="heading">
                  Daftar sekarang dan{" "}
                  <CombiningTextWithUnderline
                    text={{ underline: "dapatkan kedua buku" }}
                    position="back"
                  />{" "}
                  ini gratis!
                </Typography>
                <Typography>
                  Belajar rangkuman buku{" "}
                  <strong>bisnis, investasi, dan pengembangan diri</strong>{" "}
                  terbaik dunia hanya dalam 15 menit.
                </Typography>
                <Button
                  onClick={() => {
                    history.push("/pricing");
                    window.scrollTo(0, 0);
                  }}
                  round
                >
                  Berlanggan Sekarang
                </Button>
              </Grid>

              <Grid md={3}>
                <Hidden smDown>
                  <Link
                    to={`/book-details/Atomic Habits`}
                    onClick={() => {
                      window.scrollTo(0, 0);
                    }}
                  >
                    <img
                      src={AtomicHabits}
                      className={
                        books.imgRounded +
                        " " +
                        books.imgFluid +
                        " " +
                        books.imgNewHomeBook
                      }
                    />
                  </Link>
                </Hidden>
              </Grid>
            </Grid>
          </Container> */}
        </>
      )}

      {/*---------------------------------------------------------------*/}
      {/*---------------------- WHATSAPP FIXED NAV ---------------------*/}
      {/*---------------------------------------------------------------*/}
      <a href="https://wa.me/6281291176795" target="_blank">
        <div className={multi.whatsapp}>
          <Grid container spacing={3}>
            <Grid item xs={4}>
              <img
                style={{
                  width: "60px",
                }}
                src={Whatsapp}
              />
            </Grid>

            <Grid item xs={4}>
              <Typography type="bold">Butuh Bantuan?</Typography>
            </Grid>
          </Grid>
        </div>
      </a>
      <Footer />
    </div>
  );
}
