import * as React from "react";

import PropTypes from "prop-types";
import { useState } from "react";

// material-ui
import { styled, useTheme, withStyles } from "@material-ui/styles";
import {
  Avatar,
  Box,
  Grid,
  Menu,
  MenuItem,
  Typography,
  Tooltip,
  Button,
} from "@material-ui/core";

// project imports
import MainCard from "../../ui-component/cards/MainCard";
import SkeletonEarningCard from "../../ui-component/cards/Skeleton/EarningCard";

// assets
import { SubscriptionsRounded } from "@material-ui/icons";
import TableSubscribe from "./subContent/TableSubscribe.jsx";

const CardWrapper = styled(MainCard)(({ theme }) => ({
  backgroundColor: theme.palette.secondary.dark,
  color: "#fff",
  overflow: "hidden",
  position: "relative",
  "&:after": {
    content: '""',
    position: "absolute",
    width: 210,
    height: 210,
    background: theme.palette.secondary[800],
    borderRadius: "50%",
    top: -85,
    right: -95,
    [theme.breakpoints.down("sm")]: {
      top: -105,
      right: -140,
    },
  },
  "&:before": {
    content: '""',
    position: "absolute",
    width: 210,
    height: 210,
    background: theme.palette.secondary[800],
    borderRadius: "50%",
    top: -125,
    right: -15,
    opacity: 0.5,
    [theme.breakpoints.down("sm")]: {
      top: -155,
      right: -70,
    },
  },
  cursor: "pointer",
}));

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
    minWidth: "max-content",
  },
}))(Tooltip);

// ===========================|| DASHBOARD DEFAULT - EARNING CARD ||=========================== //

const SubscriptionCard = ({ isLoading, subsUnder7, data }) => {
  const theme = useTheme();

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      {isLoading ? (
        <SkeletonEarningCard />
      ) : (
        <>
          <HtmlTooltip
            interactive
            title={
              <React.Fragment>
                <TableSubscribe content={data} />
              </React.Fragment>
            }
          >
            <CardWrapper border={false} content={false}>
              <Box sx={{ p: 2.25 }}>
                <Grid container direction="column">
                  <Grid item>
                    <Grid container justifyContent="space-between">
                      <Grid item>
                        <Avatar
                          variant="rounded"
                          sx={{
                            ...theme.typography.commonAvatar,
                            ...theme.typography.largeAvatar,
                            backgroundColor: theme.palette.secondary[800],
                            mt: 1,
                          }}
                        >
                          <SubscriptionsRounded />
                        </Avatar>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Grid container alignItems="center">
                      <Grid item>
                        <Typography
                          sx={{
                            fontSize: "2.125rem",
                            fontWeight: 500,
                            mr: 1,
                            mt: 1.75,
                            mb: 0.75,
                          }}
                        >
                          {subsUnder7 || 0}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item sx={{ mb: 1.25 }}>
                    <Typography
                      sx={{
                        fontSize: "1rem",
                        fontWeight: 500,
                        color: theme.palette.secondary[200],
                      }}
                    >
                      Total Subscribe Under 7
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
            </CardWrapper>
          </HtmlTooltip>
        </>
      )}
    </>
  );
};

SubscriptionCard.propTypes = {
  isLoading: PropTypes.bool,
};

export default SubscriptionCard;
