import React, { useState, useEffect } from "react";

// Custom components
import TextReadingStyle from "../../styles/TextReadingStyle";

// icon
import VideocamIcon from "@material-ui/icons/Videocam";

//nodejs library to set properties for components
import classNames from "classnames";
import { IconButton, Toolbar } from "@material-ui/core";
import fire from "./../../firebase/fire";
import { selectUser } from "../../feature/userSlice";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

function ChapterButton({
  chosenChapter,
  setChosenChapter,
  chapter,
  type,
  coverLink,
}) {
  // Styles
  const classes = TextReadingStyle();

  // hooks router dom
  const param = useParams();

  // Auth
  const userData = useSelector(selectUser);

  // useState hooks
  const [isSelected, setIsSelected] = useState(false);

  // Variables
  const isVideo = type === "video";

  useEffect(() => {
    if (isVideo) {
      if (chosenChapter === 0) {
        setIsSelected(true);
      } else {
        setIsSelected(false);
      }
    } else {
      if (chosenChapter === chapter.content.kilas) {
        setIsSelected(true);
      } else {
        setIsSelected(false);
      }
    }
  }, [chosenChapter]);

  var btnClass = classNames(classes.chapter, {
    [`${classes.selectedChapter}`]: isSelected,
  });

  const setHistoryReadKilas = () => {
    fire
      .firestore()
      .collection("lastReadBook")
      .doc(userData.user.email.toLowerCase())
      .set({
        book: {
          book: param.book_title,
          kilas: chapter.content.kilas,
          book_cover: coverLink || "",
        },
      });
  };

  const removeHistoryReadKilas = () => {
    fire
      .firestore()
      .collection("lastReadBook")
      .doc(userData.user.email.toLowerCase())
      .delete();
  };

  return (
    <div
      onClick={(e) => {
        e.preventDefault();
        if (isVideo) {
          setChosenChapter(0);
          removeHistoryReadKilas();
          window.scrollTo(0, 0);
        } else if (chapter.content.title === undefined) {
          setChosenChapter(chapter.content.kilas);
          removeHistoryReadKilas();
          window.scrollTo(0, 0);
        } else {
          setHistoryReadKilas();
          setChosenChapter(chapter.content.kilas);
          window.scrollTo(0, 0);
        }
      }}
      className={btnClass}
    >
      {isVideo ? (
        <Toolbar style={{ padding: "0px", margin: "0px", minHeight: "0px" }}>
          <IconButton edge="start" color="inherit" aria-label="menu">
            <VideocamIcon />
          </IconButton>
          <div>{chapter}</div>
        </Toolbar>
      ) : (
        <div className={isSelected ? classes.visible : classes.lineClamp}>
          {chapter.content.title === undefined
            ? "Ringkasan Akhir"
            : "Kilas #" + chapter.content.kilas + " : " + chapter.content.title}
        </div>
      )}
    </div>
  );
}

export default ChapterButton;
