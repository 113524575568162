import React from "react";
import { useHistory } from "react-router";

// Material-UI components
import {
  IconButton,
  CssBaseline,
  Grid,
  Container,
  Link,
} from "@material-ui/core";
import { Instagram } from "@material-ui/icons";

// Custom components
import Typography from "./Typography";
import FooterStyle from "../styles/FooterStyle";

// nodejs library to set properties for components
import classNames from "classnames";
import { beigeColor } from "../styles/Style";

// Images
const Logo =
  "https://firebasestorage.googleapis.com/v0/b/sekilasaja-999fd.appspot.com/o/Website_Images%2FWeb_Picture_Components%2Fyellow-logo.png?alt=media&token=7483e708-574b-455d-9128-b03fe6b0e4e2";

function SocialIcons() {
  // Styles
  const classes = FooterStyle();
  const mobileClass = classNames({
    [classes.sectionMobile]: true,
  });
  const desktopClass = classNames({
    [classes.sectionDesktop]: true,
  });

  return (
    <div>
      <div className={desktopClass}>
        <IconButton
          href="https://www.instagram.com/sekilasajacom"
          edge="start"
          size="small"
          className={classes.socialIcon}
          color={beigeColor}
          aria-label="menu"
        >
          <Instagram className={classes.iconColor} />{" "}
          <Typography
            className={classes.footerLinks}
            style={{ marginLeft: "5px" }}
            color="beigeColor"
            small
          >
            sekilasajacom
          </Typography>
        </IconButton>
      </div>

      <div className={mobileClass}>
        <div className={classes.topSpacing}>
          <IconButton
            href="https://www.instagram.com/sekilasajacom"
            edge="start"
            size="small"
            className={classes.socialIcon}
            color="inherit"
            aria-label="menu"
          >
            <Instagram className={classes.iconColor} />
            <Typography
              className={classes.footerLinks}
              style={{ marginLeft: "5px" }}
              color="beigeColor"
              small
            >
              sekilasajacom
            </Typography>
          </IconButton>
        </div>
      </div>
    </div>
  );
}

function Copyright() {
  // Styles
  const classes = FooterStyle();
  const mobileClass = classNames({
    [classes.sectionMobile]: true,
  });
  const desktopClass = classNames({
    [classes.sectionDesktop]: true,
  });

  return (
    <div>
      <div className={desktopClass}>
        <Typography color="beigeColor" size="small">
          {"Copyright © "}
          {"SekilasAja! "}
          {new Date().getFullYear()}
          {"."}
        </Typography>
      </div>

      <div className={mobileClass}>
        <Typography color="beigeColor" size="small">
          {"Copyright © "}
          {"SekilasAja! "}
          {new Date().getFullYear()}
          {"."}
        </Typography>
      </div>
    </div>
  );
}

function FooterComponents() {
  // Styles
  const classes = FooterStyle();
  const iconClass = classNames({
    [classes.icon]: true,
  });

  // navigation
  const history = useHistory();

  return (
    <Grid justifyContent="space-between" spacing={3} container>
      <Grid item md={3} xs={12}>
        <a
          style={{
            cursor: "pointer",
          }}
          onClick={() => {
            history.push("/home");

            window.scrollTo(0, 0);
          }}
        >
          <img className={iconClass} src={Logo} />
        </a>

        {/* <Typography color="beigeColor">
            Bantuin Kamu Ngerti Isi Buku <div>HANYA Dalam 15 Menit!</div>
          </Typography> */}
        <SocialIcons />
      </Grid>

      <Grid item md={3} xs={12}>
        <Typography color="beigeColor" size="subheading">
          Fitur-fitur
        </Typography>
        <Link
          style={{ cursor: "pointer" }}
          onClick={() => {
            history.push("/library");
            window.scrollTo(0, 0);
          }}
          underline="none"
        >
          <Typography className={classes.footerLinks} color="beigeColor">
            Kilas
          </Typography>
        </Link>
        <Link
          style={{ cursor: "pointer" }}
          onClick={() => {
            history.push("/mentoring");
            window.scrollTo(0, 0);
          }}
          underline="none"
        >
          <Typography className={classes.footerLinks} color="beigeColor">
            Group Mentoring
          </Typography>
        </Link>
        <Link
          style={{ cursor: "pointer" }}
          onClick={() => {
            history.push("/artikel");
            window.scrollTo(0, 0);
          }}
          underline="none"
        >
          <Typography className={classes.footerLinks} color="beigeColor">
            Artikel Pembelajaran
          </Typography>
        </Link>
      </Grid>

      <Grid item md={3} xs={12}>
        <Typography color="beigeColor" size="subheading">
          Bantuan & Panduan
        </Typography>
        <Link
          underline="none"
          href="https://docs.google.com/forms/d/1SVfaa6G8yOglsZCMelTjslwGrbnkKuOQ6u0Xo6F-Tws/edit"
          target="_blank"
        >
          <Typography className={classes.footerLinks} color="beigeColor">
            Kirim Feedback
          </Typography>
        </Link>
        <Link underline="none">
          <Typography color="beigeColor">
            Hubungi kami jika ada pertanyaan atau masalah di{" "}
            <a
              style={{
                color: beigeColor,
                textDecoration: "none",
                fontStyle: "italic",
              }}
              href="mailto:hi@sekilasaja.com"
            >
              <div className={classes.footerLinks}>hi@sekilasaja.com</div>
            </a>
          </Typography>
        </Link>
        <Link
          style={{ cursor: "pointer" }}
          onClick={() => {
            history.push("/privacy-policy");
            window.scrollTo(0, 0);
          }}
          underline="none"
        >
          <Typography className={classes.footerLinks} color="beigeColor">
            Kebijakan Privasi
          </Typography>
        </Link>
      </Grid>

      <Grid item md={3} xs={12}>
        <Typography color="beigeColor" size="subheading">
          Link Penting
        </Typography>
        <Link
          href="/pricing"
          style={{ cursor: "pointer" }}
          onClick={() => {
            window.scrollTo(0, 0);
          }}
          underline="none"
        >
          <Typography className={classes.footerLinks} color="beigeColor">
            Berlanggan Sekarang
          </Typography>
        </Link>
        <Link
          href="/faq"
          style={{ cursor: "pointer" }}
          onClick={() => {
            window.scrollTo(0, 0);
          }}
          underline="none"
        >
          <Typography className={classes.footerLinks} color="beigeColor">
            FAQ
          </Typography>
        </Link>
      </Grid>
    </Grid>
  );
}

export default function Footer() {
  // Styles
  const classes = FooterStyle();
  const mobileClass = classNames({
    [classes.sectionMobile]: true,
  });
  const desktopClass = classNames({
    [classes.sectionDesktop]: true,
  });
  const footerClass = classNames({
    [classes.footer]: true,
  });

  return (
    <footer className={footerClass}>
      <CssBaseline />
      <Container>
        <div className={desktopClass}>
          <FooterComponents />
        </div>

        <div className={mobileClass}>
          <FooterComponents />
        </div>

        <Copyright />
      </Container>
    </footer>
  );
}
