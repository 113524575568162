import React from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";

// Material-UI components
import {
  IconButton,
  Backdrop,
  Card,
  CardHeader,
  Grid,
  Container,
  CardContent,
  Paper,
  Dialog,
} from "@material-ui/core";
import { Close } from "@material-ui/icons";

// Custom components
import Typography from "../../components/Typography";
import Button from "../../components/Button";
import PricingStyles from "../../styles/PricingStyle";

// Springweb
import { useSpring, animated } from "@react-spring/web"; // web.cjs is required for IE 11 support

const Fade = React.forwardRef(function Fade(props, ref) {
  const { in: open, children, onEnter, onExited, ...other } = props;
  const style = useSpring({
    from: { opacity: 0 },
    to: { opacity: open ? 1 : 0 },
    onStart: () => {
      if (open && onEnter) {
        onEnter();
      }
    },
    onRest: () => {
      if (!open && onExited) {
        onExited();
      }
    },
  });

  return (
    <animated.div ref={ref} style={style} {...other}>
      {children}
    </animated.div>
  );
});

Fade.propTypes = {
  children: PropTypes.element,
  in: PropTypes.bool.isRequired,
  onEnter: PropTypes.func,
  onExited: PropTypes.func,
};

export default function ModalPricing({ open, setOpen }) {
  // Styles
  const classes = PricingStyles();

  // History
  const history = useHistory();

  // Functions
  const handleClose = () => {
    setOpen(false);
  };

  const tiers = [
    {
      title: "12 Bulan",
      monthlyPrice: "30.000",
      hemat: "Hemat Rp. 180.000",
      disclaimer: "* Pembayaran langsung 12 bulan di depan",
      buttonText: "Berlanggan Sekarang!",
      route: "/payment-twelve",
    },
    // {
    //   title: "1 Bulan",
    //   monthlyPrice: "50.000",
    //   disclaimer: "",
    //   buttonText: "Berlanggan Sekarang!",
    //   route: "/payment-three",
    // },
  ];

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
      scroll={"body"}
    >
      <IconButton
        onClick={() => {
          handleClose();
        }}
        style={{ position: "absolute", right: 0 }}
      >
        <Close />
      </IconButton>
      <Fade in={open}>
        <Paper
          style={{
            padding: 20,
            width: "100%",
            height: "auto",
          }}
        >
          <Container style={{ textAlign: "center" }}>
            <Typography size="heading">
              <strong className={classes.underline}>Berlangganan</strong>, Yuk!
            </Typography>
            <Typography
              style={{
                textAlign: "center",
                textTransform: "uppercase",
                marginTop: "0px",
                letterSpacing: "3px",
                fontWeight: 700,
                fontSize: 20,
              }}
            >
              <strong>
                <strong
                  // className={classes.underline}
                  style={{ fontFamily: "Montserrat" }}
                >
                  30++ kilas baru
                </strong>{" "}
                setiap bulannya!
              </strong>
            </Typography>
            <Container maxWidth="sm">
              <Typography style={{ fontSize: 20, marginBottom: 40 }}>
                Bergabung ke dalam <strong>komunitas eksklusif</strong> dan
                dapatkan akses ke{" "}
                <strong>semua kilas teks, audio, dan video</strong> dari
                SekilasAja!
              </Typography>
            </Container>
            <Grid container spacing={1} alignItems="center">
              {tiers.map((tier) => (
                <Grid
                  style={{
                    marginBottom: "10px",
                    maxWidth: "450px"
                  }}
                  item
                  key={tier.title}
                  xs={12}
                  md={6}
                  className={classes.cardHover}
                >
                  <Card variant="outlined" style={{ borderRadius: 30 }}>
                    <CardHeader
                      title={
                        <Typography
                          size="subheading"
                          style={{ marginBottom: 5 }}
                        >
                          {tier.title}
                        </Typography>
                      }
                      titleTypographyProps={{ align: "center" }}
                      subheaderTypographyProps={{ align: "center" }}
                      className={
                        tier.title === "12 Bulan"
                          ? classes.cardHeader
                          : classes.cardHeaderWhite
                      }
                    />
                    <CardContent>
                      <div style={{ textAlign: "center" }}>
                        <Typography
                          type="italic"
                          style={{
                            marginBottom: 0,
                          }}
                        >
                          {tier.hemat}
                        </Typography>

                        <div className={classes.cardPricing}>
                          <Typography
                            type="subheading"
                            style={{
                              marginTop: 0,
                              fontSize: "24px",
                            }}
                          >
                            Rp. {tier.monthlyPrice}
                          </Typography>
                          <Typography type="italic">/bulan</Typography>
                        </div>

                        <Typography style={{ fontSize: "12px" }} type="italic">
                          {tier.disclaimer}
                        </Typography>
                      </div>

                      <Button
                        onClick={() => {
                          history.push(tier.route);
                          window.scrollTo(0, 0);
                        }}
                        round
                        fullWidth
                        color={tier.title === "12 Bulan" ? "primary" : "gray"}
                      >
                        {tier.buttonText}
                      </Button>
                    </CardContent>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </Container>
        </Paper>
      </Fade>
    </Dialog>
  );
}
