import React from "react";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";

// Material-UI components
import {
  IconButton,
  Backdrop,
  Paper,
  Dialog,
  Grid,
  Container,
  makeStyles,
} from "@material-ui/core";
import { Close } from "@material-ui/icons";

// Custom components
import Typography from "../Typography";
import Button from "../Button";
import CombiningTextWithUnderline from "../CombiningTextWithUnderline";
import InfoStyle from "../../styles/InfoAreaStyle";

// Springweb
import { useSpring, animated } from "@react-spring/web"; // web.cjs is required for IE 11 support

// Other
import PropTypes from "prop-types";

// Images
const AtomicHabits =
  "https://firebasestorage.googleapis.com/v0/b/sekilasaja-999fd.appspot.com/o/Book_Dashboard_Images%2FAtomic%20Habits.png?alt=media&token=bf6b087c-ce51-4b90-b329-3b91bfe6a1e3";
const TheLittleBook =
  "https://firebasestorage.googleapis.com/v0/b/sekilasaja-999fd.appspot.com/o/Book_Dashboard_Images%2FThe%20Little%20Book%20of%20Common%20Sense%20Investing.png?alt=media&token=fd281a56-a2e0-4986-a1bd-b20bafe4b069";

// Styles
const useStyles = makeStyles(InfoStyle);

const Fade = React.forwardRef(function Fade(props, ref) {
  const { in: open, children, onEnter, onExited, ...other } = props;
  const style = useSpring({
    from: { opacity: 0 },
    to: { opacity: open ? 1 : 0 },
    onStart: () => {
      if (open && onEnter) {
        onEnter();
      }
    },
    onRest: () => {
      if (!open && onExited) {
        onExited();
      }
    },
  });

  return (
    <animated.div ref={ref} style={style} {...other}>
      {children}
    </animated.div>
  );
});

Fade.propTypes = {
  children: PropTypes.element,
  in: PropTypes.bool.isRequired,
  onEnter: PropTypes.func,
  onExited: PropTypes.func,
};

export default function ModalFreeBooks({ open, setOpen }) {
  // Styles
  const books = useStyles();

  // History
  const history = useHistory();

  // Functions
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
      scroll={"body"}
    >
      <Fade in={open}>
        <IconButton
          onClick={() => {
            handleClose();
          }}
          style={{ position: "absolute", right: 0 }}
        >
          <Close />
        </IconButton>
        <Paper
          style={{
            padding: 20,
            width: "100%",
            height: "auto",
          }}
        >
          <Container style={{ textAlign: "center" }}>
            <Grid container>
              {/* <Grid item xs={12}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    maxWidth: 400,
                  }}
                >
                  <img
                    src={TheLittleBook}
                    className={books.imgModalFreeBooks}
                  />
                  <img src={AtomicHabits} className={books.imgModalFreeBooks} />
                </div>
              </Grid> */}
              <Grid item xs={12}>
                <Typography size="subheading">
                  Daftar sekarang dan{" "}
                  <CombiningTextWithUnderline
                    text={{ underline: "dapatkan dua kilas" }}
                    position="back"
                  />{" "}
                  gratis!
                </Typography>
                <Typography>
                  Belajar rangkuman buku{" "}
                  <strong>bisnis, investasi, dan pengembangan diri</strong>{" "}
                  terbaik dunia hanya dalam 15 menit.
                </Typography>
                <Button
                  fullWidth
                  onClick={() => {
                    history.push("/signup");
                    window.scrollTo(0, 0);
                  }}
                  round
                >
                  Daftar Sekarang
                </Button>
              </Grid>
            </Grid>
          </Container>
        </Paper>
      </Fade>
    </Dialog>
  );
}
