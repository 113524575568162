// Material-UI components
import { makeStyles } from "@material-ui/core/styles";
import { primaryColor, secondaryColor } from "./Style";

const TextReadingStyle = makeStyles((theme) => ({
  // small: 600px; md, medium: 960px; lg, large: 1280px
  sectionDesktop: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
  },
  // small: 600px; md, medium: 960px; lg, large: 1280px
  sectionMobile: {
    display: "flex",
    justifyContent: "center",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  container: {
    display: "flex",
    margin: "auto",
    padding: "20px",
    width: "80%",
    flex: "1",
    fontSize: "1em",
    textAlign: "center",
    flexDirection: "column",
    color: secondaryColor,
    justifyContent: "space-between",
    textAlign: "left",
  },
  book_title: {
    fontSize: "1.5em",
    fontWeight: "700",
    marginLeft: "20px",
    textAlign: "center",
  },
  paragraph: {
    // fontFamily: "Lexend Deca",
    fontSize: "18px",
    marginBottom: "30px",
    userSelect: "none",
    pointerEvents: "none",
  },
  paragraphBookDetails: {
    fontSize: "18px",
    userSelect: "none",
    pointerEvents: "none",
  },
  lineClamp: {
    display: "-webkit-box",
    "-webkit-line-clamp": 2,
    "-webkit-box-orient": "vertical",
    overflow: "hidden",
  },
  visible: {},
  page: {
    display: "flex",
    alignItems: "flex-start",
  },
  tableOfContent: {
    marginTop: "40px",
    marginBottom: "90px",
  },
  tableOfContentMobile: {
    marginTop: "40px",
    marginBottom: "100px",
    flex: "0.4",
  },
  chapter: {
    // fontFamily: "Lexend Deca",
    fontFamily: "Poppins",
    color: secondaryColor,
    marginBottom: "10px",
    padding: "7px",
    cursor: "pointer",
    "&:hover": {
      background: secondaryColor,
      color: "white",
    },
  },
  chapterContent: {
    marginBottom: "40px",
  },
  selectedChapter: {
    background: primaryColor,
    fontWeight: "bold",
  },
  extraSpace: {
    marginTop: "40px",
  },
  audioBar: {
    top: "auto",
    bottom: 0,
  },
  audio: {
    background: "none",
    "&:-webkit-media-controls-play-button": {
      backgroundColor: secondaryColor,
      color: "white",
    },
  },
  extraSpace: {
    marginTop: "50px",
  },
  rootBar: {
    flexGrow: 1,
  },
  uncopyable: {
    userSelect: "none",
    pointerEvents: "none",
  },
  link: {
    fontWeight: "bold",
    color: secondaryColor,
    "&:hover,&:focus": {
      cursor: "pointer",
    },
  },
  audioControl: {
    width: "100%",
    maxWidth: "800px",
    padding: "5px",
  },
  containerVideo: {
    position: "relative",
    overflow: "hidden",
    width: "100%",
    paddingTop: "56.25%",
  },
  iframe: {
    position: "absolute",
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    width: "100%",
    height: "100%",
  },
}));

export default TextReadingStyle;
