import React from "react";

// Whatsapp Button
import MultiUseMobile from "../../../styles/MultiUseMobile";

// Custom components
import Parallax from "../../../components/Parallax";
import Typography from "../../../components/Typography";
import Button from "../../../components/Button";

// Material-UI components
import { Container, Grid } from "@material-ui/core";

// Images
const Whatsapp =
    "https://firebasestorage.googleapis.com/v0/b/sekilasaja-999fd.appspot.com/o/Website_Images%2FWeb_Picture_Components%2FWhatsapp.png?alt=media&token=88483bb9-b9d3-4aa8-9f14-9b7f91682861";
const ContactBackground =
    "https://firebasestorage.googleapis.com/v0/b/sekilasaja-999fd.appspot.com/o/Website_Images%2FWeb_Picture_Components%2Fcontact.jpg?alt=media&token=3ab740bd-1640-4479-b9d3-651bc9235d91";

export default function Contact({ history }) {
    // Styles
    const multi = MultiUseMobile();

    return (
        <div>
            <div style={{ marginTop: "100px" }} />
            <Container style={{ textAlign: "center" }}>
                <Button href="https://wa.me/6281291176795?text=Halo,%20min.%20Saya%20ingin%20berlangganan%20SekilasAja%2012%20bulan.%20Minta%20nomor%20rekeningnya%20dong.%20Terima%20kasih!"
                    target="_blank"
                    round>
                    Lanjut ke pembayaran
                </Button>
            </Container>
        </div>
    );
}
