import React, { useEffect, useState, useContext } from "react";
import { Redirect, useParams } from "react-router-dom";

// Whatsapp Button
import MultiUseMobile from "../../styles/MultiUseMobile";

// Custom components
import Typography from "../../components/Typography";
import Footer from "../../components/Footer";
import Header from "../../components/NavBar/Header";
import HeaderLinks from "../../components/NavBar/HeaderLinks";
import HeaderLinksMobile from "../../components/NavBar/HeaderLinksMobile";
import { beigeColor } from "../../styles/Style";
import TextReadingStyle from "../../styles/TextReadingStyle";
import Video from "../KilasPage/Video";

// Material-UI components
import { Container, Grid } from "@material-ui/core";

// Firebase
import * as firebaseGetMentorById from "../../firebase/firebaseGetMentorById";

// Redux & Auth
import { useSelector } from "react-redux";
import { selectUser } from "../../feature/userSlice";
import { AuthContext } from "../../components/Routing/Auth";

// Images
const Whatsapp =
  "https://firebasestorage.googleapis.com/v0/b/sekilasaja-999fd.appspot.com/o/Website_Images%2FWeb_Picture_Components%2FWhatsapp.png?alt=media&token=88483bb9-b9d3-4aa8-9f14-9b7f91682861";

export default function Contact({ history, match }) {
  // Styles
  const multi = MultiUseMobile();
  const video = TextReadingStyle();

  const params = useParams();

  // useStates
  const [mentor, setMentor] = useState(false);

  // Auth
  const userData = useSelector(selectUser);
  const { currentUser } = useContext(AuthContext);

  useEffect(() => {
    const fetchData = async () => {
      const getMentor = await firebaseGetMentorById.getMentorById(params.id);
      setMentor(getMentor);
    };
    fetchData();
  }, []);

  if (userData.user.is_subcribed === false || !currentUser) {
    return <Redirect to="/404page" />;
  }

  return (
    <div style={{ backgroundColor: beigeColor }}>
      <div style={{ marginTop: "100px" }} />
      <Header
        history={history}
        rightLinks={<HeaderLinks history={history} />}
        rightLinksMobile={<HeaderLinksMobile history={history} />}
        fixed
        color="white"
      />
      {mentor && (
        <Container maxWidth="md">
          <Typography style={{ marginBottom: 0 }} size="heading">
            {mentor.title}
          </Typography>
          <Typography style={{ marginTop: 0 }} size="subheading">
            By {mentor.name}
          </Typography>
          <div className={video.containerVideo}>
            <Video
              className={video.iframe + " sproutvideo-player"}
              src={mentor.videoLink}
            />
          </div>
        </Container>
      )}

      {/*---------------------------------------------------------------*/}
      {/*---------------------- WHATSAPP FIXED NAV ---------------------*/}
      {/*---------------------------------------------------------------*/}
      <a href="https://wa.me/6281291176795" target="_blank">
        <div className={multi.whatsapp}>
          <Grid container spacing={3}>
            <Grid item xs={4}>
              <img
                style={{
                  width: "60px",
                }}
                src={Whatsapp}
              />
            </Grid>

            <Grid item xs={4}>
              <Typography type="bold">Butuh Bantuan?</Typography>
            </Grid>
          </Grid>
        </div>
      </a>
      <Footer />
    </div>
  );
}
