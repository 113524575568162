import fire from "./fire";
const newChallenge = fire.firestore().collection("newChallenge");
export const firebaseNewGetChallenge = async (param) => {
    const challenge = await fire
      .firestore()
      .collection("newChallenge")
      .doc(param)
      .get();
    return challenge.data();
  },
  firebaseNewGetProgress = async (param) => {
    let result = await newChallenge
      .doc(param.challenge)
      .collection("user")
      .doc(param.email)
      .get();
    // .then((res) => {

    //   return res.data() ? Object.keys(res.data()).length : 0;
    // });
    return {
      done: result.data() ? Object.keys(result.data()).length : 0,
      bookList: result.data() ? Object.keys(result.data()) : [],
    };
  },
  getChallengeByTittle = async (title) => {
    let get = {};
    await newChallenge.get().then((res) => {
      return res.docChanges().map((data) => {
        const newTittle = data.doc.data().tittle;
        if (newTittle === title) {
          get = { ...data.doc.data(), id: data.doc.id };
        }
      });
    });
    return get;
  };
