import React, { useContext } from "react";

// Whatsapp Button
import MultiUseMobile from "../../styles/MultiUseMobile";

// Custom components
import Typography from "../../components/Typography";
import Button from "../../components/Button";
import Header from "../../components/NavBar/Header";
import HeaderLinks from "../../components/NavBar/HeaderLinks";
import HeaderLinksMobile from "../../components/NavBar/HeaderLinksMobile";
import Footer from "../../components/Footer";
import PricingStyles from "../../styles/PricingStyle";
import { secondaryColor, primaryColor } from "../../styles/Style";

// Context
import { setModalAuth, setRedirectTo } from "./../../context/action";
import { AuthenticationArea } from "../../utils";
import { ContextReact } from "../../context";

// Material-UI components
import {
  Container,
  Grid,
  CardContent,
  CardHeader,
  Card
} from "@material-ui/core";

// Firebase components
import { AuthContext } from "../../components/Routing/Auth";

// Images
const Whatsapp =
  "https://firebasestorage.googleapis.com/v0/b/sekilasaja-999fd.appspot.com/o/Website_Images%2FWeb_Picture_Components%2FWhatsapp.png?alt=media&token=88483bb9-b9d3-4aa8-9f14-9b7f91682861";

export default function PricingPage({ match, history }) {
  // Styles
  const classes = PricingStyles();
  const multi = MultiUseMobile();

  // Auth
  const { currentUser } = useContext(AuthContext);

  // Context
  const context = React.useContext(ContextReact);
  const { dispatch } = context;

  React.useEffect(() => {
    dispatch(setRedirectTo(false));
  }, []);

  const tiers = [
    {
      title: "12 Bulan",
      monthlyPrice: "448.000",
      hemat: "Penawaran Terbaik!",
      disclaimer: "* Pembayaran langsung 12 bulan di depan",
      buttonText: "Berlanggan Sekarang!",
      route: "/payment-twelve",
      routeNonUser: "/payment-twelve-new-user",
      dailyPrice: "1.227"
    }
    // {
    //   title: "1 Bulan",
    //   monthlyPrice: "100.000",
    //   disclaimer: "",
    //   buttonText: "Berlanggan Sekarang!",
    //   route: "/payment-three",
    //   routeNonUser: "/payment-three-new-user",
    //   dailyPrice: "3.333"
    // }
  ];

  const redirect = (param) => {
    if (AuthenticationArea()) {
      history.push("/login");
    } else {
      dispatch(setModalAuth({ type: "login", modal: true }));
      dispatch(setRedirectTo(param));
    }
  };

  return (
    <div>
      <Header
        history={history}
        rightLinks={<HeaderLinks history={history} />}
        rightLinksMobile={<HeaderLinksMobile history={history} />}
        fixed
      />

      <div style={{ marginTop: "100px" }} />

      <Container maxWidth="md" style={{ textAlign: "center" }}>
        <Typography size="heading">
          <strong className={classes.underline}>Berlangganan</strong>, dan
          dapatkan...
        </Typography>
        <Container maxWidth="md">
          <div
            style={{
              border: "2px solid #41444b",
              borderRadius: 30,
              padding: 20
            }}
            elevation={0}
          >
            <Typography style={{ fontSize: 20 }}>
              ✅ 100+ rangkuman buku terbaik DUNIA!
            </Typography>
            {/* <Typography style={{ fontSize: 20 }}>
              ✅ 20+ rangkuman BUKU BARU per BULAN!
            </Typography> */}
            <Typography style={{ fontSize: 20 }}>
              ✅ Selesaikan 1 buku hanya dalam 15 MENIT
            </Typography>
            <Typography style={{ fontSize: 20 }}>
              ✅ Buku tersedia dalam bentuk VIDEO, AUDIO, dan TEKS
            </Typography>
            {/* <Typography style={{ fontSize: 20 }}>
              ✅ Belajar secara live dengan mentor
            </Typography>
            <Typography style={{ fontSize: 20 }}>
              ✅ Webinar GRATIS SETIAP MINGGU!Tersedia Rekaman video webinar
              sebelumnya
            </Typography>
            <Typography style={{ fontSize: 20 }}>
              ✅ Tersedia Rekaman video webinar sebelumnya
            </Typography> */}
            <Typography style={{ fontSize: 20 }}>
              ✅ 50+ Artikel pembelajaran
            </Typography>
            <Typography style={{ fontSize: 20 }}>
              ✅ Tergabung ke dalam Komunitas Eksklusif
            </Typography>
          </div>

          <div style={{ marginBottom: 40 }} />
        </Container>

        {/* <Typography id="pricing" size="heading">
          Gunakan Kode Promo{" "}
          <strong
            style={{
              backgroundColor: secondaryColor,
              color: primaryColor,
              padding: 5,
              borderRadius: 10,
              marginBottom: 0
            }}
          >
            DISKON20
          </strong>{" "}
          Untuk Mendapatkan EKSTRA DISKON 20%!
        </Typography>

        <Typography style={{ marginTop: 0, marginBottom: 30 }} type="italic">
          * Hanya berlaku untuk paket 12 bulan
        </Typography>

        <Typography size="subheading">
          <strong
            style={{
              backgroundColor: secondaryColor,
              color: primaryColor,
              padding: 5,
              borderRadius: 10
            }}
          >
            Harga bisa naik sewaktu-waktu!
          </strong>
        </Typography> */}

        <Grid container spacing={5} justifyContent="center" alignItems="center">
          {tiers.map((tier) => (
            <Grid
              style={{
                marginBottom: "10px",
                maxWidth: "450px"
              }}
              item
              key={tier.title}
              xs={12}
              md={12 / tiers.length}
              className={classes.cardHover}
            >
              <Card variant="outlined" style={{ borderRadius: 30 }}>
                <CardHeader
                  title={
                    <Typography size="subheading" style={{ marginBottom: 5 }}>
                      {tier.title}
                    </Typography>
                  }
                  titleTypographyProps={{ align: "center" }}
                  subheaderTypographyProps={{ align: "center" }}
                  className={
                    tier.title === "12 Bulan"
                      ? classes.cardHeader
                      : classes.cardHeaderWhite
                  }
                />
                <CardContent>
                  <div style={{ textAlign: "center" }}>
                    <Typography
                      type="italic"
                      style={{
                        marginBottom: 0
                      }}
                    >
                      {tier.hemat}
                    </Typography>

                    <div className={classes.cardPricing}>
                      <Typography
                        type="subheading"
                        style={{
                          marginTop: 0,
                          fontSize: "24px",
                          marginBottom: 0
                        }}
                      >
                        Rp. {tier.monthlyPrice}
                      </Typography>
                      {/* <Typography type="italic">/hari</Typography> */}
                    </div>

                    {tier.title === "12 Bulan" ? (
                      <Typography>
                        <strong
                          style={{
                            backgroundColor: secondaryColor,
                            color: primaryColor,
                            padding: 5,
                            borderRadius: 10
                          }}
                        >
                          Rp. {tier.dailyPrice}/hari
                        </strong>
                      </Typography>
                    ) : (
                      <Typography>Rp. {tier.dailyPrice}/hari</Typography>
                    )}

                    <Typography style={{ fontSize: "12px" }} type="italic">
                      {tier.disclaimer}
                    </Typography>
                  </div>

                  <Button
                    // onClick={
                    //   !!currentUser
                    //     ? () => {
                    //         history.push(tier.route);
                    //         window.scrollTo(0, 0);
                    //       }
                    //     : () => {
                    //         history.push(tier.routeNonUser);
                    //         window.scrollTo(0, 0);
                    //       }
                    // }
                    href="https://wa.me/6281291176795?text=Halo,%20min.%20Saya%20ingin%20berlangganan%20SekilasAja%2012%20bulan.%20Minta%20nomor%20rekeningnya%20dong.%20Terima%20kasih!" target="_blank"
                    round
                    fullWidth
                    color={tier.title === "12 Bulan" ? "primary" : "gray"}
                  >
                    {tier.buttonText}
                  </Button>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>

      {/*---------------------------------------------------------------*/}
      {/*---------------------- WHATSAPP FIXED NAV ---------------------*/}
      {/*---------------------------------------------------------------*/}
      <a href="https://wa.me/6281291176795" target="_blank">
        <div className={multi.whatsapp}>
          <Grid container spacing={3}>
            <Grid item xs={4}>
              <img
                style={{
                  width: "60px"
                }}
                src={Whatsapp}
              />
            </Grid>

            <Grid item xs={4}>
              <Typography type="bold">Butuh Bantuan?</Typography>
            </Grid>
          </Grid>
        </div>
      </a>
      <Footer />
    </div>
  );
}
