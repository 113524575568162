import React, { useState, useEffect } from "react";

// @material-ui/core components
import { makeStyles, Grid, Paper } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import { Lock } from "@material-ui/icons";

// Custom components
import InfoAreaStyle from "../../styles/InfoAreaStyle";
import Typography from "../Typography";

// Firebase components
import * as firebaseGetBookCoverImageURL from "../../firebase/firebaseGetBookCoverImageURL";
import { countingDown } from "../../utils";
import { secondaryColor } from "../../styles/Style";

//Images
const unreleasedCover =
  "https://firebasestorage.googleapis.com/v0/b/sekilasaja-999fd.appspot.com/o/Book_Cover_Images_JPG%2Funreleased-cover.jpg?alt=media&token=a0d39b53-e2b0-4888-86f7-ec9d39dab55d";

// Styles
const useStyles = makeStyles(InfoAreaStyle);

export default function ComingSoonCard({
  product,
  chosenCategory,
  notOwned,
  extraSpace,
  isTimeHidden,
}) {
  // Styles
  const classes = useStyles();

  // useState hooks
  const [coverLink, setCoverLink] = useState("");
  const [openIn, setOpenIn] = useState("");
  const [hours, setHours] = React.useState(0);

  useEffect(() => {
    const fetchData = async () => {
      const getLink = firebaseGetBookCoverImageURL.getBookCoverImageURL(
        product.book_title
      );
      const link = await getLink;

      if (link !== undefined) setCoverLink(link);
    };
    fetchData();

    if (chosenCategory == "Coming Soon!") {
      setInterval(() => {
        setOpenIn(
          countingDown({
            setHours: setHours,
            releaseDate: product.release_date && product.release_date.toDate(),
            bookTitle: product.book_title,
          })
        );
      }, 1000);
    }
  }, [, chosenCategory]);

  return (
    <div style={{ padding: 7 }}>
      <Paper
        style={{ maxWidth: 200, height: "auto", width: "100%" }}
        square
        elevation={2}
      >
        <Grid item>
          {coverLink || unreleasedCover ? (
            <div className={classes.bookCoverNew}>
              <div
                hidden={isTimeHidden}
                style={{
                  position: "absolute",
                  textAlign: "center",
                  marginTop: "30%",
                  width: "90%",
                }}
              >
                <Lock fontSize="large" style={{ color: secondaryColor }} />
                <Typography
                  style={{
                    fontSize: "12px",
                    marginBottom: "0px",
                    color: hours < 24 ? "red" : "",
                  }}
                  type="bold"
                >
                  Hadir dalam
                </Typography>
                <Typography
                  style={{
                    marginTop: "0px",
                  }}
                  size="subheading"
                >
                  {openIn}
                </Typography>
              </div>
              <div>
                <img
                  style={
                    !isTimeHidden
                      ? { opacity: 0.3, backgroundColor: "#D2D2D2" }
                      : { filter: "grayscale(100%)" }
                  }
                  src={coverLink || unreleasedCover}
                  alt={product.book_title}
                  className={notOwned + " " + classes.imgBookCoverNew}
                />

                <div
                  style={{ padding: 10 }}
                  className={classes.descriptionWrapper}
                >
                  <Typography className={classes.lineClampAuthor} type="italic">
                    {product.author}
                  </Typography>
                  <Typography className={classes.lineClamp} type="bold">
                    {product.book_title}: {product.short_desc}
                  </Typography>
                  {/* <div style={{ marginBottom: 35 }} /> */}
                </div>
              </div>
            </div>
          ) : (
            <Skeleton variant="rect" width={220} height={500} />
          )}

          {extraSpace}
        </Grid>
      </Paper>

      <div style={{ marginBottom: "50px" }} />
    </div>
  );
}
