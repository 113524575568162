import React from "react";

// @material-ui/core components
import { makeStyles, Grid, Divider, Hidden } from "@material-ui/core";
import {
  ImportContacts,
  EmojiObjects,
  Videocam,
  ChatBubbleOutline,
  ChatBubble,
  KeyboardArrowDown,
} from "@material-ui/icons";
import { Skeleton } from "@material-ui/lab";

// Custom components
import InfoAreaStyle from "../../styles/InfoAreaStyle";
import MultiUseMobile from "../../styles/MultiUseMobile";
import Typography from "../../components/Typography";
import Accordion from "./Accordion";
import { getListComment } from "../../firebase/firebaseComment";

// Styles
const useStyles = makeStyles(InfoAreaStyle);

// Images
const unreleasedCover =
  "https://firebasestorage.googleapis.com/v0/b/sekilasaja-999fd.appspot.com/o/Book_Cover_Images_JPG%2Funreleased-cover.jpg?alt=media&token=a0d39b53-e2b0-4888-86f7-ec9d39dab55d";

export default function BookDetails(props) {
  const {
    title,
    readTime,
    watchTime,
    upsellBlock,
    kilasBody,
    video,
    audio,
    tableOfContents,
    description,
    num,
    author,
    short_desc,
    cover,
    buttons,
    isVideoMinutesHidden,
    commentBlock,
  } = props;

  // useStates
  const [totalComment, setTotalCOmment] = React.useState(0);
  const [hoverState, setHoverState] = React.useState(false);

  React.useEffect(() => {
    const fetchData = async () => {
      const getComment = await getListComment({ book_title: title });
      setTotalCOmment(getComment);
    };
    fetchData();
  }, [,]);

  // Styles
  const classes = useStyles();
  const multi = MultiUseMobile();

  return (
    <div>
      <Grid container spacing={3} justifyContent="space-between">
        <Grid item xs={12}>
          <Hidden mdUp>
            <img
              src={cover}
              className={
                classes.imgRounded +
                " " +
                classes.imgFluid +
                " " +
                classes.imgBookDetailsCover
              }
            />
          </Hidden>
        </Grid>

        <Grid item md={9} xs={12}>
          <Typography
            style={{ marginTop: 0, marginBottom: 1 }}
            size="subheading"
            type="bold"
          >
            {title}
          </Typography>

          <Typography style={{ marginTop: 0, marginBottom: 20 }}>
            {short_desc}
          </Typography>

          <Typography type="italic">{author}</Typography>

          <Divider />

          <Grid container>
            <Grid item>
              <div className={classes.kilasDesc}>
                <ImportContacts className={classes.logo} />
                <Typography type="bold">{readTime} Menit</Typography>
              </div>
            </Grid>
            <Grid hidden={isVideoMinutesHidden} item>
              <div className={classes.kilasDesc}>
                <Videocam className={classes.logo} />
                <Typography type="bold">{watchTime} Menit</Typography>
              </div>
            </Grid>
            <Grid item>
              <div className={classes.kilasDesc}>
                <EmojiObjects className={classes.logo} />
                <Typography type="bold">{num} Kilas</Typography>
              </div>
            </Grid>
            <Grid item>
              <div
                onClick={() =>
                  document
                    .getElementById("forum_diskusi")
                    .scrollIntoView({ behavior: "smooth" })
                }
                style={{
                  cursor: "pointer",
                }}
                className={classes.kilasDesc}
                onMouseEnter={() => setHoverState(true)}
                onMouseLeave={() => setHoverState(false)}
              >
                {!hoverState ? (
                  <ChatBubbleOutline className={classes.logo} />
                ) : (
                  <ChatBubble className={classes.logo} />
                )}

                <Typography type="bold">{totalComment} Komentar</Typography>
              </div>
            </Grid>
          </Grid>

          <Divider />

          <Typography
            className={multi.underline}
            style={{ marginTop: 25 }}
            type="bold"
          >
            Tentang
          </Typography>
          {description &&
            description.map((paragraph, index) => (
              <Typography
                className={classes.uncopyable}
                className={classes.paragraph}
              >
                {paragraph}
              </Typography>
            ))}

          <Hidden mdUp>
            <div>{buttons}</div>
          </Hidden>

          <div style={{ marginBottom: 12, marginTop: 30 }}>
            <Typography type="bold">
              <strong className={multi.underline}>Sneakpeak</strong> Dari Buku
              Ini
            </Typography>
            {audio}
          </div>

          <div>
            <Accordion
              title="Apa Yang Akan Kamu Pelajari?"
              content={
                <div>
                  {/* <Typography size="subheading">{kilasTitle}</Typography> */}
                  <Typography>{kilasBody}</Typography>
                </div>
              }
            />
          </div>

          <div>
            <Accordion title="Video" content={video} />
          </div>

          <div>
            <Accordion
              title="Daftar Kilas"
              content={<Typography>{tableOfContents}</Typography>}
            />
          </div>

          {upsellBlock}
          {commentBlock}
        </Grid>

        <Grid item xs={3}>
          <Hidden smDown>
            {cover || unreleasedCover ? (
              <img
                src={cover || unreleasedCover}
                className={
                  classes.imgRounded +
                  " " +
                  classes.imgFluid +
                  " " +
                  classes.imgBookDetailsCover
                }
              />
            ) : (
              <Skeleton variant="rect" width={220} height={500} />
            )}

            <div style={{ maxWidth: 250 }}>{buttons}</div>
          </Hidden>
        </Grid>
      </Grid>
    </div>
  );
}
