import React from "react";
import MultiUseMobile from "../styles/MultiUseMobile";

function CombiningTextWithUnderline({
  text: { underline, normal },
  position = "",
}) {
  const multi = MultiUseMobile();
  const textByText = () => {};

  textByText();
  const TextUnderLine = () => {
    const split = underline.split(" ");
    return split.map((newTxt, index) => {
      return (
        <React.Fragment key={index}>
          <strong className={multi.underline}>
            {" "}
            {newTxt}
            {index !== split.length - 1 && <>&nbsp;</>}
          </strong>
        </React.Fragment>
      );
    });
  };
  return (
    <>
      {position === "front" && <TextUnderLine />} {normal}{" "}
      {position === "back" && <TextUnderLine />}
    </>
  );
}

export default CombiningTextWithUnderline;
