import React, { useState, useContext, useEffect } from "react";
import { Redirect } from "react-router-dom";

// Custom components
import TextDetails from "./TextDetails";
import Header from "../../components/NavBar/Header";
import HeaderLinks from "../../components/NavBar/HeaderLinks";
import HeaderLinksMobile from "../../components/NavBar/HeaderLinksMobile";
import Footer from "../../components/Footer";
import Typography from "../../components/Typography";
import ReactAudioPlayer from "react-audio-player";
import MultiUseMobile from "../../styles/MultiUseMobile";
import Button from "../../components/Button";
import Loading from "../Utilities/Loading";
import ModalFreeBooks from "../../components/Modal/ModalFreeBooks";
import ModalPricing from "../../components/Modal/ModalPricing";

// Material-UI components
import { Container, IconButton, Paper, Grid } from "@material-ui/core";
import VpnKeyIcon from "@material-ui/icons/VpnKey";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import LockOpenIcon from "@material-ui/icons/LockOpen";
import LockIcon from "@material-ui/icons/Lock";

//Redux
import { useSelector } from "react-redux";
import { selectUser } from "../../feature/userSlice";
import { AuthenticationArea } from "../../utils";

// Auth and fire
import { AuthContext } from "../../components/Routing/Auth";
import * as firebaseGetBookInfoByTitle from "../../firebase/firebaseGetBookInfoByTitle";
import * as firebaseGetBookCoverImageURL from "../../firebase/firebaseGetBookCoverImageURL";
import * as firebaseGetBookAudioTrialURL from "../../firebase/firebaseGetBookAudioTrialURL";

// Context
import { ContextReact } from "../../context";
import { risetData, setModalAuth } from "../../context/action";
import Commentary from "./commentary";

// Images
const Whatsapp =
  "https://firebasestorage.googleapis.com/v0/b/sekilasaja-999fd.appspot.com/o/Website_Images%2FWeb_Picture_Components%2FWhatsapp.png?alt=media&token=88483bb9-b9d3-4aa8-9f14-9b7f91682861";

export default function BookDetailsPage({ match, history }) {
  // Styles
  const classes = MultiUseMobile();

  // Auth
  const { currentUser } = useContext(AuthContext);

  // Redux
  const userData = useSelector(selectUser);

  // useState Hooks
  const [current_product, setCurrent_Product] = useState({});
  const [coverLink, setCoverLink] = useState("");
  const [audioLink, setAudioLink] = useState(null);
  const [isBookOwned, setIsBookOwned] = useState(false);
  const [pending, setPending] = useState(true);
  const [modal, setModal] = useState(false);
  const [pricingModal, setPricingModal] = useState(false);
  const [freeBooksModal, setFreeBooksModal] = useState(false);

  // Context
  const context = React.useContext(ContextReact);
  const { dispatch, data } = context;

  //First useEffect to grab user data...
  useEffect(() => {
    if (data.detailBook[match.params.book_title]) {
      sessionStorage.setItem("Book", JSON.stringify(data));
    }
    const book = sessionStorage.getItem("Book");

    if (book) {
      dispatch(risetData(JSON.parse(book)));
    }
    if (currentUser !== null) {
      if (userData.user.owned_books.includes(match.params.book_title)) {
        setIsBookOwned(true);
      }
    }
    const parse = JSON.parse(book);
    const newData = parse || data;

    if (match.params.book_title !== null) {
      const fetchData = async () => {
        if (
          newData.detailBook[match.params.book_title] &&
          newData.audioLink[match.params.book_title]
        ) {
          // set book detail
          const bookDetail = newData.detailBook[match.params.book_title];
          setCurrent_Product(bookDetail);

          // set cover link
          const coverLink = newData.coverLink[match.params.book_title];
          setCoverLink(coverLink);

          // set loading
          setPending(false);

          // set audio link
          const audionLink = newData.audioLink[match.params.book_title];
          setAudioLink(audionLink);
        } else {
          const book_ = await firebaseGetBookInfoByTitle.getBookInfoByTitle(
            match.params.book_title
          );
          setCurrent_Product(book_);
          setPending(false);

          const link = await firebaseGetBookCoverImageURL.getBookCoverImageURL(
            match.params.book_title
          );
          setCoverLink(link);

          const audioLink =
            await firebaseGetBookAudioTrialURL.getBookAudioTrialURL(
              match.params.book_title
            );

          setAudioLink(audioLink);
          let key = match.params.book_title;

          if (book) {
            const parse = JSON.parse(book);
            const newDetail = {
              ...parse.detailBook,
              [match.params.book_title]: book_,
            };
            const newAudio = { ...parse.audioLink, [key]: audioLink };
            const newCover = { ...parse.coverLink, [key]: link };
            sessionStorage.setItem(
              "Book",
              JSON.stringify({
                audioLink: newAudio,
                coverLink: newCover,
                detailBook: newDetail,
              })
            );
          } else {
            sessionStorage.setItem(
              "Book",
              JSON.stringify({
                audioLink: { [key]: audioLink },
                coverLink: { [key]: link },
                detailBook: { [key]: book_ },
              })
            );
          }
        }
      };
      fetchData();
    }
  }, [history.location]);

  //Make page loading till book details have been loaded
  if (pending) {
    return (
      <>
        <Loading />
      </>
    );
  }

  if (!current_product || current_product.category.includes("Coming Soon!"))
    return <Redirect to="/404page" />;

  const redirect = () => {
    if (AuthenticationArea()) {
      history.push("/login");
    } else {
      dispatch(setModalAuth({ type: "login", modal: true }));
    }
  };

  const onClickPricingModal = () => {
    return setPricingModal(true);
  };

  const onClickFreeBooksModal = () => {
    return setFreeBooksModal(true);
  };

  const {
    book_title,
    descriptions,
    author,
    watch_time,
    read_time,
    kilasan,
    video_link,
    short_desc,
  } = current_product;

  return (
    <div>
      <div style={{ marginTop: "100px" }} />

      <ModalFreeBooks open={freeBooksModal} setOpen={setFreeBooksModal} />
      <ModalPricing open={pricingModal} setOpen={setPricingModal} />

      <Header
        history={history}
        rightLinks={<HeaderLinks history={history} />}
        rightLinksMobile={<HeaderLinksMobile history={history} />}
        fixed
        color="white"
      />
      <Container>
        <TextDetails
          isVideoMinutesHidden={video_link ? false : true}
          cover={coverLink}
          title={book_title}
          author={author}
          description={descriptions}
          watchTime={watch_time}
          readTime={read_time}
          short_desc={short_desc}
          num={kilasan ? kilasan.length : 0}
          buttons={
            !!currentUser ? (
              userData.user && (
                // for user subscribe
                <div>
                  {!!userData.user.is_subscribed || !!isBookOwned ? (
                    <div>
                      <Button
                        onClick={() => {
                          history.push(`/kilas/${book_title}`);
                          window.scrollTo(0, 0);
                        }}
                        round
                        fullWidth
                      >
                        Akses Kilasmu Sekarang!
                      </Button>
                    </div>
                  ) : (
                    <div>
                      <Button
                        round
                        onClick={() => {
                          history.push("/pricing");
                          window.scrollTo(0, 0);
                        }}
                        fullWidth
                      >
                        Berlanggan Sekarang!
                      </Button>
                    </div>
                  )}
                </div>
              )
            ) : (
              // for user un-subscribe

              <div>
                <Button
                  onClick={() => {
                    redirect();
                  }}
                  round
                  fullWidth
                >
                  <VpnKeyIcon /> Masuk Sekarang!
                </Button>
              </div>
            )
          }
          video={
            video_link ? (
              <div style={{ display: "flex" }}>
                {(userData.user && !!userData.user.is_subscribed) ||
                  !!isBookOwned ? (
                  <LockOpenIcon
                    className={classes.iconColor}
                    style={{ marginRight: 10 }}
                    fontSize="md"
                  />
                ) : (
                  <LockIcon
                    className={classes.iconColor}
                    style={{ marginRight: 10 }}
                    fontSize="md"
                  />
                )}
                <Paper
                  style={{
                    backgroundColor: "#515151",
                    padding: "20px 40px 20px 40px",
                    height: 85,
                  }}
                  elevation={3}
                >
                  <IconButton
                    onClick={
                      !!currentUser
                        ? (userData.user && !!userData.user.is_subscribed) ||
                          !!isBookOwned
                          ? () => {
                            history.push(`/kilas/${book_title}`);
                            window.scrollTo(0, 0);
                          }
                          : onClickPricingModal
                        : onClickFreeBooksModal
                    }
                    size="sm"
                    style={{
                      backgroundColor: "#FFE05D",
                    }}
                    aria-label="menu"
                  >
                    <PlayArrowIcon fontSize="small" />
                  </IconButton>
                </Paper>
                <div style={{ marginLeft: "10px" }}>
                  <Typography style={{ marginTop: 0 }} type="bold">
                    {book_title}
                  </Typography>
                  <Typography>{watch_time} menit</Typography>
                </div>
              </div>
            ) : (
              <Typography style={{ display: "flex", alignItems: "center" }}>
                <div>
                  <LockIcon
                    className={classes.iconColor}
                    style={{ marginRight: 10 }}
                    fontSize="md"
                  />{" "}
                  Video untuk kilas ini belum tersedia!
                </div>
              </Typography>
            )
          }
          audio={
            <ReactAudioPlayer
              className={classes.audioControl}
              controlsList="nodownload"
              src={audioLink}
              controls
            />
          }
          kilasTitle={
            <Typography size="subheading">
              {kilasan && kilasan[0].title}
            </Typography>
          }
          kilasBody={
            kilasan &&
            kilasan[0].details.map((paragraph) => (
              <Typography>{paragraph}</Typography>
            ))
          }
          tableOfContents={
            <div>
              {kilasan &&
                kilasan.map((kilas, index) => (
                  <Typography>
                    {kilas.title === undefined ? (
                      <div style={{ display: "flex", marginBottom: 20 }}>
                        {(userData.user && !!userData.user.is_subscribed) ||
                          !!isBookOwned ? (
                          <LockOpenIcon
                            className={classes.iconColor}
                            style={{ marginRight: 10 }}
                            fontSize="md"
                          />
                        ) : (
                          <LockIcon
                            className={classes.iconColor}
                            style={{ marginRight: 10 }}
                            fontSize="md"
                          />
                        )}
                        <strong>Ringkasan Akhir</strong>
                      </div>
                    ) : (
                      <div style={{ display: "flex", marginBottom: 20 }}>
                        {(userData.user && !!userData.user.is_subscribed) ||
                          !!isBookOwned ? (
                          <LockOpenIcon
                            className={classes.iconColor}
                            style={{ marginRight: 10 }}
                            fontSize="md"
                          />
                        ) : (
                          <LockIcon
                            className={classes.iconColor}
                            style={{ marginRight: 10 }}
                            fontSize="md"
                          />
                        )}
                        <div>
                          <strong>Kilas #{index + 1}:</strong> {kilas.title}
                        </div>
                      </div>
                    )}
                  </Typography>
                ))}
            </div>
          }
          commentBlock={
            <>
              <br />
              <div id="forum_diskusi">
                <Typography size="heading">Forum Diskusi</Typography>
                <Commentary dataBook={current_product} />
              </div>
            </>
          }
        />
      </Container>

      {/*---------------------------------------------------------------*/}
      {/*---------------------- WHATSAPP FIXED NAV ---------------------*/}
      {/*---------------------------------------------------------------*/}
      <a href="https://wa.me/6281291176795" target="_blank">
        <div className={classes.whatsapp}>
          <Grid container spacing={3}>
            <Grid item xs={4}>
              <img
                style={{
                  width: "60px",
                }}
                src={Whatsapp}
              />
            </Grid>

            <Grid item xs={4}>
              <Typography type="bold">Butuh Bantuan?</Typography>
            </Grid>
          </Grid>
        </div>
      </a>

      <Footer />
    </div>
  );
}
