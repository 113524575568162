import React from "react";
import {
  riset_data,
  set_audio_link,
  set_book,
  set_book_detail,
  set_cover_link,
  set_modal_auth,
  set_redirect_to,
  set_logout_modal,
  db_set_user,
} from "./type";
let initialState = {
  detailBook: {},
  coverLink: {},
  audioLink: {},
  book: false,
  modalAuth: { type: "", modal: false },
  redirectTo: false,
  dashboard: {},
};

const reducerReact = (state, action) => {
    switch (action.type) {
      case set_book_detail:
        return {
          ...state,
          detailBook: { ...state.detailBook, ...action.payload },
        };

      case set_cover_link:
        return {
          ...state,
          coverLink: { ...state.coverLink, ...action.payload },
        };
      case set_audio_link:
        return {
          ...state,
          audioLink: { ...state.audioLink, ...action.payload },
        };
      case riset_data:
        return {
          ...state,
          ...action.payload,
        };
      case set_book:
        return {
          ...state,
          book: action.payload,
        };
      case set_modal_auth:
        return {
          ...state,
          modalAuth: action.payload,
        };
      case set_redirect_to:
        return {
          ...state,
          redirectTo: action.payload,
        };
      case set_logout_modal:
        return {
          ...state,
          logoutModal: action.payload,
        };

      // dashboard
      case db_set_user:
        return {
          ...state,
          dashboard: { ...state.dashboard, dataUser: action.data },
        };

      default:
        return state;
    }
  },
  ContextReact = React.createContext();
export { reducerReact, initialState, ContextReact };
