import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";

// Whatsapp Button
import MultiUseMobile from "../../styles/MultiUseMobile";

// Custom components
import Typography from "../../components/Typography";
import Footer from "../../components/Footer";
import Header from "../../components/NavBar/Header";
import HeaderLinks from "../../components/NavBar/HeaderLinks";
import HeaderLinksMobile from "../../components/NavBar/HeaderLinksMobile";
import Button from "../../components/Button";
import InfoStyle from "../../styles/InfoAreaStyle";
import BenefitsBlock from "./BenefitsBlock/BenefitsBlock";
import NewKilasBlock from "./NewKilas/NewKilasBlock";
import Loading from "../Utilities/Loading";
import CategoryBlock from "./CategoryBlock/CategoryBlock";
import ComingSoonBlock from "./ComingSoonBlock/ComingSoonBlock";
import TestimonialBlock from "./TestimonialBlock/TestimonialBlock";
import CombiningTextWithUnderline from "../../components/CombiningTextWithUnderline";
import ModalPricing from "../../components/Modal/ModalPricing";
import ModalSignOut from "../../components/Modal/ModalSignOut";

// Firebase components
import { AuthContext } from "../../components/Routing/Auth";
import fire from "../../firebase/fire";

// Material-UI components
import { Container, Grid, makeStyles, Hidden } from "@material-ui/core";

// Redux
import { useSelector } from "react-redux";
import { selectUser } from "../../feature/userSlice";

// context
import { ContextReact } from "../../context";
import getBookInfoByTitle from "../../firebase/firebaseGetBookInfoByTitle";
import {
  setAudioLink,
  setBookDetail,
  setCoverLink,
  setModalAuth,
  setLogoutModal,
} from "./../../context/action";
import getBookCoverImageURL from "../../firebase/firebaseGetBookCoverImageURL";
import getBookAudioTrialURL from "../../firebase/firebaseGetBookAudioTrialURL";
import { AuthenticationArea } from "../../utils";

// Images
const Whatsapp =
  "https://firebasestorage.googleapis.com/v0/b/sekilasaja-999fd.appspot.com/o/Website_Images%2FWeb_Picture_Components%2FWhatsapp.png?alt=media&token=88483bb9-b9d3-4aa8-9f14-9b7f91682861";
const AtomicHabits =
  "https://firebasestorage.googleapis.com/v0/b/sekilasaja-999fd.appspot.com/o/Book_Dashboard_Images%2FAtomic%20Habits.png?alt=media&token=bf6b087c-ce51-4b90-b329-3b91bfe6a1e3";
const TheLittleBook =
  "https://firebasestorage.googleapis.com/v0/b/sekilasaja-999fd.appspot.com/o/Book_Dashboard_Images%2FThe%20Little%20Book%20of%20Common%20Sense%20Investing.png?alt=media&token=fd281a56-a2e0-4986-a1bd-b20bafe4b069";
const Logo =
  "https://firebasestorage.googleapis.com/v0/b/sekilasaja-999fd.appspot.com/o/Website_Images%2FWeb_Picture_Components%2Fdark-logo.png?alt=media&token=cfd7dc4d-1687-473e-a272-4d7c66b97467";

// Styles
const useStyles = makeStyles(InfoStyle);

export default function HomeNew({ history }) {
  // Styles
  const books = useStyles();
  const multi = MultiUseMobile();

  // Auth
  const { currentUser } = useContext(AuthContext);
  const userData = useSelector(selectUser);
  const db = fire.firestore();

  // useState hooks
  const [pending, setPending] = useState(true);
  const [products, SetProducts] = useState([]);
  const [pricingModal, setPricingModal] = useState(false);
  const [localLogoutModal, setLocalLogoutModal] = useState(false);

  const context = React.useContext(ContextReact);
  const { dispatch } = context;

  useEffect(() => {
    // fetching book
    const fetchBook = async (nameBook) => {
      // detailbBook
      const book_ = await getBookInfoByTitle(nameBook);
      dispatch(setBookDetail({ [nameBook]: book_ }));

      // coverLink
      const cover = await getBookCoverImageURL(nameBook);
      dispatch(setCoverLink({ [nameBook]: cover }));

      // coverLink
      const audio = await getBookAudioTrialURL(nameBook);
      dispatch(setAudioLink({ [nameBook]: audio }));
    };
    const book = ["Atomic Habits", "The Little Book of Common Sense Investing"];
    book.map((data) => {
      fetchBook(data);
    });

    // Get books info
    db.collection("books").onSnapshot((snapshot) => {
      SetProducts(
        snapshot.docs.map((doc) => ({
          ...doc.data(),
        }))
      );
    });

    setLocalLogoutModal(context.data.logoutModal);
    dispatch(setLogoutModal(false));
  }, []);

  if (products && pending) {
    setPending(false);
  }

  if (pending) {
    return (
      <>
        <Loading />
      </>
    );
  }

  // open modal
  const redirect = () => {
    if (AuthenticationArea()) {
      history.push("/signup");
    } else {
      dispatch(setModalAuth({ type: "signup", modal: true }));
    }
  };

  const onClickPricingModal = () => {
    setPricingModal(true);
  };

  return (
    <div>
      <Header
        history={history}
        rightLinks={<HeaderLinks history={history} />}
        rightLinksMobile={<HeaderLinksMobile history={history} />}
        fixed
        color="transparent"
        changeColorOnScroll={{
          height: 20,
        }}
      />

      <ModalSignOut open={localLogoutModal} setOpen={setLocalLogoutModal} />
      <ModalPricing open={pricingModal} setOpen={setPricingModal} />

      <div style={{ marginTop: "100px" }} />

      {userData.user && userData.user.is_subscribed && currentUser ? (
        <>
          <NewKilasBlock products={products} />
          <div style={{ marginTop: 30 }} />
          <ComingSoonBlock products={products} />
          <div style={{ marginTop: 30 }} />
          <TestimonialBlock />
          <div style={{ marginTop: 30 }} />
          <CategoryBlock />
        </>
      ) : (
        <>
          <Container style={{ textAlign: "center" }}>
            <Grid container>
              <Grid md={3} xs={12}>
                <Hidden smDown>
                  <Link
                    to={`/book-details/The Little Book of Common Sense Investing`}
                    onClick={() => {
                      window.scrollTo(0, 0);
                    }}
                  >
                    <img
                      src={TheLittleBook}
                      className={
                        books.imgRounded +
                        " " +
                        books.imgFluid +
                        " " +
                        books.imgNewHomeBook
                      }
                    />
                  </Link>
                </Hidden>

                <Grid container>
                  <Grid item xs={12}>
                    <Hidden mdUp>
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <Link
                          to={`/book-details/The Little Book of Common Sense Investing`}
                          onClick={() => {
                            window.scrollTo(0, 0);
                          }}
                        >
                          <img
                            src={TheLittleBook}
                            className={
                              books.imgRounded +
                              " " +
                              books.imgFluid +
                              " " +
                              books.imgNewHomeBook
                            }
                          />
                        </Link>
                        <Link
                          to={`/book-details/Atomic Habits`}
                          onClick={() => {
                            window.scrollTo(0, 0);
                          }}
                        >
                          <img
                            src={AtomicHabits}
                            className={
                              books.imgRounded +
                              " " +
                              books.imgFluid +
                              " " +
                              books.imgNewHomeBook
                            }
                          />
                        </Link>
                      </div>
                    </Hidden>
                  </Grid>
                </Grid>
              </Grid>

              <Grid md={6} xs={12}>
                <Typography size="heading">
                  Malas Membaca?{" "}
                  <div>
                    <CombiningTextWithUnderline
                      text={{ underline: "Ini Solusi Buat Kamu!" }}
                      position="back"
                    />
                  </div>
                </Typography>
                <Typography>
                  Belajar rangkuman buku bisnis, investasi, dan pengembangan
                  diri terbaik dunia hanya dalam 15 menit. Dapatkan kedua buku
                  ini <strong>gratis</strong> dengan mendaftar sekarang!
                </Typography>
                {currentUser ? (
                  <Button
                    onClick={() => {
                      history.push("/pricing");
                      window.scrollTo(0, 0);
                    }}
                    round
                  >
                    Berlanggan Sekarang
                  </Button>
                ) : (
                  <Button
                    onClick={() => {
                      redirect();
                    }}
                    round
                  >
                    Daftar Gratis
                  </Button>
                )}
              </Grid>

              <Grid md={3}>
                <Hidden smDown>
                  <Link
                    to={`/book-details/Atomic Habits`}
                    onClick={() => {
                      window.scrollTo(0, 0);
                    }}
                  >
                    <img
                      src={AtomicHabits}
                      className={
                        books.imgRounded +
                        " " +
                        books.imgFluid +
                        " " +
                        books.imgNewHomeBook
                      }
                    />
                  </Link>
                </Hidden>
              </Grid>
            </Grid>
          </Container>

          <div style={{ marginTop: 30 }} />

          <BenefitsBlock
            logo={
              <img
                src={Logo}
                style={{ marginBottom: -23 }}
                className={books.imgLogoText}
              />
            }
          />

          <div style={{ marginTop: 30 }} />

          <CategoryBlock />

          <div style={{ marginTop: 30 }} />

          <NewKilasBlock products={products} />

          <div style={{ marginTop: 30 }} />

          <ComingSoonBlock products={products} />

          <div style={{ marginTop: 30 }} />

          <TestimonialBlock />

          <Container style={{ textAlign: "center" }}>
            <div style={{ marginTop: 30 }} />
            <Grid container>
              <Grid md={3} xs={12}>
                <Hidden smDown>
                  <Link
                    to={`/book-details/The Little Book of Common Sense Investing`}
                    onClick={() => {
                      window.scrollTo(0, 0);
                    }}
                  >
                    <img
                      src={TheLittleBook}
                      className={
                        books.imgRounded +
                        " " +
                        books.imgFluid +
                        " " +
                        books.imgNewHomeBook
                      }
                    />
                  </Link>
                </Hidden>

                <Grid container>
                  <Grid item xs={12}>
                    <Hidden mdUp>
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <Link
                          to={`/book-details/The Little Book of Common Sense Investing`}
                          onClick={() => {
                            window.scrollTo(0, 0);
                          }}
                        >
                          <img
                            src={TheLittleBook}
                            className={
                              books.imgRounded +
                              " " +
                              books.imgFluid +
                              " " +
                              books.imgNewHomeBook
                            }
                          />
                        </Link>
                        <Link
                          to={`/book-details/Atomic Habits`}
                          onClick={() => {
                            window.scrollTo(0, 0);
                          }}
                        >
                          <img
                            src={AtomicHabits}
                            className={
                              books.imgRounded +
                              " " +
                              books.imgFluid +
                              " " +
                              books.imgNewHomeBook
                            }
                          />
                        </Link>
                      </div>
                    </Hidden>
                  </Grid>
                </Grid>
              </Grid>

              <Grid md={6} xs={12}>
                <Typography size="heading">
                  Daftar sekarang dan{" "}
                  <CombiningTextWithUnderline
                    text={{ underline: "dapatkan kedua buku" }}
                    position="back"
                  />{" "}
                  ini gratis!
                </Typography>
                <Typography>
                  Belajar rangkuman buku{" "}
                  <strong>bisnis, investasi, dan pengembangan diri</strong>{" "}
                  terbaik dunia hanya dalam 15 menit.
                </Typography>
                {currentUser ? (
                  <Button
                    onClick={() => {
                      history.push("/pricing");
                      window.scrollTo(0, 0);
                    }}
                    round
                  >
                    Berlanggan Sekarang
                  </Button>
                ) : (
                  <Button
                    onClick={() => {
                      redirect();
                    }}
                    round
                  >
                    Daftar Gratis
                  </Button>
                )}
              </Grid>

              <Grid md={3}>
                <Hidden smDown>
                  <Link
                    to={`/book-details/Atomic Habits`}
                    onClick={() => {
                      window.scrollTo(0, 0);
                    }}
                  >
                    <img
                      src={AtomicHabits}
                      className={
                        books.imgRounded +
                        " " +
                        books.imgFluid +
                        " " +
                        books.imgNewHomeBook
                      }
                    />
                  </Link>
                </Hidden>
              </Grid>
            </Grid>
          </Container>
        </>
      )}

      {/*---------------------------------------------------------------*/}
      {/*---------------------- WHATSAPP FIXED NAV ---------------------*/}
      {/*---------------------------------------------------------------*/}
      <a href="https://wa.me/6281291176795" target="_blank">
        <div className={multi.whatsapp}>
          <Grid container spacing={3}>
            <Grid item xs={4}>
              <img
                style={{
                  width: "60px",
                }}
                src={Whatsapp}
              />
            </Grid>

            <Grid item xs={4}>
              <Typography type="bold">Butuh Bantuan?</Typography>
            </Grid>
          </Grid>
        </div>
      </a>
      <Footer />
    </div>
  );
}
