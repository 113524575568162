import React, { useState, useEffect, useContext } from "react";

// Custom components
import Footer from "../../components/Footer";
import Header from "../../components/NavBar/Header";
import HeaderLinks from "../../components/NavBar/HeaderLinks";
import Typography from "../../components/Typography";
import HeaderLinksMobile from "../../components/NavBar/HeaderLinksMobile";
import MentorBlock from "./MentorBlock";
import { IntroBlock } from "./IntroBlock";
import MultiUseMobile from "../../styles/MultiUseMobile";

// Material-UI components
import { Container, Grid } from "@material-ui/core";
import { beigeColor } from "../../styles/Style";

// Redux & Auth
import { useSelector, useDispatch } from "react-redux";
import { selectUser } from "../../feature/userSlice";
import { AuthContext } from "../../components/Routing/Auth";

// Firebase
import * as firebaseGetAllMentors from "../../firebase/firebaseGetAllMentors";
import { selectMentor, setMentorRedux } from "../../feature/reduxMentoring";

// Images
const Whatsapp =
  "https://firebasestorage.googleapis.com/v0/b/sekilasaja-999fd.appspot.com/o/Website_Images%2FWeb_Picture_Components%2FWhatsapp.png?alt=media&token=88483bb9-b9d3-4aa8-9f14-9b7f91682861";

export default function MentorPage({ history }) {
  // Styles
  const multi = MultiUseMobile();

  // Auth
  const userData = useSelector(selectUser);
  const { currentUser } = useContext(AuthContext);

  // redux
  const mentorData = useSelector(selectMentor);

  const dispatch = useDispatch();

  useEffect(() => {
    const fetchData = async () => {
      const getMentor = await firebaseGetAllMentors.getAllMentors();
      dispatch(
        setMentorRedux(
          getMentor.sort(
            (a, b) => b.spesific_date.toDate() - a.spesific_date.toDate()
          )
        )
      );
    };
    fetchData();
  }, []);
  const { isReady, data } = mentorData ? mentorData.mentorRedux : {};
  return (
    <>
      <div style={{ marginTop: "100px" }} />
      <Header
        history={history}
        rightLinks={<HeaderLinks history={history} />}
        rightLinksMobile={<HeaderLinksMobile history={history} />}
        fixed
        color="white"
      />

      <Container maxWidth="md">
        <IntroBlock
          history={history}
          isSubscribed={userData.user.is_subscribed}
          currentUser={currentUser}
        />
        <br />
        {isReady && (
          <MentorBlock
            history={history}
            mentor={data}
            isSubscribed={userData.user.is_subscribed}
            currentUser={currentUser}
          />
        )}
      </Container>

      {/*---------------------------------------------------------------*/}
      {/*---------------------- WHATSAPP FIXED NAV ---------------------*/}
      {/*---------------------------------------------------------------*/}
      <a href="https://wa.me/6281291176795" target="_blank">
        <div className={multi.whatsapp}>
          <Grid container spacing={3}>
            <Grid item xs={4}>
              <img
                style={{
                  width: "60px",
                }}
                src={Whatsapp}
              />
            </Grid>

            <Grid item xs={4}>
              <Typography type="bold">Butuh Bantuan?</Typography>
            </Grid>
          </Grid>
        </div>
      </a>
      <Footer />
    </>
  );
}
