import React from "react";

// Custom components
import Typography from "../../components/Typography";
import MultiUseMobile from "../../styles/MultiUseMobile";

// Material-UI components
import { Container, Grid, CircularProgress } from "@material-ui/core";
import { beigeColor, secondaryColor } from "../../styles/Style";

// Images
const Logo =
  "https://firebasestorage.googleapis.com/v0/b/sekilasaja-999fd.appspot.com/o/Website_Images%2FWeb_Picture_Components%2Fdark-logo.png?alt=media&token=cfd7dc4d-1687-473e-a272-4d7c66b97467";

export default function Loading() {
  // Styles
  const multi = MultiUseMobile();
  return (
    <div style={{ backgroundColor: beigeColor }}>
      <Container>
        <Grid
          container
          direction="row-reverse"
          justifyContent="center"
          alignItems="center"
        >
          <Grid item xs={12}>
            <div className={multi.extraSpace} />
          </Grid>
          <Grid item xs={12}>
            <div className={multi.extraSpace} />
          </Grid>
          <Grid item xs={12}>
            <div className={multi.extraSpace} />
          </Grid>

          <Grid style={{ textAlign: "center" }} item xs={12}>
            <div>
              <img style={{ maxWidth: 300, width: "100%" }} src={Logo} />
            </div>
            <CircularProgress style={{ color: secondaryColor }} />
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}
