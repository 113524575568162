import React from "react";
import { useHistory } from "react-router-dom";

// Material UI components
import { makeStyles, Grid, Paper } from "@material-ui/core";

// Custom components
import Typography from "../Typography";

// Firebase & Redux
import { firebaseNewGetProgress } from "../../firebase/firebaseChallenge";
import { useSelector } from "react-redux";
import { selectUser } from "../../feature/userSlice";

// Styles
const useStyles = makeStyles((theme) => ({
  root: {
    margin: "20px",
    minWidth: "max-content",
    margin: "10px",
  },
  paper: {
    padding: theme.spacing(2),
    margin: "auto",
    // minWidth: "max-content",
    boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
    borderRadius: "20px",
  },
  image: {
    width: 128,
    height: 128,
  },
  img: {
    maxWidth: "200",
    width: "100%",
  },
}));

export default function ComplexGrid({ data }) {
  const [progress, setProgress] = React.useState(0);
  const history = useHistory();

  const userData = useSelector(selectUser);
  const email = userData.user && userData.user.email;

  React.useEffect(async () => {
    const progress = await firebaseNewGetProgress({
      email,
      challenge: data.challenge,
    });
    setProgress(progress);
  }, []);

  const Card = () => {
    return (
      <Grid item xs={12} md={6}>
        <Paper
          style={{ cursor: "pointer", borderRadius: 30 }}
          onClick={() => {
            history.push({
              pathname: `/challenge/${data.tittle}`,
              state: data,
            });
            window.scrollTo(0, 0);
          }}
        >
          <Grid container>
            <Grid xs={3} item>
              <img
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                  borderRadius: "30px 0px 0px 30px",
                }}
                src={data.cover}
              />
            </Grid>

            <Grid style={{ padding: 10 }} item xs={9}>
              <Typography>
                Progress {progress.done || 0} of {data.books.length}
              </Typography>
              <Typography size="subheading">
                Challenge: {data.tittle || "Challenge Title"}
              </Typography>
              <Typography>{data.descsription}</Typography>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    );
  };
  return (
    <>
      <Card />
    </>
  );
}
