import React from "react";

// Whatsapp Button
import MultiUseMobile from "../../styles/MultiUseMobile";

// Custom components
import Typography from "../../components/Typography";
import Footer from "../../components/Footer";
import Header from "../../components/NavBar/Header";
import HeaderLinks from "../../components/NavBar/HeaderLinks";
import HeaderLinksMobile from "../../components/NavBar/HeaderLinksMobile";

// Material-UI components
import { Container, Grid } from "@material-ui/core";
import { beigeColor } from "../../styles/Style";

// Images
const Whatsapp =
  "https://firebasestorage.googleapis.com/v0/b/sekilasaja-999fd.appspot.com/o/Website_Images%2FWeb_Picture_Components%2FWhatsapp.png?alt=media&token=88483bb9-b9d3-4aa8-9f14-9b7f91682861";

export default function PrivacyPolicyPage({ history }) {
  // Styles
  const multi = MultiUseMobile();

  return (
    <div style={{ backgroundColor: beigeColor }}>
      <div style={{ marginTop: "100px" }} />
      <Header
        history={history}
        rightLinks={<HeaderLinks history={history} />}
        rightLinksMobile={<HeaderLinksMobile history={history} />}
        fixed
        color="white"
      />
      <Container maxWidth="md">
        <Typography size="heading">Kebijakan Privasi SekilasAja!</Typography>
        <Typography type="italic">Updated: January 23rd, 2022</Typography>
        <br />
        <Typography>
          KEBIJAKAN PRIVASI SekilasAja! ("kami" atau "milik kami") berkomitmen
          untuk melindungi privasi Anda. Kebijakan Privasi ini menjelaskan
          bagaimana informasi pribadi Anda dikumpulkan, digunakan, dan
          diungkapkan oleh SekilasAja!. Kebijakan Privasi ini berlaku untuk
          situs web kami, dan subdomainnya yang terkait (secara kolektif,
          "Layanan" kami) di samping aplikasi kami, SekilasAja!. Dengan
          mengakses atau menggunakan Layanan kami, Anda menandakan bahwa Anda
          telah membaca, memahami, dan menyetujui pengumpulan, penyimpanan,
          penggunaan, dan pengungkapan informasi pribadi Anda seperti yang
          dijelaskan dalam Kebijakan Privasi ini dan Persyaratan Layanan kami.
        </Typography>
        <Typography size="subheading">DEFINISI DAN ISTILAH PENTING</Typography>
        <Typography>
          Untuk membantu menjelaskan hal-hal sejelas mungkin dalam Kebijakan
          Privasi ini, setiap kali salah satu istilah ini dirujuk, secara tegas
          didefinisikan sebagai: Cookie: sejumlah kecil data yang dihasilkan
          oleh situs web dan disimpan oleh browser web Anda. Ini digunakan untuk
          mengidentifikasi browser Anda, menyediakan analisis, mengingat
          informasi tentang Anda, seperti preferensi bahasa atau informasi login
          Anda. Perusahaan: ketika kebijakan ini menyebutkan “Perusahaan,”
          “kami,” atau “milik kami,” ini merujuk pada SekilasAja! yang
          bertanggung jawab atas informasi Anda berdasarkan Syarat & Ketentuan
          ini. Negara: lokasi dimana SekilasAja! atau pemilik atau pendiri
          SekilasAja! berada, dalam hal ini adalah negara Indonesia Device:
          perangkat yang terhubung dengan internet, seperti ponsel, tablet,
          laptop, atau perangkat lain yang dapat digunakan untuk mengunjungi
          situs web SekilasAja! dan menggunakan layanan SekilasAja!. Layanan:
          mengacu pada layanan yang disediakan oleh SekilasAja! seperti yang
          dijelaskan dalam istilah relatif (jika tersedia) dan pada platform
          ini. Layanan pihak ketiga: mengacu pada pengiklan, sponsor, mitra
          promosi dan pemasaran, dan orang lain yang menyediakan konten kami
          atau yang produk atau layanannya menurut kami mungkin menarik bagi
          Anda Situs web: situs SekilasAja! yang dapat diakses melalui URL ini:
          https://sekilasaja.com/ Anda: orang atau pihak yang terdaftar di
          SekilasAja! untuk menggunakan Layanan SekilasAja!.
        </Typography>

        <Typography size="subheading">
          INFORMASI APA YANG KAMI KUMPULKAN?
        </Typography>
        <Typography>
          Kami mengumpulkan informasi dari Anda ketika Anda mengunjungi situs
          web/aplikasi kami, mendaftar di situs kami, melakukan pemesanan,
          berlangganan buletin kami, menanggapi survei, atau mengisi formulir.
          Adapun informasi yang kami kumpulkan adalah sebagai berikut: Nama /
          Nama Pengguna Nomor Telepon Alamat Email Kami juga mengumpulkan
          informasi dari perangkat seluler untuk pengalaman pengguna yang lebih
          baik, meskipun fitur-fitur ini sepenuhnya opsional: Kamera (Gambar):
          Memberikan izin kamera memungkinkan pengguna untuk mengunggah gambar
          apapun langsung dari situs web/aplikasi, Anda dapat dengan aman
          menolak izin kamera untuk situs web/aplikasi ini. Galeri Foto
          (Gambar): Memberikan akses galeri foto memungkinkan pengguna
          mengunggah gambar apapun dari galeri foto mereka, Anda dapat dengan
          aman menolak akses galeri foto untuk situs web/aplikasi ini.
        </Typography>

        <Typography size="subheading">
          BAGAIMANA KAMI MENGGUNAKAN INFORMASI YANG KAMI KUMPULKAN?
        </Typography>

        <Typography>
          Setiap informasi yang kami kumpulkan dari Anda dapat digunakan dengan
          salah satu cara berikut: Untuk mempersonalisasi pengalaman Anda
          (informasi Anda membantu kami untuk lebih menanggapi kebutuhan pribadi
          Anda) Untuk meningkatkan situs web/aplikasi kami (kami terus berupaya
          untuk meningkatkan penawaran situs web/aplikasi kami berdasarkan
          informasi dan umpan balik yang kami terima dari Anda) Untuk
          meningkatkan layanan pelanggan (informasi Anda membantu kami untuk
          lebih efektif menanggapi permintaan layanan pelanggan dan kebutuhan
          dukungan) Untuk memproses transaksi Untuk mengelola promosi, survei,
          atau fitur situs lainnya Untuk mengirim email berkala.
        </Typography>

        <Typography size="subheading">
          KAPAN SEKILASAJA! MENGGUNAKAN INFORMASI PENGGUNA AKHIR DARI PIHAK
          KETIGA?
        </Typography>
        <Typography>
          SekilasAja! akan mengumpulkan Data Pengguna Akhir yang diperlukan
          untuk menyediakan layanan kami kepada pelanggan kami. Pengguna akhir
          dapat secara sukarela memberi kami informasi yang telah mereka
          sediakan di situs web media sosial. Jika Anda memberi kami informasi,
          kami dapat mengumpulkan informasi yang tersedia untuk umum dari situs
          web media sosial yang Anda tunjukkan. Anda dapat mengontrol seberapa
          banyak informasi Anda yang dipublikasikan oleh situs web media sosial
          dengan mengunjungi situs web ini dan mengubah pengaturan privasi Anda.
        </Typography>

        <Typography size="subheading">
          KAPAN SEKILASAJA! MENGGUNAKAN INFORMASI PELANGGAN DARI PIHAK KETIGA?
        </Typography>
        <Typography>
          Kami menerima beberapa informasi dari pihak ketiga saat Anda
          menghubungi kami. Misalnya, ketika Anda mengirimkan alamat email Anda
          kepada kami untuk menunjukkan minat menjadi pelanggan SekilasAja!,
          kami menerima informasi dari pihak ketiga yang menyediakan layanan
          deteksi penipuan otomatis untuk SekilasAja!. Kami juga terkadang
          mengumpulkan informasi yang tersedia untuk umum di situs media sosial.
          Anda dapat mengontrol seberapa banyak informasi Anda yang
          dipublikasikan oleh situs web media sosial dengan mengunjungi situs
          web ini dan mengubah pengaturan privasi Anda.
        </Typography>

        <Typography size="subheading">
          APAKAH KAMI MEMBAGIKAN INFORMASI YANG KAMI KUMPULKAN DENGAN PIHAK
          KETIGA?
        </Typography>
        <Typography>
          Kami dapat membagikan informasi yang kami kumpulkan, baik pribadi
          maupun non-pribadi, dengan pihak ketiga seperti pengiklan, sponsor,
          mitra promosi dan pemasaran, dan pihak lain yang menyediakan konten
          kami atau yang produk atau layanannya menurut kami mungkin menarik
          bagi Anda. Kami juga dapat membagikannya dengan perusahaan afiliasi
          dan mitra bisnis kami saat ini dan di masa depan, dan jika kami
          terlibat dalam merger, penjualan aset, atau reorganisasi bisnis
          lainnya, kami juga dapat membagikan atau mentransfer informasi pribadi
          dan non-pribadi Anda kepada penerus kami. Kami dapat melibatkan
          penyedia layanan pihak ketiga tepercaya untuk menjalankan fungsi dan
          memberikan layanan kepada kami, seperti hosting dan memelihara server
          dan situs web/aplikasi kami, penyimpanan dan pengelolaan basis data,
          pengelolaan email, pemasaran penyimpanan, pemrosesan kartu kredit,
          layanan pelanggan, dan memenuhi pesanan untuk produk dan layanan yang
          dapat Anda beli melalui situs web/aplikasi. Kami kemungkinan akan
          membagikan informasi pribadi Anda, dan mungkin beberapa informasi
          non-pribadi, dengan pihak ketiga ini agar mereka dapat melakukan
          layanan ini untuk kami dan Anda. Kami dapat membagikan sebagian dari
          data file log kami, termasuk alamat IP, untuk tujuan analitik dengan
          pihak ketiga seperti mitra analitik web, pengembang aplikasi, dan
          jaringan iklan. Jika alamat IP Anda dibagikan, itu dapat digunakan
          untuk memperkirakan lokasi umum dan teknik lain seperti kecepatan
          koneksi, apakah Anda telah mengunjungi situs web/aplikasi di lokasi
          yang dibagikan, dan jenis perangkat yang digunakan untuk mengunjungi
          situs web/aplikasi. Mereka dapat mengumpulkan informasi tentang iklan
          kami dan apa yang Anda lihat di situs web/aplikasi dan kemudian
          memberikan audit, penelitian, dan pelaporan untuk kami dan pengiklan
          kami. Kami juga dapat mengungkapkan informasi pribadi dan non-pribadi
          tentang Anda kepada pemerintah atau pejabat penegak hukum atau pihak
          swasta karena kami, atas kebijakan kami sendiri, percaya perlu atau
          sesuai untuk menanggapi klaim, proses hukum (termasuk panggilan
          pengadilan), untuk melindungi kami. Hak dan kepentingan atau
          kepentingan pihak ketiga, keselamatan publik atau siapapun, untuk
          mencegah atau menghentikan aktivitas ilegal, tidak etis, atau yang
          dapat ditindaklanjuti secara hukum, atau untuk mematuhi perintah
          pengadilan, undang-undang, aturan, dan regulasi yang berlaku.
        </Typography>

        <Typography size="subheading">
          DIMANA DAN KAPAN INFORMASI DIKUMPULKAN DARI PELANGGAN DAN PENGGUNA
          AKHIR?
        </Typography>
        <Typography>
          SekilasAja! akan mengumpulkan informasi pribadi yang Anda kirimkan
          kepada kami. Kami juga dapat menerima informasi pribadi tentang Anda
          dari pihak ketiga seperti yang dijelaskan di atas.
        </Typography>

        <Typography size="subheading">
          BAGAIMANA KAMI MENGGUNAKAN ALAMAT EMAIL ANDA?
        </Typography>
        <Typography>
          Dengan mengirimkan alamat email Anda di situs web/aplikasi ini, Anda
          setuju untuk menerima email dari kami. Anda dapat membatalkan
          partisipasi Anda dalam salah satu daftar email ini kapan saja dengan
          mengklik tautan keluar atau opsi berhenti berlangganan lainnya yang
          disertakan di masing-masing email. Kami hanya mengirim email ke orang
          yang telah memberi kami otorisasi untuk menghubungi mereka, baik
          secara langsung, atau melalui pihak ketiga. Kami tidak mengirim email
          komersial yang tidak diminta, karena kami membenci spam seperti halnya
          Anda. Dengan mengirimkan alamat email Anda, Anda juga setuju untuk
          mengizinkan kami menggunakan alamat email Anda untuk penargetan
          audiens pelanggan di situs seperti Facebook, tempat kami menampilkan
          iklan khusus kepada orang-orang tertentu yang telah memilih untuk
          menerima komunikasi dari kami. Alamat email yang dikirimkan hanya
          melalui halaman pemrosesan pesanan akan digunakan semata-mata untuk
          tujuan mengirimi Anda informasi dan pembaruan yang berkaitan dengan
          pesanan Anda. Namun, jika Anda telah memberikan email yang sama kepada
          kami melalui metode lain, kami dapat menggunakannya untuk tujuan
          apapun yang dinyatakan dalam Kebijakan ini. Catatan: Jika suatu saat
          Anda ingin berhenti berlangganan menerima email di masa mendatang,
          kami menyertakan petunjuk berhenti berlangganan mendetail di bagian
          bawah setiap email.
        </Typography>

        <Typography size="subheading">
          BAGAIMANA KAMI MENGGUNAKAN ALAMAT EMAIL ANDA?
        </Typography>
        <Typography>
          Kami menyimpan informasi Anda hanya selama kami membutuhkannya untuk
          memberikan bimbingan kepada Anda dan memenuhi tujuan yang dijelaskan
          dalam kebijakan ini. Ini juga berlaku untuk siapapun yang kami bagikan
          informasi Anda dan yang melakukan layanan atas nama kami. Jika kami
          tidak lagi perlu menggunakan informasi Anda dan tidak perlu
          menyimpannya untuk mematuhi kewajiban hukum atau peraturan, kami akan
          menghapusnya dari sistem kami atau membatalkannya sehingga kami tidak
          dapat mengidentifikasi Anda.
        </Typography>

        <Typography size="subheading">
          BAGAIMANA KAMI MELINDUNGI INFORMASI ANDA?
        </Typography>
        <Typography>
          Kami menerapkan berbagai langkah keamanan untuk menjaga keamanan
          informasi pribadi Anda saat Anda melakukan pemesanan atau memasukkan,
          mengirimkan, atau mengakses informasi pribadi Anda. Kami menawarkan
          penggunaan server yang aman. Semua informasi sensitif/kredit yang
          diberikan dikirimkan melalui teknologi Secure Socket Layer (SSL) dan
          kemudian dienkripsi ke dalam basis data penyedia gateway Pembayaran
          kami hanya untuk dapat diakses oleh mereka yang berwenang dengan hak
          akses khusus ke sistem tersebut, dan diwajibkan untuk menjaga
          kerahasiaan informasi tersebut. Setelah transaksi, informasi pribadi
          Anda (kartu kredit, nomor jaminan sosial, keuangan, dll) tidak pernah
          disimpan dalam file. Kami tidak dapat, bagaimanapun, memastikan atau
          menjamin keamanan mutlak dari setiap informasi yang Anda kirimkan ke
          SekilasAja! atau menjamin bahwa informasi Anda di Layanan tidak dapat
          diakses, diungkapkan, diubah, atau dihancurkan oleh pelanggaran dari
          salah satu pengamanan fisik, teknis, atau manajerial kami.
        </Typography>

        <Typography size="subheading">
          BISAKAH INFORMASI SAYA DITRANSFER KE NEGARA LAIN?
        </Typography>
        <Typography>
          SekilasAja! berada di negara Indonesia. Informasi yang dikumpulkan
          melalui situs web kami, melalui interaksi langsung dengan Anda, atau
          dari penggunaan layanan bantuan kami dapat ditransfer dari waktu ke
          waktu ke kantor atau personel kami, atau ke pihak ketiga, yang
          berlokasi di seluruh dunia, dan dapat dilihat dan dihosting dimana
          saja di dunia, termasuk negara-negara yang mungkin tidak memiliki
          undang-undang penerapan umum yang mengatur penggunaan dan transfer
          data semacam itu. Sejauh diizinkan oleh hukum yang berlaku, dengan
          menggunakan salah satu dari yang di atas, Anda secara sukarela
          menyetujui transfer lintas batas dan menampung informasi tersebut.
        </Typography>

        <Typography size="subheading">
          APAKAH INFORMASI YANG DIKUMPULKAN MELALUI SEKILASAJA! AMAN?
        </Typography>
        <Typography>
          Kami melakukan tindakan pencegahan untuk melindungi keamanan informasi
          Anda. Kami memiliki prosedur fisik, elektronik, dan manajerial untuk
          membantu menjaga, mencegah akses yang tidak sah, menjaga keamanan
          data, dan menggunakan informasi Anda dengan benar. Akan tetapi, baik
          orang maupun sistem keamanan tidak bisa dibantah, termasuk sistem
          enkripsi. Selain itu, orang dapat melakukan kejahatan yang disengaja,
          membuat kesalahan, atau gagal mengikuti kebijakan. Oleh karena itu,
          meskipun kami menggunakan upaya yang wajar untuk melindungi informasi
          pribadi Anda, kami tidak dapat menjamin keamanan mutlaknya. Jika hukum
          yang berlaku memberlakukan kewajiban yang tidak dapat disangkal untuk
          melindungi informasi pribadi Anda, Anda setuju bahwa kesalahan yang
          disengaja akan menjadi standar yang digunakan untuk mengukur kepatuhan
          terhadap kewajiban tersebut.
        </Typography>

        <Typography size="subheading">
          DAPATKAH SAYA MENGOREKSI ATAU MEMPERBARUI INFORMASI SAYA?
        </Typography>
        <Typography>
          Hak-hak yang Anda miliki untuk meminta pembaruan atau koreksi atas
          informasi yang dikumpulkan oleh SekilasAja! bergantung pada hubungan
          Anda dengan SekilasAja!. Personel dapat memperbarui atau mengoreksi
          informasi mereka seperti yang dijelaskan dalam kebijakan
          ketenagakerjaan internal perusahaan kami. Pelanggan memiliki hak untuk
          meminta pembatasan penggunaan tertentu dan pengungkapan informasi
          pribadi sebagai berikut. Anda dapat menghubungi kami untuk (1)
          memperbarui atau memperbaiki informasi pribadi Anda, (2) mengubah
          preferensi Anda sehubungan dengan komunikasi dan informasi lain yang
          Anda terima dari kami, atau (3) menghapus informasi pribadi yang
          disimpan tentang Anda di situs kami (tunduk pada paragraf berikut),
          dengan membatalkan akun Anda. Pembaruan, koreksi, perubahan, dan
          penghapusan tersebut tidak akan berpengaruh pada informasi lain yang
          kami simpan, atau informasi yang kami berikan kepada pihak ketiga
          sesuai dengan Kebijakan Privasi ini sebelum pembaruan, koreksi,
          perubahan, atau penghapusan tersebut. Untuk melindungi privasi dan
          keamanan Anda, kami dapat mengambil langkah-langkah yang wajar
          (seperti meminta kata sandi unik) untuk memverifikasi informasi Anda
          sebelum memberi Anda akses profil atau melakukan koreksi. Anda
          bertanggung jawab untuk menjaga kerahasiaan kata sandi unik dan
          informasi akun Anda setiap saat. Anda harus menyadari bahwa secara
          teknologi tidak mungkin untuk menghapus setiap catatan informasi yang
          Anda berikan kepada kami dari sistem kami. Kebutuhan untuk
          mencadangkan sistem kami untuk melindungi informasi dari kehilangan
          yang tidak disengaja berarti bahwa salinan informasi Anda mungkin ada
          dalam bentuk yang tidak dapat dihapus yang akan sulit atau tidak
          mungkin kami temukan. Segera setelah menerima permintaan Anda, semua
          informasi pribadi yang disimpan dalam database yang kami gunakan
          secara aktif, dan media lain yang mudah dicari akan diperbarui,
          diperbaiki, diubah atau dihapus, sebagaimana mestinya, secepat dan
          sejauh secara wajar dan secara teknis dapat dilakukan. Jika Anda
          adalah pengguna akhir dan ingin memperbarui, menghapus, atau menerima
          informasi apapun yang kami miliki tentang Anda, Anda dapat
          melakukannya dengan menghubungi organisasi tempat Anda menjadi
          pelanggan.
        </Typography>

        <Typography size="subheading">PERSONEL</Typography>
        <Typography>
          Jika Anda adalah pekerja SekilasAja! atau pelamar, kami mengumpulkan
          informasi yang Anda berikan secara sukarela kepada kami. Kami
          menggunakan informasi yang dikumpulkan untuk tujuan Sumber Daya
          Manusia untuk mengelola manfaat bagi pekerja dan menyaring pelamar.
          Anda dapat menghubungi kami untuk (1) memperbarui atau memperbaiki
          informasi Anda, (2) mengubah preferensi Anda sehubungan dengan
          komunikasi dan informasi lain yang Anda terima dari kami, atau (3)
          menerima catatan informasi yang kami miliki terkait dengan Anda.
          Pembaruan, koreksi, perubahan, dan penghapusan tersebut tidak akan
          berpengaruh pada informasi lain yang kami simpan, atau informasi yang
          kami berikan kepada pihak ketiga sesuai dengan Kebijakan Privasi ini
          sebelum pembaruan, koreksi, perubahan, atau penghapusan tersebut.
        </Typography>

        <Typography size="subheading">PENJUALAN BISNIS</Typography>
        <Typography>
          Kami berhak untuk mentransfer informasi kepada pihak ketiga jika
          terjadi penjualan, merger atau pengalihan lainnya dari semua atau
          secara substansial semua aset SekilasAja! atau salah satu Afiliasi
          Perusahaannya (sebagaimana didefinisikan di sini), atau bagian
          SekilasAja! atau salah satu Afiliasi Korporatnya yang terkait dengan
          Layanan, atau dalam hal kami menghentikan bisnis kami atau telah
          mengajukan petisi kepada kami dalam kebangkrutan, reorganisasi atau
          proses serupa, asalkan pihak ketiga setuju untuk mematuhi persyaratan
          dari Kebijakan Privasi ini.
        </Typography>

        <Typography size="subheading">AFILIASI</Typography>
        <Typography>
          Kami dapat mengungkapkan informasi (termasuk informasi pribadi)
          tentang Anda kepada Afiliasi Perusahaan kami. Untuk tujuan Kebijakan
          Privasi ini, "Afiliasi Perusahaan" berarti setiap orang atau pihak
          yang secara langsung atau tidak langsung mengendalikan, dikendalikan
          oleh atau berada di bawah kendali yang sama dengan SekilasAja!, baik
          dengan kepemilikan atau sebaliknya. Setiap informasi yang berkaitan
          dengan Anda yang kami berikan kepada Afiliasi Perusahaan kami akan
          diperlakukan oleh Afiliasi Perusahaan tersebut sesuai dengan ketentuan
          Kebijakan Privasi ini.
        </Typography>

        <Typography size="subheading">PERATURAN PEMERINTAH</Typography>
        <Typography>
          Kebijakan Privasi ini diatur oleh hukum Indonesia tanpa memperhatikan
          pertentangan ketentuan hukum. Anda menyetujui yurisdiksi eksklusif
          pengadilan sehubungan dengan tindakan atau perselisihan apapun yang
          timbul antara para pihak di bawah atau sehubungan dengan Kebijakan
          Privasi ini kecuali untuk individu yang mungkin memiliki hak untuk
          membuat klaim di bawah Perlindungan Privasi, atau kerangka kerja
          Swiss-AS. Hukum Indonesia, tidak termasuk aturan pertentangan hukum,
          akan mengatur Perjanjian ini dan penggunaan Anda atas situs
          web/aplikasi. Penggunaan Anda atas situs web/aplikasi juga tunduk pada
          hukum lokal, negara bagian, nasional, atau internasional lainnya.
          Dengan menggunakan SekilasAja! atau menghubungi kami secara langsung,
          Anda menandakan penerimaan Anda terhadap Kebijakan Privasi ini. Jika
          Anda tidak setuju dengan Kebijakan Privasi ini, Anda tidak boleh
          terlibat dengan situs web kami, atau menggunakan layanan kami.
          Penggunaan situs web yang berkelanjutan, keterlibatan langsung dengan
          kami, atau setelah posting perubahan pada Kebijakan Privasi ini yang
          tidak secara signifikan memengaruhi penggunaan atau pengungkapan
          informasi pribadi Anda, berarti Anda menerima perubahan tersebut.
        </Typography>

        <Typography size="subheading">PERSETUJUAN ANDA</Typography>
        <Typography>
          Kami telah memperbarui Kebijakan Privasi kami untuk memberi Anda
          transparansi lengkap tentang apa yang diatur saat Anda mengunjungi
          situs kami dan bagaimana situs itu digunakan. Dengan menggunakan situs
          web/aplikasi kami, mendaftarkan akun, atau melakukan pembelian, Anda
          dengan ini menyetujui Kebijakan Privasi kami dan menyetujui
          persyaratannya.
        </Typography>

        <Typography size="subheading">COOKIE</Typography>
        <Typography>
          SekilasAja! menggunakan "Cookies" untuk mengidentifikasi area situs
          web kami yang telah Anda kunjungi. Cookie adalah sepotong kecil data
          yang disimpan di komputer atau perangkat seluler Anda oleh browser web
          Anda. Kami menggunakan Cookies untuk meningkatkan kinerja dan
          fungsionalitas situs web/aplikasi kami tetapi tidak penting untuk
          penggunaannya. Namun, tanpa cookie ini, fungsionalitas tertentu
          seperti video mungkin menjadi tidak tersedia atau Anda akan diminta
          untuk memasukkan detail login Anda setiap kali Anda mengunjungi situs
          web/aplikasi karena kami tidak dapat mengingat bahwa Anda telah login
          sebelumnya. Sebagian besar browser web memiliki pengaturan untuk
          menonaktifkan penggunaan Cookies. Namun, jika Anda menonaktifkan
          Cookies, Anda mungkin tidak dapat mengakses fungsionalitas di situs
          web kami dengan benar atau sama sekali. Kami tidak pernah menempatkan
          Informasi Pribadi dalam Cookie.
        </Typography>

        <Typography size="subheading">
          MEMBLOKIR DAN MENONAKTIFKAN COOKIE DAN TEKNOLOGI SERUPA
        </Typography>
        <Typography>
          Di mana pun Anda berada, Anda juga dapat mengatur browser Anda untuk
          memblokir cookie dan teknologi serupa, tetapi tindakan ini dapat
          memblokir cookie penting kami dan mencegah situs web kami berfungsi
          dengan baik, dan Anda mungkin tidak dapat sepenuhnya memanfaatkan
          semua fitur dan layanannya. Anda juga harus menyadari bahwa Anda
          mungkin juga kehilangan beberapa informasi yang disimpan (misalnya
          detail login yang disimpan, preferensi situs) jika Anda memblokir
          cookie di browser Anda. Browser yang berbeda memiliki pengaturan yang
          berbeda untuk Anda. Menonaktifkan cookie atau kategori cookie tidak
          menghapus cookie dari browser Anda, Anda harus melakukannya sendiri
          dari dalam browser Anda, Anda harus mengunjungi menu bantuan browser
          Anda untuk informasi lebih lanjut.
        </Typography>

        <Typography size="subheading">LAYANAN PEMASARAN ULANG</Typography>
        <Typography>
          Kami menggunakan layanan pemasaran ulang. Apa Itu Pemasaran Ulang?
          Dalam pemasaran digital, pemasaran ulang (atau penargetan ulang)
          adalah praktik menayangkan iklan di internet kepada orang-orang yang
          telah mengunjungi situs web Anda. Ini memungkinkan perusahaan Anda
          terlihat seperti mereka "mengikuti" orang-orang di internet dengan
          menayangkan iklan di situs web dan platform yang paling sering mereka
          gunakan.
        </Typography>

        <Typography size="subheading">RINCIAN PEMBAYARAN</Typography>
        <Typography>
          Sehubungan dengan kartu kredit atau rincian pemrosesan pembayaran lain
          yang Anda berikan kepada kami, kami berkomitmen bahwa informasi
          rahasia ini akan disimpan dengan cara yang paling aman.
        </Typography>

        <Typography size="subheading">PRIVASI ANAK</Typography>
        <Typography>
          Kami mengumpulkan informasi dari anak-anak di bawah usia 13 tahun
          hanya untuk meningkatkan layanan kami. Jika Anda adalah orangtua atau
          wali dan Anda mengetahui bahwa anak Anda telah memberikan Data Pribadi
          kepada kami tanpa izin Anda, silakan hubungi kami. Jika Kami
          mengetahui bahwa Kami telah mengumpulkan Data Pribadi dari siapa pun
          yang berusia di bawah 13 tahun tanpa verifikasi izin orangtua, kami
          mengambil langkah-langkah untuk menghapus informasi tersebut dari
          server kami.
        </Typography>

        <Typography size="subheading">
          PERUBAHAN KEBIJAKAN PRIVASI KAMI
        </Typography>
        <Typography>
          Kami dapat mengubah Layanan dan kebijakan kami, dan kami mungkin perlu
          membuat perubahan pada Kebijakan Privasi ini sehingga secara akurat
          mencerminkan Layanan dan kebijakan kami. Kecuali jika diwajibkan oleh
          hukum, kami akan memberitahu Anda (misalnya, melalui Layanan kami)
          sebelum kami membuat perubahan pada Kebijakan Privasi ini dan memberi
          Anda kesempatan untuk meninjaunya sebelum diberlakukan. Kemudian, jika
          Anda terus menggunakan Layanan, Anda akan terikat oleh Kebijakan
          Privasi yang diperbarui. Jika Anda tidak ingin menyetujui ini atau
          Kebijakan Privasi apapun yang diperbarui, Anda dapat menghapus akun
          Anda.
        </Typography>

        <Typography size="subheading">LAYANAN PIHAK KETIGA</Typography>
        <Typography>
          Kami dapat menampilkan, memasukkan, atau menyediakan konten pihak
          ketiga (termasuk data, informasi, aplikasi, dan layanan produk
          lainnya) atau menyediakan tautan ke situs web atau layanan pihak
          ketiga ("Layanan Pihak Ketiga"). Anda mengakui dan setuju bahwa
          SekilasAja! tidak bertanggung jawab atas Layanan Pihak Ketiga,
          termasuk keakuratan, kelengkapan, ketepatan waktu, validitas,
          kepatuhan hak cipta, legalitas, kesusilaan, kualitas atau aspek
          lainnya daripadanya. SekilasAja! tidak menganggap dan tidak akan
          memiliki kewajiban atau tanggung jawab apapun kepada Anda atau orang
          atau pihak lain mana pun untuk Layanan Pihak Ketiga mana pun. Layanan
          Pihak Ketiga dan tautannya disediakan hanya untuk kenyamanan Anda dan
          Anda mengakses dan menggunakannya sepenuhnya atas risiko Anda sendiri
          dan tunduk pada syarat dan ketentuan pihak ketiga tersebut.
        </Typography>

        <Typography size="subheading">TEKNOLOGI PELACAKAN</Typography>
        <Typography>
          Penyimpanan Lokal Penyimpanan Lokal terkadang dikenal sebagai
          penyimpanan DOM, menyediakan aplikasi web dengan metode dan protokol
          untuk menyimpan data sisi klien. Penyimpanan web mendukung penyimpanan
          data yang persisten, mirip dengan cookie tetapi dengan kapasitas yang
          sangat ditingkatkan dan tidak ada informasi yang disimpan di header
          permintaan HTTP. Sesi $ elnombre menggunakan "Sesi" untuk
          mengidentifikasi area situs web kami yang telah Anda kunjungi. Sesi
          adalah sepotong kecil data yang disimpan di komputer atau perangkat
          seluler Anda oleh browser web Anda.
        </Typography>

        <Typography size="subheading">HUBUNGI KAMI</Typography>
        <Typography>
          Jangan ragu untuk menghubungi kami jika Anda memiliki pertanyaan: Via
          e-mail: hi@sekilasaja.com Via telepon: +62812-9117-6795
        </Typography>
      </Container>

      {/*---------------------------------------------------------------*/}
      {/*---------------------- WHATSAPP FIXED NAV ---------------------*/}
      {/*---------------------------------------------------------------*/}
      <a href="https://wa.me/6281291176795" target="_blank">
        <div className={multi.whatsapp}>
          <Grid container spacing={3}>
            <Grid item xs={4}>
              <img
                style={{
                  width: "60px",
                }}
                src={Whatsapp}
              />
            </Grid>

            <Grid item xs={4}>
              <Typography type="bold">Butuh Bantuan?</Typography>
            </Grid>
          </Grid>
        </div>
      </a>
      <Footer />
    </div>
  );
}
