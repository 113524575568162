import React from "react";
function MobileVideo({ src, className }) {
  return (
    <div>
      <iframe
        type="text/html"
        className={className}
        src={src}
        frameborder="0"
        allow="fullscreen"
      />
    </div>
  );
}

export default MobileVideo;
