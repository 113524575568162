import React from "react";
import { makeStyles } from "@material-ui/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import TablePagination from "@material-ui/core/TablePagination";
import { DateToFormattedString } from "../../../utils/dateFormatted";
import ExportListPayment from "../exported/ListPayment";
import { Button } from "@material-ui/core";

const useStyles = makeStyles({
  table: {
    minWidth: 650
  },
  root: {
    width: "100%"
  },
  container: {
    maxHeight: 440
  }
});

// export function TablePayment(name, code, population, size) {
//   const density = population / size;
//   return { name, code, population, size, density };
// }

export default function TablePayment({ dataPayment }) {
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <Paper className={classes.root}>
      <Button
        style={{
          background: "lightblue",
          padding: "0px",
          margin: "1px 1px 3px 1px"
        }}
      >
        <ExportListPayment data={dataPayment} />
      </Button>
      <TableContainer component={Paper} className={classes.container}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              <TableCell>tanggal</TableCell>
              <TableCell align="center">email</TableCell>
              <TableCell align="center">nomor telepon</TableCell>
              <TableCell align="center">nama</TableCell>
              <TableCell align="center">item</TableCell>
              <TableCell align="center">total</TableCell>
              <TableCell align="center">sedang proses</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {dataPayment &&
              dataPayment
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => {
                  const ni = {
                    date: row.date
                      ? DateToFormattedString(row.date.toDate())
                      : "",
                    email: row.email,
                    phoneNumber: row.phoneNumber,
                    first_name: row.fn || row.firstName,
                    items: row.info ? row.info.items[0].title : "",
                    total_cost: row.info ? row.info.total_cost : 0,
                    is_procces: row.is_processed ? "yes" : "no"
                  };

                  const {
                    email,
                    first_name,
                    date,
                    phoneNumber,
                    items,
                    total_cost,
                    is_procces
                  } = ni;

                  return (
                    <TableRow key={Math.random()}>
                      <TableCell component="th" scope="row">
                        {date}
                      </TableCell>
                      <TableCell align="center">{email}</TableCell>
                      <TableCell align="center">{phoneNumber}</TableCell>
                      <TableCell align="center">{first_name}</TableCell>
                      <TableCell align="center">{items}</TableCell>
                      <TableCell align="center">{total_cost}</TableCell>
                      <TableCell align="center">{is_procces}</TableCell>
                    </TableRow>
                  );
                })}
          </TableBody>
        </Table>
      </TableContainer>
      {dataPayment && (
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={dataPayment.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      )}
    </Paper>
  );
}
