import React from "react";
import PropTypes from "prop-types";

// Material-UI components
import { IconButton, Backdrop, Paper, Dialog } from "@material-ui/core";
import { Close } from "@material-ui/icons";

// Custom components
import Typography from "../../components/Typography";
import Button from "../../components/Button";

// Springweb
import { useSpring, animated } from "@react-spring/web"; // web.cjs is required for IE 11 support

// Images
const Logo =
  "https://firebasestorage.googleapis.com/v0/b/sekilasaja-999fd.appspot.com/o/Website_Images%2FWeb_Picture_Components%2Fdark-logo.png?alt=media&token=cfd7dc4d-1687-473e-a272-4d7c66b97467";

const Fade = React.forwardRef(function Fade(props, ref) {
  const { in: open, children, onEnter, onExited, ...other } = props;
  const style = useSpring({
    from: { opacity: 0 },
    to: { opacity: open ? 1 : 0 },
    onStart: () => {
      if (open && onEnter) {
        onEnter();
      }
    },
    onRest: () => {
      if (!open && onExited) {
        onExited();
      }
    },
  });

  return (
    <animated.div ref={ref} style={style} {...other}>
      {children}
    </animated.div>
  );
});

Fade.propTypes = {
  children: PropTypes.element,
  in: PropTypes.bool.isRequired,
  onEnter: PropTypes.func,
  onExited: PropTypes.func,
};

export default function ModalSignOut({ open, setOpen }) {
  // Functions
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
      scroll={"body"}
    >
      <Fade in={open}>
        <IconButton
          onClick={() => {
            handleClose();
          }}
          style={{ position: "absolute", right: 0 }}
        >
          <Close />
        </IconButton>
        <Paper
          style={{
            padding: 20,
            width: "100%",
            height: "auto",
          }}
        >
          <div
            style={{
              textAlign: "center",
            }}
          >
            <br />
            <img style={{ maxWidth: 200 }} src={Logo} />
            <br />
            <Typography>Kamu telah berhasil keluar!</Typography>
            <Button
              style={{ marginBottom: "30px" }}
              onClick={() => {
                handleClose();
              }}
              round
            >
              Tutup
            </Button>
          </div>
        </Paper>
      </Fade>
    </Dialog>
  );
}
