import React from "react";
import { makeStyles } from "@material-ui/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import TablePagination from "@material-ui/core/TablePagination";
import { DateToFormattedString } from "../../utils/dateFormatted";
import ExportListPayment from "./exported/ListPayment";
import { Button } from "@material-ui/core";
import ExportListUserSubsUnSubs from "./exported/ListUserSubsUnSubs";

const useStyles = makeStyles({
  table: {
    minWidth: 650
  },
  root: {
    width: "100%"
  },
  container: {
    maxHeight: 440
  }
});

export function TableUser(name, code, population, size) {
  const density = population / size;
  return { name, code, population, size, density };
}

export default function StickyHeadTable({ dataUser }) {
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <Paper className={classes.root}>
      {dataUser && (
        <>
          <Button
            style={{
              background: "lightblue",
              padding: "0px",
              margin: "1px 1px 3px 1px"
            }}
          >
            <ExportListUserSubsUnSubs
              data={dataUser.filter((data) => !data.doc.data().is_subscribed)}
              titleButton="Export User Non Subscibe"
              fileName="List User Non Subscibe"
            />
          </Button>
          <Button
            style={{
              background: "lightblue",
              padding: "0px",
              margin: "1px 1px 3px 1px"
            }}
          >
            <ExportListUserSubsUnSubs
              data={dataUser.filter((data) => data.doc.data().is_subscribed)}
              titleButton="Export User Subscibe"
              fileName="List User Subscibe"
            />
          </Button>
        </>
      )}
      <TableContainer component={Paper} className={classes.container}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              <TableCell>email</TableCell>
              <TableCell align="center">nomor telfon</TableCell>
              <TableCell align="center">Nama</TableCell>
              <TableCell align="center">Subscribe</TableCell>
              <TableCell align="center">End Date</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {dataUser &&
              dataUser
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => {
                  const { email, phoneNumber, firstName } = row.doc.data();
                  const toDate = row.doc.data().end_date
                    ? row.doc.data().end_date.toDate()
                    : false;

                  return (
                    <TableRow key={Math.random()}>
                      <TableCell component="th" scope="row">
                        {email}
                      </TableCell>
                      <TableCell align="center">{phoneNumber}</TableCell>
                      <TableCell align="center">{firstName}</TableCell>
                      <TableCell align="center">
                        {row.doc.data().is_subscribed ? "Yes" : "No"}
                      </TableCell>
                      <TableCell align="center">
                        {DateToFormattedString(toDate)}
                      </TableCell>
                    </TableRow>
                  );
                })}
          </TableBody>
        </Table>
      </TableContainer>
      {dataUser && (
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={dataUser.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      )}
    </Paper>
  );
}
