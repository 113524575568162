import { encode } from "base-64";
const Xendit = require("xendit-node");

const key_testing =
    "xnd_development_HWcWLqDSdSs46onUwVyjQs6CcDTShU1EmYF16XRZLgZPwImUdFK6OVKzfBPc4i",
  key_real =
    "xnd_production_6WMWIlyfrTmMN5Nyl5jaf1Yb1gZOFw0NnU2MFFFMJFp9UsmU0QXUTF4VM5uJp94l";

export const listAvailableVA = () => {
  return new Promise((resolve, reject) => {
    fetch("https://api.xendit.co/available_virtual_account_banks", {
      headers: {
        Authorization: "Basic " + encode(key_real + ":" + "")
      }
    })
      .then((response) => response.json())
      .then((data) => resolve(data))
      .catch((err) => {
        reject({
          message: "failed"
        });
      });
  });
};
export const createInvoices = async (body) => {
  return new Promise((resolve, reject) => {
    try {
      const newBody = {
        external_id: `invoice-${new Date().getTime()}`,
        currency: "IDR",
        fixed_va: false,
        locale: "id",
        should_send_email: true
      };
      const data = {
        ...newBody,
        ...body
      };

      fetch(
        "https://us-central1-sekilasaja-999fd.cloudfunctions.net/widgets/create-invoices",
        {
          method: "POST",
          body: JSON.stringify(data),

          headers: {
            Authorization: "Basic " + encode(key_real + ":" + "")
          }
        }
      )
        .then((response) => response.json())
        .then((data) => resolve(data))
        .catch((err) => {
          reject(err);
        });
    } catch (err) {}
  });
};
export const getInvoices = (id) => {
  return new Promise((resolve, reject) => {
    fetch(
      `https://us-central1-sekilasaja-999fd.cloudfunctions.net/widgets/get-invoices/${id}`,
      {
        headers: {
          Authorization: "Basic " + encode(key_real + ":" + "")
        }
      }
    )
      .then((response) => response.json())
      .then((data) => resolve(data))
      .catch((err) => {
        reject(err);
      });
  });
};
