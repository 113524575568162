const FourOFourStyle = {
  position: {
    textAlign: "center",
    maxWidth: 900,
    marginBottom: "85px",
  },
  title: {
    fontSize: "150px",
  },
  description: {
    fontWeight: "normal",
  },
};

export default FourOFourStyle;
