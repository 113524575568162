import React, { useEffect, useState } from "react";

// material-ui
import { Button, CssBaseline, Grid } from "@material-ui/core";

// project imports
import EarningCard from "./EarningCard"; // done
import TotalIncomeDarkCard from "./TotalIncomeDarkCard"; // done
import TotalIncomeLightCard from "./TotalIncomeLightCard"; // done
import TotalGrowthBarChart from "./TotalGrowthBarChart"; // done
import { gridSpacing } from "../../store/constant"; // done
import SubscriptionCard from "./SubscriptionCard"; //done
import TableUser from "./tableUser"; // done
import { DateToFormattedString, solveDate } from "../../utils/dateFormatted";
import TotalPaymentBarChart from "./chart-data/TotalPaymentBarChart";
import { ContextReact } from "../../../context";
import { db_set_user } from "../../../context/type";
import fire from "../../../firebase/fire";
import { store } from "../../store";
import { useSelector, Provider } from "react-redux";
import { ThemeProvider } from "@material-ui/core/styles";

// defaultTheme
import themes from "../../themes";
import MainLayout from "../../MainLayout";
import TablePayment from "./table/tablePayment";
import PaymentBCA from "./paymentBCA/PaymentBca";
import ChartPaymentPerDay from "./chart-data/ChartPaymentPerDay";
import { firebaseGetSubscribeByDate } from "../../../firebase/firebaseDashboard";
import NewChartPaymentPerDay from "./chart-data/NewChartPaymentPerDay";

// ==============================|| DEFAULT DASHBOARD ||============================== //

let firstAllPayment = false;
let firstAllDataUser = false;

const Dashboard = () => {
  const [isLoading, setLoading] = useState(true);
  const [totalUser, setTotalUser] = useState(0);
  const [subscribe, setSubscribe] = useState({
    isSUbscribe: 0,
    isNotSubscribe: 0,
    subscribeUnder7: 0
  });
  const [dataSubscribe, setDataSubscribe] = useState({
    dataIsSUbscribe: 0,
    dataIsNotSubscribe: 0,
    dataSubscribeUnder7: 0
  });

  // state sign up
  const [trackSignUpMonth, setTrackSignUpMonth] = useState({});
  const [trackSignUpDay, setTrackSignUpDay] = useState({});
  const [trackSignUpWeek, setTrackSignUpWeek] = useState({});

  // state payment

  const [trackPaymentMonth, setTrackPaymentMonth] = useState({});
  const [trackPaymentDay, setTrackPaymentDay] = useState({});
  const [trackPaymentWeek, setTrackPaymentWeek] = useState({});
  const [allPayment, setAllPayment] = useState([]);

  const [paymentByDate, setPaymentByDate] = useState(false);

  const context = React.useContext(ContextReact);
  const customization = useSelector((state) => state.customization);

  async function fetchData() {
    // You can await here
    let sub = 0;
    let nonSUb = 0;
    let subsUnder7 = 0;

    const dataSubsUnder7 = [];
    const totalUser = await fire.firestore().collection("users").get();

    // fetching all payment
    const totalPayments = await fire.firestore().collection("payments").get();
    if (!firstAllPayment) {
      const newAllPayment = await totalPayments.docs.map((collection) => {
        return collection.data();
      });
      firstAllPayment = newAllPayment;
      setAllPayment(newAllPayment);
    } else if (firstAllPayment) {
      setAllPayment(firstAllPayment);
    }

    const findPayment = (email) => {
      const find = totalPayments
        .docChanges()
        .find((p) => p.doc.data().email === email);
      return find;
    };

    setTotalUser(totalUser.docChanges().length);
    await totalUser.docChanges().map((usr) => {
      if (usr.doc.data().is_subscribed) {
        sub += 1;
      } else {
        nonSUb += 1;
      }
      const date = new Date().setDate(new Date().getDate() + 7);

      if (usr.doc.data().end_date) {
        if (
          usr.doc.data().end_date.toDate() < date &&
          usr.doc.data().is_subscribed
        ) {
          subsUnder7 += 1;
          const email = usr.doc.data().email;
          if (findPayment(email)) {
            dataSubsUnder7.push({
              ...usr,
              ...findPayment(email).doc.data()
            });
          }
        }
      }
      return usr;
    });
    if (!firstAllDataUser) {
      firstAllDataUser = totalUser.docChanges();
      context.dispatch({ type: db_set_user, data: totalUser.docChanges() });
    } else if (firstAllDataUser) {
      context.dispatch({ type: db_set_user, data: firstAllDataUser });
    }
    setDataSubscribe({
      ...dataSubscribe,
      dataSubscribeUnder7: dataSubsUnder7
    });
    setSubscribe({
      isSUbscribe: sub,
      isNotSubscribe: nonSUb,
      subscribeUnder7: subsUnder7
    });
  }

  var week = new Date();
  week.setDate(week.getDate() - 7);

  async function fetchDataTrackRecord(by, sp) {
    // You can await here
    const track = await fire
      .firestore()
      .collection("dashboard")
      .doc("track_record")
      .get();
    const newBy = by === "month" ? "y-m" : false;
    const data = Object.values(track.data()[sp]);

    const sortsData = data.sort((a, b) => a.date.toDate() - b.date.toDate());

    let lastWeek = 0;

    const groupsData = await sortsData.reduce((groups, dataToChart) => {
      const now = new Date(dataToChart.date.toDate());

      if (now > lastWeek) {
        now.setDate(now.getDate(dataToChart.date.toDate()) + 6);
        lastWeek = now;
      }

      const date = `${DateToFormattedString(new Date(lastWeek))}`;
      if (!groups[date]) {
        groups[date] = [];
      }
      groups[date].push(dataToChart);
      return groups;
    }, {});

    const groupArraysData = (by) =>
      Object.keys(groupsData).map((date) => {
        let result = "";
        if (by === "date") {
          result = date;
        } else if (by === "data") {
          result = groupsData[date].length;
        }
        return result;
      });

    const sumData = groupArraysData("data").reduce(function (a, b) {
      return a + b;
    }, 0);

    const newDataResults = {
      data: groupArraysData("data"),
      date: groupArraysData("date"),
      total: sumData
    };

    const groups = await data.reduce((groups, dataToChart) => {
      const date = solveDate(
        newBy,
        DateToFormattedString(dataToChart.date.toDate())
      );

      if (!groups[date]) {
        groups[date] = [];
      }
      groups[date].push(dataToChart);
      return groups;
    }, {});

    // Edit: to add it in the array format instead
    const groupArrays = (by) =>
      Object.keys(groups).map((date) => {
        let result = "";
        if (by === "date") {
          result = date;
        } else if (by === "data") {
          result = groups[date].length;
        }
        return result;
      });

    const sum = groupArrays("data").reduce(function (a, b) {
      return a + b;
    }, 0);

    if (by === "month") {
      const newData = {
        data: groupArrays("data"),
        date: groupArrays("date"),
        total: sum
      };
      if (sp === "payment") {
        await setTrackPaymentMonth(newData);
      } else {
        await setTrackSignUpMonth(newData);
      }
    } else {
      const newData = {
        data: groupArrays("data").slice(
          groupArrays("data").length - 7,
          groupArrays("data").length
        ),
        date: groupArrays("date").slice(
          groupArrays("date").length - 7,
          groupArrays("date").length
        ),
        total: sum
      };
      if (sp === "payment") {
        setTrackPaymentDay(newData);
      } else {
        setTrackSignUpDay(newData);
      }
    }
    if (sp === "payment") {
      setTrackPaymentWeek(newDataResults);
    } else {
      setTrackSignUpWeek(newDataResults);
    }
  }

  useEffect(async () => {
    setLoading(false);

    // fetch data user

    await fetchDataTrackRecord("day", "payment");

    fetchData();
  }, []);

  useEffect(() => {
    fetchDataTrackRecord("month", "payment");
  }, []);

  useEffect(() => {
    fetchDataTrackRecord("day", "sign_up");
  }, []);

  useEffect(() => {
    fetchDataTrackRecord("month", "sign_up");
  }, []);

  const open = true;
  return (
    <Grid container spacing={gridSpacing}>
      <Grid item xs={12}>
        <Grid container spacing={gridSpacing}>
          <Grid item lg={6} md={6} sm={6} xs={12}>
            <EarningCard totalUser={totalUser} isLoading={isLoading} />
          </Grid>
          {/* <Grid item lg={6} md={6} sm={6} xs={12}>
                  <TotalOrderLineChartCard isLoading={isLoading} />
              </Grid> */}
          <Grid item lg={6} md={6} sm={6} xs={12}>
            <SubscriptionCard
              subsUnder7={subscribe.subscribeUnder7}
              isLoading={isLoading}
              data={dataSubscribe.dataSubscribeUnder7}
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Grid container spacing={gridSpacing}>
          <Grid item lg={6} md={6} sm={6} xs={12}>
            <TotalIncomeDarkCard
              isSubscribe={subscribe.isSUbscribe}
              isLoading={isLoading}
            />
          </Grid>
          <Grid item lg={6} md={6} sm={6} xs={12}>
            <TotalIncomeLightCard
              isLoading={isLoading}
              isNotSubscribe={subscribe.isNotSubscribe}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={gridSpacing}>
          <Grid item xs={12} md={6}>
            <TotalGrowthBarChart
              data={{
                month: trackSignUpMonth,
                day: trackSignUpDay,
                week: trackSignUpWeek
              }}
              isLoading={isLoading}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TotalPaymentBarChart
              data={{
                month: trackPaymentMonth,
                day: trackPaymentDay,
                week: trackPaymentWeek
              }}
              isLoading={isLoading}
            />
          </Grid>
        </Grid>
      </Grid>

      {open && (
        <>
          {Array.isArray(context.data.dashboard.dataUser) &&
            context.data.dashboard.dataUser.length > 0 && (
              <Grid item xs={12}>
                <Grid container spacing={gridSpacing}>
                  <Grid item xs={12} md={12}>
                    <TableUser dataUser={context.data.dashboard.dataUser} />
                  </Grid>
                  <Grid item xs={12} md={0}></Grid>
                </Grid>
              </Grid>
            )}

          {/* all payment */}
          {allPayment.length > 0 && (
            <Grid item xs={12}>
              <Grid container spacing={gridSpacing}>
                <Grid item xs={12} md={12}>
                  {/* <TotalGrowthBarChart isLoading={isLoading} /> */}
                  <TablePayment dataPayment={allPayment} />
                </Grid>
                <Grid item xs={12} md={0}>
                  {/* <PopularCard isLoading={isLoading} /> */}
                </Grid>
              </Grid>
            </Grid>
          )}
        </>
      )}

      <Grid item xs={12}>
        <Grid container spacing={gridSpacing}>
          <Grid item xs={12} md={12}>
            {/* <TotalGrowthBarChart isLoading={isLoading} /> */}
            <NewChartPaymentPerDay
              data={{
                month: trackPaymentMonth,
                day: trackPaymentDay,
                week: trackPaymentWeek
              }}
              isLoading={isLoading}
            />
          </Grid>
          <Grid item xs={12} md={0}>
            {/* <PopularCard isLoading={isLoading} /> */}
          </Grid>
        </Grid>
      </Grid>

      {/* payment BCA */}
      <Grid item xs={12}>
        <Grid container spacing={gridSpacing}>
          <Grid item xs={12} md={12}>
            {/* <TotalGrowthBarChart isLoading={isLoading} /> */}
            <PaymentBCA dataPayment={allPayment} />
          </Grid>
          <Grid item xs={12} md={0}>
            {/* <PopularCard isLoading={isLoading} /> */}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Dashboard;
