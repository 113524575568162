import fire from "./fire";

const firestore = fire.firestore();

export async function getMentorById(id) {
  // References to mentor information in database
  var docRef = firestore.collection("mentoring").doc(id);
  var classRef = docRef.collection("classList");

  try {
    var mentor = {};
    var doc = await docRef.get();

    if (doc.exists) {
      mentor = { ...doc.data() };
    }

    return mentor;
  } catch (err) {
    var errorCode = err.code;
    var errorMessage = err.message;
    console.log("Error: " + errorCode + "\n\n" + errorMessage);
  }
}

export default getMentorById;
