import React from "react";

// Custom components
import Header from "../../components/NavBar/Header";
import HeaderLinks from "../../components/NavBar/HeaderLinks";
import HeaderLinksMobile from "../../components/NavBar/HeaderLinksMobile";
import Footer from "../../components/Footer";
import ForgotPasswordForm from "./ForgotPasswordForm";
import MultiUseMobile from "../../styles/MultiUseMobile";
import Typography from "../../components/Typography";

// Material-UI components
import { Container, Paper, makeStyles, Grid } from "@material-ui/core";
import { beigeColor } from "../../styles/Style";

// router
import { Link } from "react-router-dom";

// Images
const Whatsapp =
  "https://firebasestorage.googleapis.com/v0/b/sekilasaja-999fd.appspot.com/o/Website_Images%2FWeb_Picture_Components%2FWhatsapp.png?alt=media&token=88483bb9-b9d3-4aa8-9f14-9b7f91682861";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "30px",
  },
}));

export default function ForgotPassword({ history }) {
  // Styles
  const classes = useStyles();
  const multi = MultiUseMobile();

  return (
    <div style={{ backgroundColor: beigeColor }}>
      <div style={{ marginTop: "100px" }} />
      <Header
        history={history}
        rightLinks={<HeaderLinks history={history} />}
        rightLinksMobile={<HeaderLinksMobile history={history} />}
        fixed
        color="white"
      />
      <div className={multi.extraSpace} />
      <Container maxWidth="xs">
        <Paper className={classes.root}>
          <ForgotPasswordForm history={history} />
        </Paper>
        <Typography style={{ textAlign: "center" }}>
          Belum punya akun?{" "}
          <Link
            to="/signup"
            className={multi.link}
            style={{ cursor: "pointer", textDecoration: "none" }}
            onClick={() => {
              window.scrollTo(0, 0);
            }}
          >
            Daftar Sekarang!
          </Link>
        </Typography>
        <div className={multi.extraSpace} />
      </Container>

      {/*---------------------------------------------------------------*/}
      {/*---------------------- WHATSAPP FIXED NAV ---------------------*/}
      {/*---------------------------------------------------------------*/}
      <a href="https://wa.me/6281291176795" target="_blank">
        <div className={multi.whatsapp}>
          <Grid container spacing={3}>
            <Grid item xs={4}>
              <img
                style={{
                  width: "60px",
                }}
                src={Whatsapp}
              />
            </Grid>

            <Grid item xs={4}>
              <Typography type="bold">Butuh Bantuan?</Typography>
            </Grid>
          </Grid>
        </div>
      </a>
      <Footer />
    </div>
  );
}
