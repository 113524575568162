import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import { Button } from "@material-ui/core";
import { CheckBox } from "@material-ui/icons";
import FormCategory from "./FormCategory";
import { Remove } from "@material-ui/icons";
const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
      width: "100%"
    }
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120
  }
}));

const names = [
  "Oliver Hansen",
  "Van Henry",
  "April Tucker",
  "Ralph Hubbard",
  "Omar Alexander",
  "Carlos Abbott",
  "Miriam Wagner",
  "Bradley Wilkerson",
  "Virginia Andrews",
  "Kelly Snyder"
];

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP
      // width: 250
    }
  }
};

export default function FormBook({ detailBook, submitData }) {
  const classes = useStyles();

  const [personName, setPersonName] = React.useState([]);

  const [category, setCategory] = React.useState([]);

  const [descBook, setDeskBook] = useState([""]);

  let initialValue = {
    book_title: "",
    video_link: "",
    video_id: "",
    short_desk: "",
    author: "",
    book_cover: ""
  };

  const [value, setValue] = useState(detailBook || initialValue);

  const defaultVal = value;

  const handleChangeCategory = (event) => {
    setPersonName(event.target.value);
  };

  let { book_title, video_link, video_id, short_desk, author, book_cover } =
    value;

  const handleChange = (e) => {
    const { name, value } = e.target;
    const newValue = {
      ...defaultVal,
      [name]: value
    };

    setValue(newValue);
  };

  const handleChangeDeskBook = (e, id) => {
    let { value } = e.target;
    let newDesk = descBook;
    newDesk[id] = value;

    // setDeskBook()
  };

  return (
    <form className={classes.root} noValidate autoComplete="off">
      <TextField
        onChange={handleChange}
        value={book_title}
        name={"book_title"}
        size="small"
        id="title-book-basic"
        label="Judul Buku"
        variant="outlined"
      />
      <TextField
        onChange={handleChange}
        value={author}
        name={"author"}
        size="small"
        id="author-basic"
        label="Penulis"
        variant="outlined"
      />

      {/* category */}

      <FormCategory category={category} setCategory={setCategory} />

      <TextField
        onChange={handleChange}
        value={video_link}
        name={"video_link"}
        size="small"
        id="video-book-basic"
        label="URL Video"
        variant="outlined"
      />
      <TextField
        onChange={handleChange}
        value={book_cover}
        name={"book_cover"}
        size="small"
        id="cover-book-basic"
        label="URL Cover Buku"
        variant="outlined"
      />
      {descBook.map((data, index) => {
        return (
          <>
            <TextField
              onChange={(e) => handleChangeDeskBook(e, index)}
              key={index}
              size="small"
              id="descriptions-book-basic"
              label={`Deskripsi Buku ${index + 1}`}
              variant="outlined"
            />
            {index > 0 && (
              <>
                <div style={{}}>
                  <Button
                    style={{
                      float: "right"
                    }}
                    onClick={() => {
                      setDeskBook(descBook.filter((v, i) => i !== index));
                    }}
                    variant="contained"
                    color="primary"
                  >
                    <Remove />
                  </Button>
                </div>
              </>
            )}
          </>
        );
      })}
      <div>
        <Button
          onClick={() => {
            setDeskBook([...descBook, { title: "" }]);
          }}
          variant="contained"
          color="primary"
        >
          Tambah Deskripsi
        </Button>
      </div>
      <br />

      <TextField
        onChange={handleChange}
        value={short_desk}
        name={"short_desk"}
        size="small"
        id="shorts-book-basic"
        label="Deskripsi Short Buku"
        variant="outlined"
      />
      <TextField
        onChange={handleChange}
        value={video_id}
        name={"video_id"}
        size="small"
        id="id-video-book-basic"
        label="Id Video"
        variant="outlined"
      />
      <Button
        onClick={() => {
          if (submitData)
            submitData({ ...value, descriptions: descBook, category });
        }}
        variant="contained"
        color="primary"
      >
        {detailBook ? "Edit Buku" : "Tambah Buku"}
      </Button>
    </form>
  );
}
