import React, { useState, useEffect, useContext } from "react";

// Whatsapp Button
import MultiUseMobile from "../../styles/MultiUseMobile";

// Custom components
import Footer from "../../components/Footer";
import Parallax from "../../components/Parallax";
import Typography from "../../components/Typography";
import Header from "../../components/NavBar/Header";
import HeaderLinks from "../../components/NavBar/HeaderLinks";
import HeaderLinksMobile from "../../components/NavBar/HeaderLinksMobile";
import Button from "../../components/Button";
import Loading from "../Utilities/Loading";
import { beigeColor } from "../../styles/Style";
import ModalPricing from "../../components/Modal/ModalPricing";
import ByCategory from "./showing/ByCategory";
import TextReadingStyle from "../../styles/TextReadingStyle";

//Redux
import { useSelector, useDispatch } from "react-redux";
import {
  selectOwnedBookTitles,
  setOwnedBookTitles
} from "../../feature/ownedBookTitlesSlice";
import { selectUser } from "../../feature/userSlice";

// Firebase components
import fire from "../../firebase/fire";
import { AuthContext } from "../../components/Routing/Auth";

// Material-UI components
import { Grid, Container, IconButton, Paper, Toolbar } from "@material-ui/core";
import { ContextReact } from "../../context";
import { setBook } from "../../context/action";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import { ImportContacts } from "@material-ui/icons";
import CardChallenge from "./showing/Challenge";
import { firebaseNewGetProgress } from "../../firebase/firebaseChallenge";
import { isLOgin } from "../../utils";

// Images
const Whatsapp =
  "https://firebasestorage.googleapis.com/v0/b/sekilasaja-999fd.appspot.com/o/Website_Images%2FWeb_Picture_Components%2FWhatsapp.png?alt=media&token=88483bb9-b9d3-4aa8-9f14-9b7f91682861";
const LibraryBackground =
  "https://firebasestorage.googleapis.com/v0/b/sekilasaja-999fd.appspot.com/o/Website_Images%2FWeb_Picture_Components%2Flibrary.jpg?alt=media&token=694df049-4675-4ea9-97bc-7562b045e445";

export default function Library({ history }) {
  // Styles
  const classes = TextReadingStyle();
  const multi = MultiUseMobile();

  // Auth
  const db = fire.firestore();
  const { currentUser } = useContext(AuthContext);

  // Redux
  const dispatch = useDispatch();
  const userData = useSelector(selectUser);
  const ownedBookTitles = useSelector(selectOwnedBookTitles);

  // useState Hooks
  const [pending, setPending] = useState(true);
  const [products, SetProducts] = useState([]);
  const [modal, setModal] = useState(false);
  const [pricingModal, setPricingModal] = useState(false);

  // Context
  const context = React.useContext(ContextReact);
  const { data } = context;

  useEffect(() => {
    // Get books
    db.collection("books").onSnapshot((snapshot) => {
      context.dispatch(
        setBook(
          snapshot.docs.map((doc) => ({
            ...doc.data()
          }))
        )
      );
    });

    const toLowerCase = (str) => {
      if (str) {
        return str.toLowerCase();
      } else {
        return false;
      }
    };

    // const { email } = currentUser;
    // db.collection("users").onSnapshot(function (res) {
    //   res.forEach((data) => {
    //     const newData = data.data();
    //     if (toLowerCase(newData.email) === toLowerCase(email)) {
    //       dispatch(setOwnedBookTitles(newData["owned_books"]));
    //     }
    //   });
    // });

    // Get books info
    db.collection("books").onSnapshot((snapshot) => {
      SetProducts(
        snapshot.docs.map((doc) => ({
          ...doc.data()
        }))
      );
    });
  }, []);

  let isOpen = false;

  useEffect(() => {
    window.addEventListener("scroll", (event) => {
      const element = document.getElementById("root").clientHeight;
      const persen = element * (50 / 100);
      const bulatkan = Math.round(persen);
      const scrollY = Math.round(window.scrollY);
      if (scrollY > bulatkan && !isOpen) {
        isOpen = true;
        setModal(true);
      }
    });
  }, []);

  const [lastViewVideo, setLastViewVideo] = React.useState({});
  const [lastReadKilas, setLastReadKilas] = React.useState({});

  useEffect(() => {
    if (currentUser !== null) {
      const { email } = currentUser;
      db.collection("lastViewVideo")
        .doc(email)
        .get()
        .then((res) => {
          if (res.data()) {
            setLastViewVideo(res.data());
          }
        });
    }
  }, []);

  useEffect(() => {
    if (currentUser !== null) {
      const { email } = currentUser;
      db.collection("lastReadBook")
        .doc(email)
        .get()
        .then((res) => {
          if (res.data()) {
            setLastReadKilas(res.data().book);
          }
        })
        .catch((err) => {
          // console.log("err", err);
        });
    }
  }, []);

  const [newChallenge, setNewChallenge] = React.useState([]);
  let challenge = [];
  useEffect(async () => {
    fire
      .firestore()
      .collection("newChallenge")
      .onSnapshot(async (res) => {
        await res.docChanges().map(async (data, index) => {
          const nc = await data.doc.ref
            .collection("user")
            .doc(isLOgin().email)
            .get()
            .then(async (pg) => {
              const subNc = {
                ...data.doc.data(),
                progress: pg.data(),
                challenge: data.doc.id
              };
              return subNc;
            });
          challenge.push(nc);
          if (index + 1 === res.docChanges().length) {
            setNewChallenge(challenge);
          }
          setPending(false);
          return challenge;
        });
      });
  }, []);

  const convertToTime = (time) => {
    const revisiNumber = (number) => {
      if (typeof number === "number") {
        if (number < 10) {
          return "0" + number;
        } else {
          return number;
        }
      } else {
        return number;
      }
    };

    const rounded = Math.ceil(time);
    const second = Math.round(rounded % 60);

    const minutes = rounded > 60 ? Math.round(rounded / 60) : "00";
    const hours = minutes > 60 ? Math.round(minutes / 30) : "00";
    return `${revisiNumber(hours)}:${revisiNumber(minutes)}:${revisiNumber(
      second
    )}`;
  };

  if (pending) {
    return (
      <>
        <Loading />
      </>
    );
  }

  const filterBook = (category) => {
    const isArray = Array.isArray(data.book);
    if (isArray) {
      return data.book.filter((data) => data.category.includes(category));
    } else {
      return false;
    }
  };

  const freeBooks = () => {
    const isArray = Array.isArray(data.book);
    const freeBooksArr = ["GRIT"];
    if (isArray) {
      return (
        Array.isArray(freeBooksArr) &&
        freeBooksArr
          .map((x) => data.book.filter((data) => data.book_title.includes(x)))
          .map((x) => x[0])
      );
    } else {
      return false;
    }
  };

  const dataByCategory = [
    { title: "New Release!", array: filterBook("New Release!") },
    // {
    //   title: "Coming Soon!",
    //   array: filterBook("Coming Soon!").sort(
    //     (a, b) => a.release_date.seconds - b.release_date.seconds
    //   )
    // },
    { title: "Pengembangan Diri", array: filterBook("Pengembangan Diri") },
    { title: "Biografi", array: filterBook("Biografi") },
    { title: "Bisnis & Investasi", array: filterBook("Bisnis & Investasi") },
    {
      title: "Kesehatan & Olahraga",
      array: filterBook("Kesehatan & Olahraga")
    },
    {
      title: "Non-fiksi",
      array: filterBook("Non-fiksi")
    }
  ];

  // console.log(dataByCategory.map((x) => x.array.length));

  return (
    <div style={{ backgroundColor: beigeColor }}>
      <ModalPricing open={pricingModal} setOpen={setPricingModal} />
      <div style={{ marginTop: "70px" }} />
      <Header
        history={history}
        rightLinks={<HeaderLinks history={history} />}
        rightLinksMobile={<HeaderLinksMobile history={history} />}
        fixed
        color="white"
      />
      {!!userData.user.is_subscribed ? (
        <Parallax small filter image={LibraryBackground}>
          <Grid container>
            <Grid item md={6} xs={12}>
              <Typography
                style={{ marginBottom: 0 }}
                color="beigeColor"
                size="heading"
              >
                MY LIBRARY
              </Typography>
            </Grid>
          </Grid>
        </Parallax>
      ) : (
        <Parallax small filter image={LibraryBackground}>
          <Typography color="beigeColor" size="heading">
            MY LIBRARY
          </Typography>
          <Typography color="beigeColor">
            Dengan hanya Rp. <strong>1.535/hari!</strong>{" "}
            <div>
              Kamu bisa memiliki <strong>akses untuk semua buku!</strong>
            </div>
          </Typography>
          <Button
            onClick={() => {
              history.push("/pricing");
              window.scrollTo(0, 0);
            }}
            round
          >
            Berlanggan sekarang!
          </Button>
        </Parallax>
      )}
      <br />
      <Container>
        <Grid container spacing={2}>
          {lastViewVideo.book && lastViewVideo.cover_book && (
            <Grid item md={6} xs={12}>
              <Paper style={{ padding: 10 }} round elevation={3}>
                <Toolbar style={{ justifyContent: "center" }} variant="dense">
                  <IconButton
                    onClick={() => {
                      history.push(`/kilas/${lastViewVideo.book}`);
                    }}
                    size="sm"
                    style={{
                      backgroundColor: "#FFE05D",
                      marginRight: "5px"
                    }}
                    aria-label="menu"
                  >
                    <PlayArrowIcon fontSize="small" />
                  </IconButton>
                  &nbsp; &nbsp;
                  <Typography type="bold" className={classes.lineClamp}>
                    Lanjut Menonton Video {lastViewVideo.book}
                  </Typography>
                  &nbsp;
                  <Typography>-</Typography>
                  &nbsp;
                  <Typography>{convertToTime(lastViewVideo.time)}</Typography>
                  &nbsp; &nbsp;
                  <img
                    style={{ maxWidth: "50px", marginLeft: "5px" }}
                    src={lastViewVideo.cover_book}
                  />
                </Toolbar>
              </Paper>
            </Grid>
          )}

          {lastReadKilas.book && (
            <Grid item md={6} xs={12}>
              <Paper style={{ padding: 10 }} round elevation={3}>
                <Toolbar style={{ justifyContent: "center" }} variant="dense">
                  <IconButton
                    onClick={() => {
                      history.push(`/kilas/${lastReadKilas.book}`);
                    }}
                    size="sm"
                    style={{
                      backgroundColor: "#FFE05D",
                      marginRight: "5px"
                    }}
                    aria-label="menu"
                  >
                    <ImportContacts />
                  </IconButton>
                  &nbsp; &nbsp;
                  <Typography className={classes.lineClamp} type="bold">
                    Lanjut Baca {lastReadKilas.book}
                  </Typography>
                  &nbsp;
                  <Typography>-</Typography>
                  &nbsp;
                  <Typography>Kilas#{lastReadKilas.kilas}</Typography>
                  &nbsp; &nbsp;
                  <img
                    style={{ maxWidth: "50px", marginLeft: "5px" }}
                    src={lastReadKilas.book_cover}
                  />
                </Toolbar>
              </Paper>
            </Grid>
          )}
        </Grid>
      </Container>
      <br />
      {/* <CardChallenge
        isHidden={true}
        history={history}
        tittle="Challenge Untuk Kamu!"
        array={newChallenge}
      />

      <br /> */}
      {userData.user && userData.user.is_subscribed ? (
        <></>
      ) : (
        <ByCategory
          isHidden={true}
          history={history}
          tittle="Kilas GRATIS Milikmu!"
          array={freeBooks()}
        />
      )}
      {dataByCategory.map((data) => {
        return (
          <>
            {data.array.length === 0 ? (
              <>
                <ByCategory
                  history={history}
                  tittle={data.title}
                  array={data.array}
                />
                <Container>
                  <Typography>Kilas terbaru akan segera datang!</Typography>
                </Container>
              </>
            ) : (
              <ByCategory
                history={history}
                tittle={data.title}
                array={data.array}
              />
            )}
          </>
        );
      })}
      {/*---------------------------------------------------------------*/}
      {/*---------------------- WHATSAPP FIXED NAV ---------------------*/}
      {/*---------------------------------------------------------------*/}
      <a href="https://wa.me/6281291176795" target="_blank">
        <div className={multi.whatsapp}>
          <Grid container spacing={3}>
            <Grid item xs={4}>
              <img
                style={{
                  width: "60px"
                }}
                src={Whatsapp}
              />
            </Grid>

            <Grid item xs={4}>
              <Typography type="bold">Butuh Bantuan?</Typography>
            </Grid>
          </Grid>
        </div>
      </a>
      <Footer />
    </div>
  );
}
