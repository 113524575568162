import { createSlice } from "@reduxjs/toolkit";

export const entoringSlice = createSlice({
  name: "redux mentoring ",
  initialState: {
    dataMentoringRedux: [],
    mentorRedux: {
      isReady: false,
      data: {}
    }
  },
  reducers: {
    setDataMentoringRedux: (state, action) => {
      state.dataMentoringRedux = action.payload;
    },
    setMentorRedux: (state, action) => {
      state.mentorRedux = {
        isReady: true,
        data: action.payload
      };
    }
  }
});

export const { setDataMentoringRedux, setMentorRedux } = entoringSlice.actions;
export const selectMentor = (state) => state.mentoringReducer;
const mentoringReducer = entoringSlice.reducer;
export default mentoringReducer;
