// Firebase
import fire from "./fire";
const firestore = fire.firestore();

export async function getAllMentors() {
  // References to mentor information in database
  var docRef = firestore.collection("mentoring");

  try {
    var doc = await docRef.get();

    if (doc.size > 0) {
      var returnDoc = doc.docs.map((doc) => ({ ...doc.data(), id: doc.id }));

      return returnDoc;
    }
  } catch (err) {
    var errorCode = err.code;
    var errorMessage = err.message;
    console.log("Error: " + errorCode + "\n\n" + errorMessage);
  }
}

export default getAllMentors;
