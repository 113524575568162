import React, { useState } from "react";

// Custom components
import Typography from "../../components/Typography";
import { primaryColor } from "../../styles/Style";

// Material-UI components
import { Container, Collapse, ListItem, List } from "@material-ui/core";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";

export default function Accordion({ title, content }) {
  // useState hooks
  const [open, setOpen] = useState(true);

  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <div
      style={{
        borderRadius: 20,
        border: "1px solid #D3D3D3",
        backgroundColor: "transparent",
        justifyContent: "space-between",
        marginBottom: 20,
      }}
    >
      <ListItem
        style={{
          backgroundColor: "transparent",
          justifyContent: "space-between",
        }}
        button
        onClick={handleClick}
      >
        <Typography type="bold">{title}</Typography>
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItem>

      <Collapse in={open} timeout="auto" unmountOnExit>
        <List
          style={{
            borderTop: "1px solid #D3D3D3",
            paddingTop: 10,
            paddingBottom: 10,
          }}
          component="div"
          disablePadding
        >
          <Container>{content}</Container>
        </List>
      </Collapse>
    </div>
  );
}
