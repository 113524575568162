import React, { useState, useEffect } from "react";
import { Redirect } from "react-router";

// Custom components
import NavBarSecond from "../../components/NavBar/NavBarSecond";
import Drawer from "../../components/Drawer";
import TextReadingStyle from "../../styles/TextReadingStyle";
import NavbarStyle from "../../styles/NavbarStyle";
import TableOfContent from "./TableOfContent";
import Typography from "../../components/Typography";
import ReactAudioPlayer from "react-audio-player";
import Loading from "../Utilities/Loading";

// Material UI components
import DvrIcon from "@material-ui/icons/Dvr";
import RepeatIcon from "@material-ui/icons/Repeat";
import {
  Container,
  AppBar,
  Grid,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormLabel,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";

// Custom components
import Button from "../../components/Button";
import { beigeColor } from "../../styles/Style";
import Accordion from "./Accordion";

//Redux
import { useSelector } from "react-redux";
import { selectUser } from "../../feature/userSlice";

//firebase components
import fire from "../../firebase/fire";
import * as firebaseGetBookAudioURL from "../../firebase/firebaseGetBookAudioURL";
import * as firebaseGetBookCoverImageURL from "../../firebase/firebaseGetBookCoverImageURL";
import { Skeleton } from "@material-ui/lab";
import { MovieCreation } from "@material-ui/icons";
import VideocamIcon from "@material-ui/icons/Videocam";

// jquery
import { isLOgin, setHistoryReadKilas } from "../../utils";
import ModalSekilasAja from "../../components/Modal/ModalSekilasAja";
import Video from "./Video";
import {
  firebaseGetRateKilas,
  firebaseSetRateKilas,
  firebaseSetRateVideo,
} from "./../../firebase/firebaseRate";

const initial = {
  description: "",
  watch_time: "",
  price: 0,
  video_link: "",
  category: ["", ""],
  descriptions: [""],
  short_desc: "",
  author: "",
  book_cover: "",
  book_title: "",
  audio_link: "",
  read_time: "",
  video_id: "",
};

export default function KilasPage({ match, history }) {
  // Auth
  const db = fire.firestore();

  // Styles
  const classes = TextReadingStyle();
  const nav = NavbarStyle();

  // useState hooks
  const [chapterContent, setChapterContent] = useState([]);
  const [chosenChapter, setChosenChapter] = useState(0);
  const [audioLink, setAudioLink] = useState(null);
  const [pending, setPending] = useState(true);
  const [isBookOwned, setIsBookOwned] = useState(false);
  const [detailBook, setDetailBook] = React.useState(initial);
  const [videoAlert, setVideoAlert] = useState(false);
  const [coverLink, setCoverLink] = useState("");
  const [innerWidth, setInnerWidth] = React.useState(0);

  // Video Variables
  const dataVideo = {
    [match.params.book_title]: detailBook.video_id,
  };
  const getIdVideo = dataVideo[match.params.book_title];
  let playIn = sessionStorage.getItem(dataVideo[match.params.book_title]);

  const [resume, setResumeVideo] = React.useState(
    playIn ? parseFloat(playIn) : 0
  );

  // Redux
  const userData = useSelector(selectUser);

  const getEmail = userData.user || "";
  const email = getEmail ? getEmail.email.toLowerCase() : "";
  const { book_title } = match.params;

  // rate hook
  const [modalRateVideo, setModalRateVideo] = React.useState(false);
  const [modalRateKilas, setModalRateKilas] = React.useState(false);
  const [rate, setRate] = React.useState({ read: 0, listen: 0, video: 0 });
  const [allHistoryRate, setAllHistoryRate] = React.useState({});

  /*----------------------------------------------------- */
  /*---------------------  FUNCTIONS -------------------- */
  /*----------------------------------------------------- */
  const handleNext = () => {
    if (chosenChapter === chapterContent.length) {
      setChosenChapter(1);
      setHistoryReadKilas(userData.user.email, {
        book: match.params.book_title,
        kilas: 1,
        book_cover: coverLink || "",
      });
      window.scrollTo(0, 0);
    } else if (chosenChapter == chapterContent.length - 1) {
      setChosenChapter(chosenChapter + 1);
      setHistoryReadKilas(userData.user.email, {
        book: match.params.book_title,
        kilas: 0,
        book_cover: coverLink || "",
      });
      window.scrollTo(0, 0);
    } else {
      setChosenChapter(chosenChapter + 1);
      setHistoryReadKilas(userData.user.email, {
        book: match.params.book_title,
        kilas: chosenChapter + 1,
        book_cover: coverLink || "",
      });
      window.scrollTo(0, 0);
    }
  };

  const handlePrev = () => {
    if (chosenChapter === 1) {
      setChosenChapter(chapterContent.length);
      setHistoryReadKilas(userData.user.email, {
        book: match.params.book_title,
        kilas: chapterContent.length,
        book_cover: coverLink || "",
      });
      window.scrollTo(0, 0);
    } else {
      setChosenChapter(chosenChapter - 1);
      setHistoryReadKilas(userData.user.email, {
        book: match.params.book_title,
        kilas: chosenChapter - 1,
        book_cover: coverLink || "",
      });
      window.scrollTo(0, 0);
    }
  };

  var continueVideo = true;
  const videoSetting = (detail, cover) => {
    var _duration = 0,
      _playing = false,
      _volume = 1,
      _fullscreen = false;

    let videoId = detail ? detail.video_id : getIdVideo;

    var videoPlayer = new window.SV.Player({ videoId: videoId });

    const setSession = (time) => {
      sessionStorage.setItem(videoId, time);
    };

    videoPlayer.bind("ready", function (event) {
      _duration = event.data.duration;
    });

    // test
    videoPlayer.bind("progress", function (event) {
      if (continueVideo && detail.time) {
        videoPlayer.seek(detail.time);
        continueVideo = false;
      }
      db.collection("lastViewVideo")
        .doc(userData.user.email.toLowerCase())
        .set({
          time: event.data.time,
          book: match.params.book_title,
          cover_book: cover || "",
        });

      setSession(event.data.time);
      setResumeVideo(event.data.time);
    });

    videoPlayer.bind("pause", function (event) {
      _playing = false;
    });

    videoPlayer.bind("completed", function () {
      const rateInSession = sessionStorage.getItem("rate");
      db.collection("lastViewVideo")
        .doc(userData.user.email.toLowerCase())
        .delete();
      if (!rateInSession) {
        setModalRateVideo(true);
      }
      sessionStorage.removeItem(videoId);
    });

    videoPlayer.bind("play", async function (event) {
      _playing = true;
    });

    videoPlayer.bind("volume", function (event) {
      _volume = event.data;
    });
  };

  const getHistoryReadKilas = () => {
    fire
      .firestore()
      .collection("lastReadBook")
      .doc(userData.user.email.toLowerCase())
      .get()
      .then((res) => {
        if (res.data()) {
          let { kilas, book } = res.data().book;
          if (book === match.params.book_title) {
            setChosenChapter(kilas);
          }
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const sum = (arr) => {
    return arr.reduce(function (a, b) {
      return a + b;
    }, 0);
  };

  // function rate book kilas & video
  const listRate = ["1", "2", "3", "4", "5"];
  const rateBook = (type) => {
    // rate reading & listening
    if (type) {
      const { listen, read } = rate;

      if (listen && read) {
        const arrAverage = (param) => [
          ...Object.values(allHistoryRate[param].byUser),
          rate[param],
        ];
        firebaseSetRateKilas(
          // data to set rate
          {
            listen: {
              byUser: {
                [email]: listen,
              },
              average: allHistoryRate[listen]
                ? sum(arrAverage("listen")) /
                  (Object.keys(allHistoryRate[listen].byUser).length + 1)
                : listen,
              totalRate: allHistoryRate[listen]
                ? Object.keys(allHistoryRate[listen].byUser).length + 1
                : 1,
            },
            read: {
              byUser: {
                [email]: read,
              },
              average: allHistoryRate[read]
                ? sum(arrAverage("read")) /
                  (Object.keys(allHistoryRate[read].byUser).length + 1)
                : read,
              totalRate: allHistoryRate[read]
                ? Object.keys(allHistoryRate[read].byUser).length + 1
                : 1,
            },
          },
          // Books to be given a rating
          book_title,
          () => {
            setModalRateKilas(false);
            getHistoryRate();
          }
        );
      } else if (!listen && !read) {
        setModalRateKilas(false);
      }
    }
    // rate video
    else {
      if (rate.video) {
        const arrAverage = () => [
          ...Object.values(allHistoryRate[type || "video"].byUser),
          rate.video,
        ];
        firebaseSetRateVideo(
          {
            byUser: {
              [email]: rate.video,
            },
            average: allHistoryRate.video
              ? sum(arrAverage()) /
                (Object.keys(allHistoryRate["video"].byUser).length + 1)
              : rate.video,
            totalRate: allHistoryRate.video
              ? Object.keys(allHistoryRate["video"].byUser).length + 1
              : 1,
          },
          book_title,
          () => {
            sessionStorage.setItem("rate", rate.video);

            setModalRateVideo(false);
            getHistoryRate();
          }
        );
      } else {
        setModalRateVideo(false);
      }
    }
  };

  /*----------------------------------------------------- */
  /*--------------------  useEffects -------------------- */
  /*----------------------------------------------------- */

  useEffect(() => {
    db.collection("books")
      .doc(match.params.book_title)
      .collection("kilasan")
      .orderBy("kilas")
      .onSnapshot((snapshot) => {
        setChapterContent(
          snapshot.docs.map((doc) => ({
            id: doc.id,
            content: doc.data(),
          }))
        );
      });

    const fetchBookData = async () => {
      const getLink = firebaseGetBookCoverImageURL.getBookCoverImageURL(
        match.params.book_title
      );
      const covLink = await getLink;

      if (covLink !== undefined) setCoverLink(covLink);

      db.collection("books")
        .doc(match.params.book_title)
        .onSnapshot((res) => {
          setDetailBook(res.data());
          setTimeout(() => {
            db.collection("lastViewVideo")
              .doc(userData.user.email.toLowerCase())
              .get()
              .then((resResumeVideo) => {
                if (resResumeVideo.data()) {
                  const { time } = resResumeVideo.data();
                  videoSetting({ ...res.data(), time }, covLink);
                } else {
                  videoSetting(res.data(), covLink);
                  return false;
                }
              });
          }, 1000);
        });
    };

    if (userData.user.owned_books.includes(match.params.book_title))
      setIsBookOwned(true);

    const fetchData = async () => {
      const getlink = firebaseGetBookAudioURL.getBookAudioURL(
        match.params.book_title,
        chosenChapter
      );

      const link = await getlink;
      setAudioLink(link);
    };
    setPending(false);
    fetchData();
    fetchBookData();
  }, [, chosenChapter]);

  useEffect(() => {
    db.collection("books")
      .doc(match.params.book_title)
      .onSnapshot((res) => {
        if (res.data().video_link == "") {
          setChosenChapter(1);
          setVideoAlert(true);
        }
      });

    db.collection("lastViewVideo")
      .doc(userData.user.email.toLowerCase())
      .get()
      .then((res) => {
        if (res.data()) {
          setResumeVideo(res.data().time);
        }
      });

    getHistoryRate();
    getHistoryReadKilas();
  }, []);

  const getHistoryRate = () => {
    const dbRate = fire.firestore().collection("rate");

    // get history rate video
    dbRate
      .doc(book_title)
      .get()
      .then((res) => {
        if (res.data()) {
          const { video } = res.data();

          setAllHistoryRate(res.data());
          if (video) {
            let { byUser } = video;

            if (byUser[email]) {
              sessionStorage.setItem("rate", byUser[email]);
            } else {
              sessionStorage.removeItem("rate");
            }
          }
        } else {
          sessionStorage.removeItem("rate");
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
    // get history rate video

    firebaseGetRateKilas(email, book_title)
      .then((res) => {
        res.forEach((data) => {
          setAllHistoryRate({ ...allHistoryRate, [data.id]: data.data() });
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // Exceptions
  if (userData.user && userData.user.is_subscribed == false && !pending) {
    if (isBookOwned == false && !pending) return <Redirect to="/404page" />;
  }

  if (pending) {
    return (
      <>
        <Loading />
      </>
    );
  }

  setInterval(() => {
    const desktopMode = window.innerWidth;

    setInnerWidth(desktopMode);
  }, 1000);

  const isRate = (type) => {
    if (allHistoryRate[type]) {
      let nIsRate = allHistoryRate[type].byUser[isLOgin().email.toLowerCase()];
      return !nIsRate;
    } else {
      return false;
    }
  };

  return (
    <div style={{ backgroundColor: beigeColor }}>
      {/* Start Modal Rate Video */}
      <ModalSekilasAja
        statusModal={modalRateVideo}
        toggleModal={setModalRateVideo}
      >
        <Typography type="bold">
          Dari 1-5 (1 tidak suka - 5 sangat suka), seberapa suka kamu dengan
          VIDEO ini?
        </Typography>

        <RadioGroup
          row
          aria-label="position"
          name="position"
          style={{ justifyContent: "space-between" }}
        >
          {listRate.map((data, index) => {
            return (
              <React.Fragment key={index}>
                <FormControlLabel
                  onClick={() => setRate({ ...rate, video: parseInt(data) })}
                  value={data}
                  control={<Radio color="primary" />}
                  label={<b>{data}</b>}
                  labelPlacement="top"
                  color="green"
                  style={{ margin: 0 }}
                />
              </React.Fragment>
            );
          })}
        </RadioGroup>

        <Button fullWidth round onClick={() => rateBook()}>
          Submit
        </Button>
      </ModalSekilasAja>
      {/* End Modal Rate Video */}

      {/* Start Modal Rate Kilas */}
      <ModalSekilasAja
        statusModal={modalRateKilas}
        toggleModal={setModalRateKilas}
      >
        <Typography type="bold">
          Dari 1-5 (1 tidak suka - 5 sangat suka), seberapa suka kamu dengan
          BACAAN ini?
        </Typography>

        <RadioGroup
          row
          aria-label="position"
          name="position"
          // defaultValue={"1"}
          style={{ justifyContent: "space-between" }}
        >
          {listRate.map((data, index) => {
            return (
              <React.Fragment key={index}>
                <FormControlLabel
                  onClick={() => setRate({ ...rate, read: parseInt(data) })}
                  value={data}
                  control={<Radio color="primary" />}
                  label={<b>{data}</b>}
                  labelPlacement="top"
                  color="green"
                  style={{ margin: 0 }}
                />
              </React.Fragment>
            );
          })}
        </RadioGroup>

        <Typography type="bold">
          Dari 1-5 (1 tidak suka - 5 sangat suka), seberapa suka kamu dengan
          AUDIO kilas ini?
        </Typography>

        <RadioGroup
          row
          aria-label="position"
          name="position"
          // defaultValue={"5"}
          style={{ justifyContent: "space-between" }}
        >
          {listRate.map((data, index) => {
            return (
              <React.Fragment key={index}>
                <FormControlLabel
                  onClick={() => setRate({ ...rate, listen: parseInt(data) })}
                  value={data}
                  control={<Radio color="primary" />}
                  label={<b>{data}</b>}
                  labelPlacement="top"
                  color="green"
                  style={{ margin: 0 }}
                />
              </React.Fragment>
            );
          })}
        </RadioGroup>

        <Button onClick={() => rateBook("kilas")} fullWidth round>
          Submit
        </Button>
      </ModalSekilasAja>
      {/* End Modal Rate Kilas */}

      <div style={{ marginTop: "70px" }} />
      <div>
        {innerWidth > 960 ? (
          // desktop mode
          <div
            id="desktop-mode"
            className={`${classes.sectionDesktop} desktop-mode`}
          >
            <NavBarSecond
              children={
                <Typography size="subheading">
                  {match.params.book_title}
                </Typography>
              }
            />
            {chapterContent.length !== 0 && (
              <Container>
                <Grid container spacing={5}>
                  <Grid item xs={3}>
                    <div className={classes.extraSpace} />
                    {!detailBook.video_link ? (
                      <></>
                    ) : (
                      <Accordion
                        title={"Nonton Video"}
                        src={detailBook.video_link || ""}
                        content={
                          <TableOfContent
                            coverLink={coverLink}
                            type="video"
                            chapterContent={match.params.book_title}
                            chosenChapter={chosenChapter}
                            setChosenChapter={setChosenChapter}
                          />
                        }
                      />
                    )}
                    <Accordion
                      title={"Baca Kilas"}
                      content={
                        <TableOfContent
                          coverLink={coverLink}
                          chapterContent={chapterContent}
                          chosenChapter={chosenChapter}
                          setChosenChapter={setChosenChapter}
                        />
                      }
                    />
                  </Grid>

                  <Grid item xs={9}>
                    {!videoAlert && (
                      <>
                        <br />
                        <br />
                      </>
                    )}
                    {videoAlert && (
                      <>
                        <div className={classes.extraSpace} />
                        <Alert
                          severity={"warning"}
                          onClose={() => {
                            setVideoAlert(false);
                          }}
                        >
                          <b>Video untuk kilas ini belum tersedia!</b>
                        </Alert>
                      </>
                    )}
                    {chosenChapter === chapterContent.length ? (
                      <div>
                        <Typography
                          className={classes.uncopyable}
                          size="subheading"
                        >
                          {"Ringkasan Akhir"}
                        </Typography>
                        <div className={classes.chapterContent}>
                          {chapterContent[
                            chapterContent.length - 1
                          ].content.details.map((paragraph, index) => (
                            <Typography className={classes.paragraph}>
                              {paragraph}
                            </Typography>
                          ))}
                        </div>
                      </div>
                    ) : (
                      <div>
                        {chapterContent[chosenChapter - 1] ? (
                          <>
                            <Typography
                              className={classes.uncopyable}
                              size="subheading"
                            >
                              {chapterContent[chosenChapter - 1].content.title}
                            </Typography>
                            <div className={classes.chapterContent}>
                              {chapterContent[
                                chosenChapter - 1
                              ].content.details.map((paragraph, index) => (
                                <Typography
                                  className={classes.uncopyable}
                                  className={classes.paragraph}
                                >
                                  {paragraph}
                                </Typography>
                              ))}
                            </div>
                          </>
                        ) : (
                          <div>
                            <div className={classes.chapterContent}>
                              {!detailBook.video_link ? (
                                <></>
                              ) : (
                                <>
                                  <div className={classes.containerVideo}>
                                    <Video
                                      className={
                                        classes.iframe + " sproutvideo-player"
                                      }
                                      src={detailBook.video_link}
                                      match={match.params.book_title}
                                    />
                                  </div>
                                  <Typography size="subheading">
                                    Tentang
                                  </Typography>
                                  {detailBook.descriptions &&
                                    detailBook.descriptions.map(
                                      (data, index) => {
                                        return (
                                          <Typography
                                            className={classes.uncopyable}
                                            className={classes.paragraph}
                                          >
                                            {data}
                                          </Typography>
                                        );
                                      }
                                    )}
                                </>
                              )}
                            </div>
                          </div>
                        )}
                      </div>
                    )}
                  </Grid>
                </Grid>
              </Container>
            )}
          </div>
        ) : (
          // mobile mode
          <div id="mobile-mode" className={classes.sectionMobile}>
            <NavBarSecond
              children={
                <>
                  <Drawer
                    compVideo={{
                      drawerLogo: <MovieCreation className={nav.hugeIcon} />,
                      drawerTitle: "Nonton Video",
                      logo: <VideocamIcon className={nav.iconColor} />,
                      children: (
                        <TableOfContent
                          coverLink={coverLink}
                          type="video"
                          chapterContent={match.params.book_title}
                          chosenChapter={chosenChapter}
                          setChosenChapter={setChosenChapter}
                        />
                      ),
                    }}
                    isHidden={!detailBook.video_link ? true : false}
                    direction={"left"}
                    drawerLogo={<DvrIcon className={nav.hugeIcon} />}
                    drawerTitle={"Daftar Kilas"}
                    logo={<DvrIcon className={nav.iconColor} />}
                    children={
                      <>
                        <TableOfContent
                          coverLink={coverLink}
                          chapterContent={chapterContent}
                          chosenChapter={chosenChapter}
                          setChosenChapter={setChosenChapter}
                        />
                      </>
                    }
                  />
                </>
              }
              buttons={
                <div>
                  {chapterContent[chosenChapter - 1] ? (
                    <>
                      {chosenChapter === 1 ||
                      chosenChapter === chapterContent.length ? (
                        <></>
                      ) : (
                        <Button
                          round
                          style={{ fontSize: "17px", width: 0 }}
                          color="secondary"
                          onClick={handlePrev}
                        >
                          <ArrowBackIcon />
                        </Button>
                      )}

                      {chosenChapter === chapterContent.length ? (
                        <>
                          <Button color="gray" round onClick={handleNext}>
                            <RepeatIcon />
                          </Button>
                          {/* button open modal rate */}
                          {/* available when the user has not given a rating */}
                          {isRate("read") && (
                            <Button
                              round
                              color="secondary"
                              onClick={() => setModalRateKilas(true)}
                            >
                              Nilai
                            </Button>
                          )}
                        </>
                      ) : (
                        <Button
                          color="secondary"
                          style={{ fontSize: "17px", width: 0 }}
                          round
                          onClick={handleNext}
                        >
                          <ArrowForwardIcon />
                        </Button>
                      )}
                    </>
                  ) : (
                    <></>
                  )}
                </div>
              }
            />

            {chapterContent.length !== 0 && (
              <div className={classes.page}>
                <div className={classes.container}>
                  {videoAlert && (
                    <Alert
                      severity={"warning"}
                      onClose={() => {
                        setVideoAlert(false);
                      }}
                    >
                      <b>Video untuk kilas ini belum tersedia!</b>
                    </Alert>
                  )}
                  {chosenChapter === 1 ||
                    (chosenChapter === 0 && (
                      <div className={classes.book_title}>
                        <Typography
                          className={classes.uncopyable}
                          size="heading"
                        >
                          {match.params.book_title}
                        </Typography>
                      </div>
                    ))}
                  {chosenChapter === chapterContent.length ? (
                    <div>
                      <div className={classes.title}>
                        <Typography
                          className={classes.uncopyable}
                          size="subheading"
                        >
                          {"Ringkasan Akhir"}
                        </Typography>
                      </div>
                      <div className={classes.chapterContent}>
                        {chapterContent[
                          chapterContent.length - 1
                        ].content.details.map((paragraph, index) => (
                          <Typography className={classes.paragraph}>
                            {paragraph}
                          </Typography>
                        ))}
                      </div>
                    </div>
                  ) : (
                    <div>
                      {chapterContent[chosenChapter - 1] ? (
                        <>
                          <div className={classes.title}>
                            <Typography
                              className={classes.uncopyable}
                              type="italic"
                              size="bold"
                            >
                              Kilas #{chapterContent[chosenChapter - 1].id}
                            </Typography>
                          </div>
                          <div className={classes.title}>
                            <Typography
                              className={classes.uncopyable}
                              size="subheading"
                            >
                              {chapterContent[chosenChapter - 1].content.title}
                            </Typography>
                          </div>
                          <div className={classes.chapterContent}>
                            {chapterContent[
                              chosenChapter - 1
                            ].content.details.map((paragraph, index) => (
                              <Typography
                                className={classes.uncopyable}
                                className={classes.paragraph}
                              >
                                {paragraph}
                              </Typography>
                            ))}
                          </div>
                        </>
                      ) : (
                        <>
                          <div className={classes.chapterContent}>
                            {!detailBook.video_link ? (
                              <Skeleton
                                variant="rect"
                                width={"100%"}
                                height={500}
                              />
                            ) : (
                              <div className={classes.containerVideo}>
                                <Video
                                  className={
                                    classes.iframe + " sproutvideo-player"
                                  }
                                  src={detailBook.video_link}
                                />
                              </div>
                            )}

                            <Typography size="subheading">Tentang</Typography>
                            {detailBook.descriptions &&
                              detailBook.descriptions.map((data, index) => {
                                return (
                                  <Typography
                                    className={classes.uncopyable}
                                    className={classes.paragraph}
                                  >
                                    {data}
                                  </Typography>
                                );
                              })}
                          </div>
                        </>
                      )}
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        )}

        <div style={{ marginTop: "100px" }}>
          {chapterContent[chosenChapter - 1] ? (
            <AppBar color="white" position="fixed" className={classes.audioBar}>
              <Container>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    padding: 10,
                  }}
                >
                  <ReactAudioPlayer
                    className={classes.audioControl}
                    controlsList="nodownload"
                    src={audioLink}
                    autoPlay={true}
                    controls
                    onEnded={handleNext}
                  />

                  <div className={classes.sectionDesktop}>
                    {chosenChapter === 1 ||
                    chosenChapter === chapterContent.length ? (
                      <></>
                    ) : (
                      <Button round onClick={handlePrev}>
                        <ArrowBackIcon /> Kembali
                      </Button>
                    )}

                    {chosenChapter === chapterContent.length ? (
                      <>
                        <Button color="gray" round onClick={handleNext}>
                          <RepeatIcon />
                        </Button>
                        {isRate("read") && (
                          <Button round onClick={() => setModalRateKilas(true)}>
                            Nilai
                          </Button>
                        )}
                      </>
                    ) : (
                      <Button round onClick={handleNext}>
                        <ArrowForwardIcon /> Lanjut
                      </Button>
                    )}
                  </div>
                </div>
              </Container>
            </AppBar>
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  );
}
