import React from "react";
import { Link } from "react-router-dom";

// Custom components
import ListCarousel from "../ListCarousel";
import ComingSoonListCarousel from "../ComingSoonListCarousel";
import Typography from "../../../components/Typography";
import CombiningTextWithUnderline from "../../../components/CombiningTextWithUnderline";
import { secondaryColor } from "../../../styles/Style";

// Material-UI components
import { Container, Grid, CircularProgress } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";

function ByCategory({ array, tittle, history, isHidden }) {
  return (
    <Container>
      <Grid container alignItems="center" justifyContent="space-between">
        <Grid item>
          <Typography size="subheading">
            <CombiningTextWithUnderline
              text={{ underline: tittle || "Title Category" }}
              position="back"
            />
          </Typography>
        </Grid>

        <Grid item>
          <div hidden={isHidden}>
            <Link
              to={`/show-by-category/${tittle}`}
              onClick={() => {
                window.scrollTo(0, 0);
              }}
              style={{ color: secondaryColor }}
            >
              Lihat semua >
            </Link>
          </div>
        </Grid>
      </Grid>
      {array ? (
        <>
          {tittle == "Coming Soon!" ? (
            <ComingSoonListCarousel content={array} />
          ) : (
            <ListCarousel content={array} />
          )}
        </>
      ) : (
        <Skeleton variant="rect" width={220} height={500} />
      )}
    </Container>
  );
}

export default ByCategory;
