import React from "react";

import { secondaryColor, primaryColor } from "../../styles/Style";

export const CustomArrow = ({ className, onClick, ...rest }) => {
  const {
    onMove,
    index,
    active,
    carouselState: { currentSlide, deviceType },
  } = rest;

  return (
    <button
      className={
        className ||
        "react-multiple-carousel__arrow react-multiple-carousel__arrow--right "
      }
      style={{
        backgroundColor: primaryColor,
        color: secondaryColor,
        opacity: 0.75,
      }}
      onClick={() => onClick()}
    ></button>
  );
};
