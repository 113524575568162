export function DateToFormattedString(d) {
    let result = '';
    if (d) {
        const yyyy = d.getFullYear();
        const mm = d.getMonth() + 1;
        const dd = d.getDate();

        result = `${yyyy} - ${mm} - ${dd}`;
        // result = yyyy + '-' + (mm[1] ? mm : '0' + mm[0]) + '-' + (dd[1] ? dd : '0' + dd[0]);
    } else {
        result = 'data end date tidak ada';
    }
    return result;
}
export function solveDate(solve, stringDate) {
    const split = stringDate.replaceAll(' ', '').split('-');
    let result = '';
    if (solve === 'y-m') {
        result = `${split[0]} - ${split[1]}`;
    } else {
        result = stringDate.replaceAll(' ', '');
    }
    return result;
}
