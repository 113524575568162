import React from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";

// Material-UI components
import { IconButton, Backdrop, Paper, Dialog } from "@material-ui/core";
import { Close } from "@material-ui/icons";

// Custom components
import Typography from "../../components/Typography";
import Button from "../../components/Button";

// Springweb
import { useSpring, animated } from "@react-spring/web"; // web.cjs is required for IE 11 support
import fire from "./../../firebase/fire";
import { AuthContext } from "../Routing/Auth";

// Images
const Logo =
  "https://firebasestorage.googleapis.com/v0/b/sekilasaja-999fd.appspot.com/o/Website_Images%2FWeb_Picture_Components%2Fdark-logo.png?alt=media&token=cfd7dc4d-1687-473e-a272-4d7c66b97467";

const Fade = React.forwardRef(function Fade(props, ref) {
  const { in: open, children, onEnter, onExited, ...other } = props;
  const style = useSpring({
    from: { opacity: 0 },
    to: { opacity: open ? 1 : 0 },
    onStart: () => {
      if (open && onEnter) {
        onEnter();
      }
    },
    onRest: () => {
      if (!open && onExited) {
        onExited();
      }
    },
  });

  return (
    <animated.div ref={ref} style={style} {...other}>
      {children}
    </animated.div>
  );
});

Fade.propTypes = {
  children: PropTypes.element,
  in: PropTypes.bool.isRequired,
  onEnter: PropTypes.func,
  onExited: PropTypes.func,
};

export default function ModalBlog({ open, setOpen }) {
  // History
  const history = useHistory();

  // total vote state
  const [totalVote, setTotalVote] = React.useState(0);

  // user is voting state
  const [isVoting, setIsVoting] = React.useState({});

  // get detail user

  const { currentUser } = React.useContext(AuthContext);

  // initialisasi collection
  const collection = fire.firestore().collection("yesNoSelection");

  React.useEffect(() => {
    // get total & cek user is vote or no
    ["modal-one"].map((data) => {
      if (data) {
        collection
          .doc(data)
          .get()
          .then((res) => {
            setTotalVote(res.data());
          })
          .catch((err) => {});
      }
    });
  }, []);

  // Functions
  const handleClose = () => {
    setOpen(false);
  };

  // submit vote
  const submitSelection = (selected) => {
    const { vote } = isVoting;

    const { yes, no } = totalVote;

    if (vote) {
      collection.doc("modal-one").set({
        yes: selected === "yes" ? yes + 1 : yes - 1,
        no: selected === "no" ? no + 1 : no - 1,
      });
    } else {
      collection.doc("modal-one").set({
        yes: selected === "yes" ? yes + 1 : yes,
        no: selected === "no" ? no + 1 : no,
      });
    }
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
      scroll={"body"}
    >
      <Fade in={open}>
        <IconButton
          onClick={() => {
            submitSelection("no");
            handleClose();
          }}
          style={{ position: "absolute", right: 0 }}
        >
          <Close />
        </IconButton>
        <Paper
          style={{
            padding: 20,
            width: "100%",
            height: "auto",
          }}
        >
          <div
            style={{
              textAlign: "center",
            }}
          >
            <br />
            <img style={{ maxWidth: 200 }} src={Logo} />
            <br />
            <Typography>
              Malas membaca buku? Kamu dapat menghabiskan{" "}
              <b>berbagai buku terpopuler dunia hanya dalam 15 menit!</b>
            </Typography>
            <Button
              onClick={() => {
                submitSelection("yes");
                history.push("/");
                window.scrollTo(0, 0);
              }}
              fullWidth
              round
            >
              Mau dong
            </Button>
            <br />
            <Button
              style={{ marginBottom: "30px" }}
              color="gray"
              onClick={() => {
                submitSelection("no");
                handleClose();
              }}
              fullWidth
              round
            >
              Gak deh
            </Button>
          </div>
        </Paper>
      </Fade>
    </Dialog>
  );
}
