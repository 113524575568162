import React, { useState, useContext } from "react";
import { Redirect, withRouter, useHistory } from "react-router";

// Material UI components
import { makeStyles, TextField, Link } from "@material-ui/core";
import { Alert } from "@material-ui/lab";

// Custom components
import Button from "../../components/Button";
import Typography from "../../components/Typography";
import MultiUseMobile from "../../styles/MultiUseMobile";

// Auth
import { AuthContext } from "../../components/Routing/Auth";
import fire from "../../firebase/fire";
import { ContextReact } from "../../context";
import { setModalAuth } from "../../context/action";
import { setRedirectTo } from "./../../context/action";
import { firebaseTrackSignUp } from "../../firebase/firebaseDashboard";
import { isLOgin } from "../../utils";

const userNW = {
  vote: [
    { user: "1", status: "yes" },
    { user: "2", status: "no" },
    { user: "3", status: "yes" },
    { user: "4", status: "no" },
    { user: "5", status: "yes" },
    { user: "6", status: "no" }
  ],
  total: {
    yes: 3,
    no: 3
  }
};

const SignUpForm = ({ IconCloseModal, switchModal }) => {
  // Styles
  const multi = MultiUseMobile();

  // useState hooks
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const { currentUser } = useContext(AuthContext);
  const [pending, setPending] = useState(true);

  // Context
  const context = React.useContext(ContextReact);
  const { dispatch } = context;

  const history = useHistory();

  const handleSubmit = (e) => {
    // Auth
    const auth = fire.auth();
    const firestore = fire.firestore();

    e.preventDefault();
    auth
      .createUserWithEmailAndPassword(email, password)
      .then((resp) => {
        console.log("CREATING IN FIRESTORE...");
        //Store the new user information in the database via firestore
        firestore
          .collection("users")
          .doc(resp.user.uid)
          .set({
            firstName: firstName,
            lastName: lastName,
            phoneNumber: phoneNumber,
            email: email,
            owned_books: ["GRIT"],
            favorite_books: [],
            is_subscribed: false,
            cart: [],
            start_date: new Date("9/9/99"), // this date means UNSUBSCRIBED
            end_date: new Date("9/9/99"), // this date means UNSUBSCRIBED
            sign_up_date: new Date(),
            promo_codes_used: []
          })
          .then((resp) => {
            console.log("Added user data to firestore...");
            //Make API request to create new subscriber for Non-Subscribers in KIRIM.EMAIL
            fetch("../../kirim-email-create-subscriber", {
              method: "POST",
              headers: { "Content-Type": "application/json" },
              // We convert the React state to JSON and send it as the POST body
              body: JSON.stringify({
                full_name: firstName,
                email: email,
                no_hp: phoneNumber,
                kirim_email_list_id: "190689"
              })
            }).then(function (response) {
              // console.log(response);
              return response.json();
            });

            //Make API request to create new subscriber for All in KIRIM.EMAIL
            fetch("../../kirim-email-create-subscriber", {
              method: "POST",
              headers: { "Content-Type": "application/json" },
              // We convert the React state to JSON and send it as the POST body
              body: JSON.stringify({
                full_name: firstName,
                email: email,
                no_hp: phoneNumber,
                kirim_email_list_id: "190688"
              })
            }).then(function (response) {
              return response.json();
            });

            setPending(false);
            firebaseTrackSignUp({ email: isLOgin().email, date: new Date() });

            dispatch(setModalAuth({ type: "signup", modal: false }));
          })
          .catch((err) => {
            //Sign up fail case
            var errorCode = err.code;
            var errorMessage = err.message;
            return setError(
              "ERROR (" + errorCode + "):" + "\n\n" + errorMessage
            );
          });
        //Sign up success case
        console.log("Firebase signup suceeded!");
      })
      .catch((err) => {
        //Sign up fail case
        var errorCode = err.code;
        var errorMessage = err.message;
        return setError("ERROR (" + errorCode + "):" + "\n\n" + errorMessage);
      });
    // }
  };

  if (currentUser && !pending) {
    if (window.location.pathname === "/pricing") {
      window.scrollTo(0, 0);
      return <Redirect to={context.data.redirectTo || "/library"} />;
    } else {
      return <Redirect to="/library" />;
    }
  }

  const isModal = IconCloseModal;

  return (
    <>
      {isModal && <IconCloseModal />}
      <form className={multi.textFieldRoot} onSubmit={handleSubmit}>
        <Typography style={{ textAlign: "center" }} size="subheading">
          Sign up for SekilasAja!
        </Typography>
        {error && (
          <div className={multi.alertRoot}>
            <Alert severity="error">{error}</Alert>
          </div>
        )}
        <TextField
          label="Nama Lengkap"
          variant="filled"
          required
          value={firstName}
          onChange={(e) => setFirstName(e.target.value)}
          fullWidth
        />
        <TextField
          label="Nomor HP"
          variant="filled"
          required
          value={phoneNumber}
          onChange={(e) => setPhoneNumber(e.target.value)}
          fullWidth
        />
        <TextField
          label="Email"
          variant="filled"
          type="email"
          required
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          fullWidth
        />
        <TextField
          label="Password"
          variant="filled"
          type="password"
          required
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          fullWidth
        />
        <Button fullWidth round type="submit" color="primary">
          Sign Up
        </Button>

        <Typography style={{ textAlign: "center" }}>
          Sudah mendaftar?{" "}
          <Link
            style={{ cursor: "pointer", textDecoration: "none" }}
            onClick={() => {
              if (isModal) {
                switchModal("login");
              } else {
                history.push("/login");
                window.scrollTo(0, 0);
              }
            }}
            className={multi.link}
            underline="none"
          >
            Masuk sekarang!
          </Link>
        </Typography>
      </form>
    </>
  );
};

export default SignUpForm;
