import React from "react";

// Custom comonents
import Typography from "../../../components/Typography";

// import compoentn from mui
import {
  makeStyles,
  Avatar,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  TextField,
  CircularProgress,
} from "@material-ui/core";

// import icon
import {
  AccountCircle,
  ThumbDown,
  ThumbUp,
  Delete,
  Send,
  ArrowDropDown,
  ArrowDropUp,
} from "@material-ui/icons";

// import function from utils
import {
  cekArray,
  filterWords,
  getRandomInt,
  isLOgin,
  revisiNumber,
} from "../../../utils";

// import initial firebase
import fire from "../../../firebase/fire";

// import api comment
import {
  createSubCOmment,
  fireHybridLikeDislikeSubComment,
  fireLikeSubComment,
  fireToggleLikeSubComment,
  newDeleteSubComment,
} from "../../../firebase/firebaseComment";

// import sub comment / reply comment
import SubCommentary from "./SubCommentary";
import { Alert } from "@material-ui/lab";
import { ContextReact } from "../../../context";
import { setModalAuth } from "../../../context/action";

// create styling from mui
const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
}));

export default function Commentary({
  data,
  deleteComment,
  refreshData,
  loadComment,
  likeDislikeComment,
}) {
  const classes = useStyles();

  // hook
  const { name, text, date, id, book_title } = data;
  const [fieldReply, setFieldReply] = React.useState(false);
  const [value, setValue] = React.useState("");
  const [listReply, setListReply] = React.useState(false);
  const [showSubComment, setShowSubComment] = React.useState(false);
  const [invalidComment, setInvalidComment] = React.useState(false);

  // hook load
  const [load, setLoad] = React.useState(false);

  // load sub comment
  const [loadDelete, setLoadDelete] = React.useState({});
  const [loadcreate, setLoadcreate] = React.useState({});

  // load comment
  const { loadDeleteComment } = loadComment;

  // like dislike comment
  const { likeComment, dislikeComment } = likeDislikeComment;

  // get date formatted
  const getDate = () => {
    const isFunction = typeof date.getDate === "function";

    const year = isFunction ? date.getFullYear() : date.toDate().getFullYear(),
      month = isFunction ? date.getMonth() + 1 : date.toDate().getMonth() + 1,
      day = isFunction ? date.getDate() : date.toDate().getDate(),
      minuts = isFunction ? date.getMinutes() : date.toDate().getMinutes(),
      hours = isFunction ? date.getHours() : date.toDate().getHours();

    return `${day}/${month}/${year}  ${revisiNumber(hours)}:${revisiNumber(
      minuts
    )}`;
  };

  React.useEffect(() => {
    setListReply((data.reply && Object.values(data.reply)) || []);
  }, []);
  // react context for open modal login

  const context = React.useContext(ContextReact);
  const { dispatch } = context;

  // function reply comment
  const replyComment = async (fromSUb) => {
    const newValue = filterWords(value);

    if (!isLOgin()) {
      dispatch(setModalAuth({ type: "login", modal: true }));
      setValue("");
    } else {
      const filterCommentByEmail = await listReply.filter(
        (data) => data.email === isLOgin().email
      );
      const filterCommentByTextComment = await filterCommentByEmail.filter(
        (data) => data.text === newValue
      );

      if (filterCommentByTextComment.length > 0) {
        setInvalidComment(true);
        setValue("");

        setTimeout(() => {
          setInvalidComment(false);
          setLoadcreate({});
        }, 2000);
      } else if (newValue) {
        if (!fromSUb) {
          setLoad(true);
        }

        // initial id
        const id = getRandomInt();

        // initial newValue
        const dataReply = {
          reply: {
            [id]: {
              name: isLOgin().firstName,
              text: newValue,
              date: new Date(),
              id,
              email: isLOgin().email,
            },
          },
        };

        // reply for state
        setListReply([
          ...listReply,
          {
            name: isLOgin().firstName,
            text: newValue,
            date: new Date(),
            id,
            email: isLOgin().email,
          },
        ]);

        // reply for database
        await createSubCOmment({
          book_title,
          dataReply,
          idComment: data.id,
        });

        // reset value sub comment
        refreshData();
        setShowSubComment(true);
        setValue("");
        setFieldReply(false);
        setLoad(false);
        setLoadcreate({});
      }
    }
  };

  // delete sub comment
  const deleteSubComment = async (idSubComment) => {
    const filtered = listReply.filter((data) => data.id !== idSubComment);

    // delete for state
    await setListReply(filtered);

    // delete for database
    await newDeleteSubComment({
      book_title,
      name,
      text,
      date,
      idSubComment,
      idComment: id,
    });
    refreshData();
  };

  const firstName = isLOgin() ? isLOgin().firstName.toUpperCase() : "O";

  const toggleField = () => {
    setTimeout(() => {
      setFieldReply(!fieldReply);
    }, 250);
  };

  const toggleLikeDislikeSubComment = (dataToAction, type) => {
    fireToggleLikeSubComment({ ...dataToAction, type: type }, refreshData);
  };

  const hybridLikeDislikeSubComment = (dataSubComment, type) => {
    if (isLOgin()) {
      const filter = dataSubComment.likeDislike
        ? dataSubComment.likeDislike[type]
          ? Object.values(dataSubComment.likeDislike[type].user).filter(
              (data) => data.email === isLOgin().email
            )
          : []
        : [];
      if (filter && filter.length > 0) {
        const idLike = Object.values(
          dataSubComment.likeDislike[type].user
        ).find((data) => data.email === isLOgin().email);
        toggleLikeDislikeSubComment(
          {
            dataSubComment,
            book_title,
            dataComment: data,
            email: isLOgin().email,
            idLike,
          },
          type
        );
      } else {
        fireHybridLikeDislikeSubComment(
          {
            dataSubComment,
            book_title,
            dataComment: data,
            email: isLOgin().email,
            type,
          },
          refreshData
        );
      }
    }
  };

  return (
    <>
      <ListItem alignItems="flex-start">
        <ListItemAvatar>
          <Avatar
            alt={name && name.toUpperCase()}
            src="/static/images/avatar/1.jpg"
          />
        </ListItemAvatar>
        {isLOgin().email === data.email && (
          <IconButton
            onClick={() => deleteComment(data.id)}
            style={{ float: "right", position: "absolute", right: 0 }}
            edge="end"
            aria-label="delete"
          >
            {loadDeleteComment && loadDeleteComment.id === data.id ? (
              <CircularProgress size={25} />
            ) : (
              <Delete />
            )}
          </IconButton>
        )}

        <ListItemText
          primary={
            <Typography>
              <b>{name} </b> <small>{getDate()}</small>
            </Typography>
          }
          secondary={
            <React.Fragment>
              <Typography>{text}</Typography>
              <br />
              <IconButton
                onClick={() => likeComment({ ...data, type: "like" })}
                aria-label="delete"
                className={classes.margin}
                size="small"
              >
                <ThumbUp style={{ marginRight: 3 }} fontSize="inherit" />{" "}
                {data.likeDislike &&
                  data.likeDislike.like &&
                  data.likeDislike.like.total > 0 &&
                  data.likeDislike.like.total}
              </IconButton>
              <IconButton
                onClick={() => dislikeComment({ ...data, type: "dislike" })}
                aria-label="delete"
                className={classes.margin}
                size="small"
              >
                <ThumbDown style={{ marginRight: 3 }} fontSize="inherit" />
                {data.likeDislike &&
                  data.likeDislike.dislike &&
                  data.likeDislike.dislike.total > 0 &&
                  data.likeDislike.dislike.total}
              </IconButton>
              <IconButton
                style={{ fontSize: "small" }}
                onClick={() => {
                  setFieldReply(!fieldReply);
                  setTimeout(() => {
                    if (!fieldReply) {
                      const element = document.getElementById(
                        "input-with-icon-textfield"
                      );
                      if (element) {
                        element.focus();
                      }
                    }
                  }, 100);
                }}
              >
                Reply
              </IconButton>
              <>
                {fieldReply && (
                  <>
                    <form
                      onSubmit={(e) => {
                        e.preventDefault();
                      }}
                      className={classes.root}
                      noValidate
                      autoComplete="off"
                    >
                      <TextField
                        onBlur={() => toggleField()}
                        value={value}
                        fullWidth
                        onChange={(e) => setValue(e.target.value)}
                        id="input-with-icon-textfield"
                        label=""
                        InputProps={{
                          startAdornment: (
                            <Avatar
                              style={{
                                margin: "5px",
                                marginRight: "10px",
                              }}
                              alt={firstName}
                              src="/static/images/avatar/1.jpg"
                            />
                          ),
                          endAdornment: fieldReply && (
                            <InputAdornment position="end">
                              <IconButton
                                onClick={() => replyComment()}
                                type="submit"
                                aria-label="comment book"
                              >
                                {load ? (
                                  <CircularProgress size={25} />
                                ) : (
                                  <Send />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </form>
                    {invalidComment && (
                      <Alert severity="warning">
                        Maaf, anda tidak bisa memberikan balasan komentar yang
                        sama secara berulang
                      </Alert>
                    )}{" "}
                  </>
                )}
                <br />
              </>
              {data.reply && cekArray(Object.values(data.reply)) && (
                <IconButton
                  onClick={() => setShowSubComment(!showSubComment)}
                  aria-label="arrow"
                  style={{ background: "none", cursor: "pointer" }}
                >
                  {showSubComment ? (
                    <>
                      <ArrowDropUp />
                      <b style={{ fontSize: "small" }}>
                        Sembunyikan {Object.values(data.reply).length} balasan
                      </b>
                    </>
                  ) : (
                    <>
                      <ArrowDropDown />
                      <b style={{ fontSize: "small" }}>
                        Tampilkan {Object.values(data.reply).length} balasan
                      </b>
                    </>
                  )}
                </IconButton>
              )}

              {showSubComment && (
                <>
                  {data.reply && cekArray(Object.values(data.reply)) && (
                    <>
                      <List>
                        {Object.values(data.reply)
                          .sort((a, b) => b.date - a.date)
                          .map((dataReply, index) => {
                            return (
                              <React.Fragment key={index}>
                                <SubCommentary
                                  likeDslike={{
                                    toggleLikeDislikeSubComment,
                                    likeSubComment: hybridLikeDislikeSubComment,
                                    dislikeSubComment:
                                      hybridLikeDislikeSubComment,
                                  }}
                                  deleteSubComment={(id) =>
                                    deleteSubComment(id)
                                  }
                                  data={dataReply}
                                  setValue={setValue}
                                  value={value}
                                  replyComment={replyComment}
                                  firstName={firstName}
                                  load={{ loadDelete, loadcreate }}
                                  setLoad={{ setLoadDelete, setLoadcreate }}
                                  invalidComment={invalidComment}
                                />
                              </React.Fragment>
                            );
                          })}
                      </List>
                    </>
                  )}
                </>
              )}
            </React.Fragment>
          }
        />
      </ListItem>
    </>
  );
}
