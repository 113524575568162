import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";

// @material-ui/core components
import { makeStyles, Grid, Paper } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";

// Custom components
import InfoAreaStyle from "../../styles/InfoAreaStyle";
import Typography from "../Typography";
import { grayColor, primaryColor, secondaryColor } from "../../styles/Style";

// Firebase components
import * as firebaseGetBookCoverImageURL from "../../firebase/firebaseGetBookCoverImageURL";

// Images
const unreleasedCover =
  "https://firebasestorage.googleapis.com/v0/b/sekilasaja-999fd.appspot.com/o/Book_Cover_Images_JPG%2Funreleased-cover.jpg?alt=media&token=a0d39b53-e2b0-4888-86f7-ec9d39dab55d";

// Styles
const useStyles = makeStyles(InfoAreaStyle);

export default function BookCard({
  product,
  chosenCategory,
  notOwned,
  extraSpace,
  isNotPremium,
}) {
  // Styles
  const classes = useStyles();

  // History
  const history = useHistory();

  // useState hooks
  const [coverLink, setCoverLink] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      const getLink = firebaseGetBookCoverImageURL.getBookCoverImageURL(
        product.book_title
      );
      const link = await getLink;

      if (link !== undefined) setCoverLink(link);
    };
    fetchData();
  }, [, chosenCategory]);

  return (
    <div style={{ padding: 7 }}>
      <Grid item>
        <Link
          to={`/book-details/${product.book_title}`}
          style={{ textDecoration: "none" }}
          onClick={() => {
            window.scrollTo(0, 0);
          }}
        >
          {coverLink || unreleasedCover ? (
            <div className={classes.bookCoverNew}>
              <div>
                <img
                  src={coverLink || unreleasedCover}
                  alt={product.book_title}
                  className={notOwned + " " + classes.imgBookCoverNew}
                />
                <div
                  style={{ paddingTop: 5 }}
                  className={classes.descriptionWrapper}
                >
                  <div style={{ display: "flex" }}>
                    <div style={{ marginRight: 4 }}>
                      <div
                        style={{
                          background: grayColor,
                          position: "relative",
                          display: "inline-block",
                          justifyContent: "center",
                          textAlign: "center",
                          padding: 5,
                          borderRadius: 30,
                        }}
                      >
                        <Typography
                          style={{ margin: 0 }}
                          type="bold"
                          size="small"
                        >
                          {product.read_time}min
                        </Typography>
                      </div>
                    </div>

                    <div hidden={isNotPremium}>
                      <div
                        style={{
                          background: secondaryColor,
                          color: primaryColor,
                          position: "relative",
                          display: "inline-block",
                          justifyContent: "center",
                          textAlign: "center",
                          padding: 5,
                          borderRadius: 30,
                        }}
                      >
                        <Typography
                          style={{ margin: 0 }}
                          type="bold"
                          size="small"
                          color="yellowColor"
                        >
                          Video
                        </Typography>
                      </div>
                    </div>
                  </div>

                  <Typography className={classes.lineClampDesc}>
                    {product.short_desc}
                  </Typography>
                </div>

                <div />
              </div>
            </div>
          ) : (
            <Skeleton variant="rect" width={220} height={500} />
          )}
        </Link>

        {extraSpace}
      </Grid>

      <div style={{ marginBottom: "50px" }} />
    </div>
  );
}
