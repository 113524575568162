import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

// Custom components
import Typography from "../../components/Typography";
import MultiUseMobile from "../../styles/MultiUseMobile";
import { beigeColor } from "../../styles/Style";
import BookCard from "../../components/Cards/BookCard";
import { CustomArrow } from "../../components/Carousel/CustomArrow";
import CombiningTextWithUnderline from "../../components/CombiningTextWithUnderline";
import { secondaryColor } from "../../styles/Style";

// Material-UI components
import { Container, Grid } from "@material-ui/core";

// Other components
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 6,
    slidesToSlide: 6, // optional, default to 1.
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 4,
    slidesToSlide: 4, // optional, default to 1.
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 2,
    slidesToSlide: 2, // optional, default to 1.
  },
};

export default function SearchResultsBlock(props) {
  const classes = MultiUseMobile();
  const { searchResults, history } = props;

  const [isSearchResultsEmpty, setIsSearchResultsEmpty] = useState(true);

  useEffect(() => {
    if (searchResults.length < 1) {
      setIsSearchResultsEmpty(true);
    } else {
      setIsSearchResultsEmpty(false);
    }

    //Clean up function for when page changes...
    return function cleanup() {
      setIsSearchResultsEmpty(true);
    };
  }, [searchResults]);

  const categories = [
    "Bisnis & Investasi",
    "Pengembangan Diri",
    "Biografi",
    "Kesehatan & Olahraga",
    "Non-fiksi",
  ];

  console.log(
    searchResults.filter(
      (product) => product.category.includes("Productivity") === true
    )
  );

  return (
    <div style={{ backgroundColor: beigeColor }}>
      {isSearchResultsEmpty ? (
        <div style={{ textAlign: "center" }}>
          <div style={{ marginTop: "230px" }} />
          <Typography size="heading">Hasil Pencarian</Typography>
          <Typography
            style={{ fontWeight: "normal" }}
            size="subheading"
            type="italic"
          >
            Kilas tidak ditemukan!
          </Typography>

          <div style={{ marginBottom: "250px" }} />
        </div>
      ) : (
        <div>
          <Typography style={{ textAlign: "center" }} size="heading">
            <strong className={classes.underline}>Hasil</strong> Pencarian
          </Typography>

          <Container>
            {categories.map((category) => (
              <div>
                {searchResults.filter(
                  (product) => product.category.includes(category) === true
                ).length !== 0 ? (
                  <Grid
                    container
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Grid item>
                      <Typography size="subheading">
                        <CombiningTextWithUnderline
                          text={{ underline: category }}
                          position="back"
                        />
                      </Typography>
                    </Grid>

                    <Grid item>
                      <Link
                        to={`/show-by-category/${category}`}
                        style={{ color: secondaryColor }}
                      >
                        Lihat semua >
                      </Link>
                    </Grid>
                  </Grid>
                ) : (
                  <></>
                )}

                <Carousel
                  swipeable={true}
                  draggable={false}
                  showDots={false}
                  responsive={responsive}
                  ssr={true}
                  infinite={true}
                  autoPlay={true}
                  autoPlaySpeed={2500}
                  keyBoardControl={true}
                  customRightArrow={<CustomArrow />}
                  customLeftArrow={
                    <CustomArrow className="react-multiple-carousel__arrow react-multiple-carousel__arrow--left" />
                  }
                >
                  {searchResults
                    .filter(
                      (product) => product.category.includes(category) === true
                    )
                    .map((categorisedProduct, index) => (
                      <BookCard
                        isNotPremium={
                          categorisedProduct.video_link === "" ? true : false
                        }
                        key={index}
                        product={categorisedProduct}
                        extraSpace={<div style={{ marginTop: "20px" }} />}
                      />
                    ))}
                </Carousel>
              </div>
            ))}
          </Container>
          <div style={{ marginBottom: "120px" }} />
        </div>
      )}
    </div>
  );
}
