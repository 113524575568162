import React from "react";
import { useParams } from "react-router";

// Material-UI
import { makeStyles, Grid, Paper } from "@material-ui/core";
import CheckIcon from "@material-ui/icons/Check";

// Custom components
import Typography from "../../components/Typography";
import RegularButton from "../../components/Button";
import ModalPricing from "../../components/Modal/ModalPricing";
import { isLOgin } from "../../utils";

// Firebase
import getBookCoverImageURL from "../../firebase/firebaseGetBookCoverImageURL";
import {
  firebaseNewGetProgress,
  getChallengeByTittle,
} from "../../firebase/firebaseChallenge";
import fire from "../../firebase/fire";

// Styles
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    borderRadius: 30,
  },
  image: {
    height: 240,
  },
  img: {
    margin: "auto",
    display: "block",
    maxWidth: "100%",
    maxHeight: "100%",
  },
  lineClampOne: {
    display: "-webkit-box",
    "-webkit-line-clamp": 1,
    "-webkit-box-orient": "vertical",
    overflow: "hidden",
  },
  lineClampTwo: {
    display: "-webkit-box",
    "-webkit-line-clamp": 2,
    "-webkit-box-orient": "vertical",
    overflow: "hidden",
  },
}));

export default function Card({ data, refresh, history, day }) {
  // Hook style
  const classes = useStyles();
  const useParam = useParams();
  const stateParam = history.location.state;
  const { is_subscribed, owned_books } = data.detailUser.user;

  // Hook
  const [cover, setCover] = React.useState("");
  const [progress, setProgress] = React.useState({});
  const [modal, setToggleModal] = React.useState(false);

  // Functions
  const getCover = async () => {
    const newCover = await getBookCoverImageURL(data.book_title);
    setCover(newCover);

    return newCover;
  };

  const getProgress = async () => {
    const newGetChallengeByTittle = await getChallengeByTittle(
      useParam.category
    );

    const progress = await firebaseNewGetProgress({
      email: isLOgin().email,
      challenge: stateParam ? stateParam.challenge : newGetChallengeByTittle.id,
    });
    setProgress(progress);
  };

  const doneReading = () => {
    fire
      .firestore()
      .collection("newChallenge")
      .doc(stateParam.challenge)
      .collection("user")
      .doc(isLOgin().email)
      .set(
        {
          [data.book_title]: {
            status: "done",
          },
        },
        { merge: true }
      )
      .then((res) => {
        getProgress();
        refresh && refresh();
      });
  };

  // useEffect
  React.useEffect(async () => {
    getCover();
    getProgress();
  }, []);

  return (
    <Grid item md={4} xs={12}>
      <ModalPricing open={modal} setOpen={setToggleModal} />
      <Paper className={classes.paper}>
        <Grid container>
          <Grid item xs={12}>
            <div className={classes.image}>
              <img
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                  borderRadius: "30px 30px 0px 0px",
                }}
                className={classes.img}
                alt={data.book_title}
                src={cover}
              />
            </div>
          </Grid>

          <Grid style={{ padding: 20 }} item xs={12}>
            <Typography type="italic">Day {day}</Typography>
            <Typography
              style={{ marginTop: 0 }}
              className={classes.lineClampOne}
              type="bold"
            >
              {" "}
              {data.book_title}
            </Typography>
            <Typography className={classes.lineClampTwo}>
              {data.short_desc}
            </Typography>

            {Array.isArray(progress.bookList) &&
            progress.bookList.includes(data.book_title) ? (
              <>
                <RegularButton
                  onClick={() => {
                    if (
                      is_subscribed ||
                      owned_books.includes(data.book_title)
                    ) {
                      history.push(`/kilas/${data.book_title}`);
                    } else {
                      setToggleModal(!modal);
                    }
                  }}
                  round
                  fullWidth
                  color="gray"
                >
                  Baca Ulang
                </RegularButton>
                <RegularButton color="transparent" round fullWidth>
                  Selesai 🗹
                </RegularButton>
              </>
            ) : (
              <>
                <RegularButton
                  onClick={() => {
                    if (is_subscribed) {
                      history.push(`/kilas/${data.book_title}`);
                    } else {
                      setToggleModal(!modal);
                    }
                  }}
                  round
                  fullWidth
                >
                  Baca Sekarang
                </RegularButton>
                <br />
                <RegularButton
                  onClick={() => {
                    if (
                      is_subscribed ||
                      owned_books.includes(data.book_title)
                    ) {
                      doneReading();
                    } else {
                      setToggleModal(!modal);
                    }
                  }}
                  color="secondary"
                  fullWidth
                  round
                >
                  Selesai Baca
                </RegularButton>
              </>
            )}
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
}
