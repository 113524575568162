import React from "react";

// Custom components
import Typography from "../../../components/Typography";
import CombiningTextWithUnderline from "../../../components/CombiningTextWithUnderline";
import ChallengeCard from "../../../components/Cards/ChallengeCard";

// Material-UI components
import { Container, Grid } from "@material-ui/core";

function CardChallenge({ array, tittle }) {
  return (
    <Container>
      <Grid
        container
        alignItems="center"
        justifyContent="space-between"
        spacing={3}
      >
        <Grid item xs={12}>
          <Typography size="subheading">
            <CombiningTextWithUnderline
              text={{ underline: tittle || "Title Category" }}
              position="back"
            />
          </Typography>
        </Grid>

        {[...array].map((data, index) => {
          return <ChallengeCard key={index} data={{ ...data }} />;
        })}
      </Grid>
    </Container>
  );
}

export default CardChallenge;
