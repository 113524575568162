import React from "react";

// @material-ui/core components
import { Grid, Container } from "@material-ui/core";
import { VideoLibrary, MenuBook, Group, HeadsetMic } from "@material-ui/icons";

// Custom components
import MultiUseMobile from "../../../styles/MultiUseMobile";
import Typography from "../../../components/Typography";
import InfoArea from "./InfoArea";
import CombiningTextWithUnderline from "../../../components/CombiningTextWithUnderline";
import { primaryColor } from "../../../styles/Style";

export default function BenefitsBlock({ logo, button }) {
  const classes = MultiUseMobile();

  return (
    <div>
      <Container>
        <Grid container direction="row" justifyContent="center" spacing={3}>
          <Grid className={classes.title} item xs={12}>
            <Typography style={{ textAlign: "center" }} size="heading">
              Kenapa Harus{" "}
              <CombiningTextWithUnderline
                text={{ underline: "Bergabung" }}
                position="back"
              />{" "}
              Di {logo} ?
            </Typography>
            <br />

            <div
              style={{
                padding: 10,
                border: "1px solid #41444b",
                backgroundColor: primaryColor,
                borderRadius: 5,
                textAlign: "center",
              }}
            >
              <Typography style={{
                fontSize: 22
              }}>
                Baca <strong>200+ rangkuman buku</strong> dan belajar bersama <strong>7500++ USER LAINNYA!</strong>
              </Typography>
            </div>
          </Grid>
          <Grid item md={3} xs={10}>
            <InfoArea
              title="Teks, Audio, & Video"
              description={
                <div>
                  <b>BACA</b>,<b> DENGAR</b>, atau <b>TONTON</b> intisari dari
                  buku-buku terbaik dunia dalam <b>15 MENIT!</b>
                </div>
              }
              icon={HeadsetMic}
              iconColor="info"
              vertical
            />
          </Grid>
          <Grid item md={3} xs={10}>
            <InfoArea
              title="Live Group Mentoring"
              description={
                <div>
                  <b>BELAJAR SECARA LANGSUNG</b> dengan <b>MENTOR PILIHAN</b>{" "}
                  mengenai pelajaran yang tidak pernah diajarkan di sekolah!
                </div>
              }
              icon={VideoLibrary}
              vertical
            />
          </Grid>

          <Grid item md={3} xs={10}>
            <InfoArea
              title="Komunitas Eksklusif"
              description={
                <div>
                  Kesuksesan dipengaruhi oleh dengan siapa Kamu bergaul!
                  Bergabung dengan <b>KOMUNITAS TERBAIK</b> untuk{" "}
                  <b>MENGEMBANGAN DIRI KAMU!</b>
                </div>
              }
              icon={Group}
              vertical
            />
          </Grid>

          <Grid item md={3} xs={10}>
            <InfoArea
              title="Ratusan Kilas Terbaik"
              description={
                <div>
                  Full akses <b>100+ RANGKUMAN BUKU</b> terbaik dunia dalam
                  berbagai kategori. Dapatkan <b>20+ BUKU BARU</b> setiap
                  bulannya!
                </div>
              }
              icon={MenuBook}
              vertical
            />
            <div style={{ marginBottom: "30px" }} />
          </Grid>

          <Grid item xs={12}>
            <div style={{ display: "flex", justifyContent: "center" }}>
              {button}
            </div>
            <div style={{ marginBottom: "50px" }} />
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}
