import React, { useState } from "react";

// Custom components
import Typography from "../../components/Typography";
import MultiUseMobile from "../../styles/MultiUseMobile";

// Material-UI components
import { Container, Collapse, ListItem, List } from "@material-ui/core";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";

export default function Accordion({ title, content }) {
  // useState hooks
  const [open, setOpen] = useState(true);

  // styles
  const multi = MultiUseMobile();

  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <div
      style={{
        marginBottom: 20,
      }}
    >
      <ListItem
        style={{
          justifyContent: "space-between",
        }}
        button
        onClick={handleClick}
      >
        <Typography
          style={{ fontSize: 20, marginLeft: -6 }}
          className={multi.underline}
          type="bold"
        >
          {title}
        </Typography>
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItem>

      <Collapse in={open} timeout="auto" unmountOnExit>
        <List
          style={{
            paddingTop: 10,
            paddingBottom: 10,
          }}
          component="div"
          disablePadding
        >
          {content}
        </List>
      </Collapse>
    </div>
  );
}
