import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    textAlign: "center",
  },
}));

export default function ModalAuth({ state, setState, submit }) {
  const classes = useStyles();
  const { password, auth, open } = state;
  const { setAuth, setPassword, setOpen } = setState;

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={!auth ? true : false}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={!auth ? true : false}>
          <div className={classes.paper}>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                submit();
              }}
              className={classes.root}
              noValidate
              autoComplete="off"
            >
              <Typography variant="h6" gutterBottom>
                Verifikasi Admin
              </Typography>
              <TextField
                onChange={(e) => setPassword(e.target.value)}
                type={"password"}
                id="standard-basic"
                label=""
              />
              <br />
              <br />
              <Button
                onClick={() => submit()}
                type="submit"
                variant="contained"
              >
                Verify
              </Button>
            </form>
          </div>
        </Fade>
      </Modal>
    </div>
  );
}
