import fire from "./fire";
const db = fire.firestore().collection("rate");

export const firebaseSetRateKilas = async (data, book, func) => {
    if (data) {
      await db.doc(book).set(data, { merge: true });
      Object.keys(data).map((key) => {
        db.doc(book)
          .collection("kilas")
          .doc(key)
          .set(data[key], { merge: true })
          .then((res) => {
            func && func();
          });
      });
    }
  },
  firebaseGetRateKilas = () => {
    return db.doc("test").collection("kilas").get();
  },
  firebaseSetRateVideo = async (data, book, func) => {
    if (data) {
      await db
        .doc(book)
        .set({ video: data }, { merge: true })
        .then((res) => {
          func && func();
        });
    }
  };
