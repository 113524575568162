import React, { useState, useEffect } from "react";
import { useParams, Redirect } from "react-router";

// momentjs
import moment from "moment";

// Whatsapp Button
import MultiUseMobile from "../../styles/MultiUseMobile";

// Custom components
import Typography from "../../components/Typography";
import Footer from "../../components/Footer";
import Header from "../../components/NavBar/Header";
import HeaderLinks from "../../components/NavBar/HeaderLinks";
import HeaderLinksMobile from "../../components/NavBar/HeaderLinksMobile";
import Loading from "../Utilities/Loading";
import ModalBlog from "../../components/Modal/ModalBlog";

// Material-UI components
import { Container, Grid } from "@material-ui/core";

// Sanity
import client from "../../sekilasajablog/client";
import BlockContent from "@sanity/block-content-to-react";

// Images
const Whatsapp =
  "https://firebasestorage.googleapis.com/v0/b/sekilasaja-999fd.appspot.com/o/Website_Images%2FWeb_Picture_Components%2FWhatsapp.png?alt=media&token=88483bb9-b9d3-4aa8-9f14-9b7f91682861";

export default function Post({ history }) {
  //Styles
  const multi = MultiUseMobile();

  // useStates
  const [singlePost, setSinglePost] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [modal, setModal] = useState(false);

  const { slug } = useParams();

  useEffect(() => {
    sessionStorage.setItem("modal", "false");
    client
      .fetch(
        `*[slug.current =="${slug}"] {
        title,
        body,
        mainImage {
          asset -> {
            _id,
            url
          },
          alt
        },
        "authorName": author -> name,
        "publishedDate": releaseDate
      }`
      )
      .then((data) => {
        setSinglePost(data[0]);
        setIsLoading(false);
      });
  }, [slug]);

  let isOpen = false;

  useEffect(() => {
    window.addEventListener("scroll", (event) => {
      const element = document.getElementById("root").clientHeight;
      const persen = element * (50 / 100);
      const bulatkan = Math.round(persen);
      const scrollY = Math.round(window.scrollY);
      if (scrollY > bulatkan && !isOpen) {
        isOpen = true;
        setModal(true);
      }
    });
  }, []);

  if (!singlePost) {
    return <Redirect to="/404page" />;
  }

  if (isLoading) {
    return <Loading />;
  }

  return (
    <>
      {isLoading ? (
        <div>
          <Loading />
        </div>
      ) : (
        <div>
          <div style={{ marginTop: "100px" }} />
          <Header
            history={history}
            rightLinks={<HeaderLinks history={history} />}
            rightLinksMobile={<HeaderLinksMobile history={history} />}
            fixed
            color="white"
          />

          <ModalBlog open={modal} setOpen={setModal} />

          <Container maxWidth="md">
            <Typography size="heading">{singlePost.title}</Typography>

            {singlePost.mainImage && singlePost.mainImage.asset && (
              <img
                style={{
                  width: "100%",
                }}
                src={singlePost.mainImage.asset.url}
                alt={singlePost.title}
              />
            )}

            <Typography>
              {singlePost.authorName} |{" "}
              {moment(singlePost.releaseDate).format("LL")}
            </Typography>
            <Typography>
              <BlockContent
                blocks={singlePost.body}
                projectId="2hp9gld0"
                dataset="production"
              />
            </Typography>
          </Container>

          {/*---------------------------------------------------------------*/}
          {/*---------------------- WHATSAPP FIXED NAV ---------------------*/}
          {/*---------------------------------------------------------------*/}
          <a href="https://wa.me/6281291176795" target="_blank">
            <div className={multi.whatsapp}>
              <Grid container spacing={3}>
                <Grid item xs={4}>
                  <img
                    style={{
                      width: "60px",
                    }}
                    src={Whatsapp}
                  />
                </Grid>

                <Grid item xs={4}>
                  <Typography type="bold">Butuh Bantuan?</Typography>
                </Grid>
              </Grid>
            </div>
          </a>
          <Footer />
        </div>
      )}
    </>
  );
}
