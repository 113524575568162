import React, { useEffect } from "react";
import ModalAuth from "./auth";
import Dashboard from "./home";
import DashboardBook from "./book";

function DashboardSekilasAja() {
  const [auth, setAuth] = React.useState(false);
  const [password, setPassword] = React.useState("");
  const [open, setOpen] = React.useState("");

  const { pathname } = window.location;

  const verifyLogin = () => {
    const check = localStorage.getItem("auth-admin");
    if (check) {
      const lastLogin = new Date(check);

      if (new Date() < lastLogin) {
        setAuth(true);
      } else {
        localStorage.removeItem("auth-admin");
        setAuth(false);
      }
    }
  };

  useEffect(() => {
    verifyLogin();
  }, []);

  function onSubmit() {
    if (password === "v3rivy") {
      let myDate = new Date();
      myDate.setHours(myDate.getHours() + 2);

      localStorage.setItem("auth-admin", myDate.toString());
      setAuth(true);
    }
  }

  const ContentPage = {
    "/dashboard": <Dashboard />,
    "/dashboard/book": <DashboardBook />
  };

  return (
    <>
      {!auth ? (
        <ModalAuth
          setState={{ setAuth, setPassword, setOpen }}
          state={{ password, auth, open }}
          submit={onSubmit}
        />
      ) : (
        <>{ContentPage[pathname]}</>
      )}
    </>
  );
}

export default DashboardSekilasAja;
