import React from "react";

// Custom comonents
import Typography from "../../../components/Typography";

// import component from mui
import {
  makeStyles,
  Input,
  IconButton,
  InputAdornment,
  List,
  CircularProgress,
  Avatar,
} from "@material-ui/core";

// impirt icon send
import SendIcon from "@material-ui/icons/Send";

// import commentary
import Commentary from "./Commentary";

// import utils function
import { cekArray, filterWords, getRandomInt, isLOgin } from "../../../utils";

// import initial fire
import fire from "./../../../firebase/fire";
import {
  createComment,
  fireHybridLikeDislikeComment,
  fireToggleLikeDislikeComment,
  newDeleteComment,
} from "../../../firebase/firebaseComment";
import { Alert } from "@material-ui/lab";
import { ContextReact } from "../../../context";
import { setModalAuth } from "../../../context/action";
import ModalDialogAuth from "../../Login/LoginModalDialog";
const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(0),
    },
  },
}));

export default function RootCommentary({ dataBook }) {
  // get define param
  const { book_title } = dataBook;

  // initial style
  const classes = useStyles();

  // hook
  const [button, setButton] = React.useState(false);
  const [listComment, setListComment] = React.useState([]);
  const [value, setValue] = React.useState("");
  const [isSUbmit, setIsSubmit] = React.useState(false);
  const [loadDeleteComment, setLoadDeleteComment] = React.useState({});
  const [invalidComment, setInvalidComment] = React.useState(false);
  const [isLimit, setIsLimit] = React.useState(false);

  // get data user
  const dataUser = isLOgin() || {};

  // initial database
  const fireBook = fire.firestore().collection("comment").doc(book_title);

  React.useEffect(() => {
    // get comment
    fireBook.get().then((res) => {
      if (res.data()) {
        setListComment(Object.values(res.data()));
      }
    });
  }, []);

  // refresh data
  const refreshData = async () => {
    await fireBook.get().then((res) => {
      if (res.data()) {
        const data = Object.values(res.data());
        setListComment(data);
      }
    });
  };

  // react context for open modal login
  const context = React.useContext(ContextReact);
  const { dispatch } = context;

  // function close modal login
  const openModalLogin = () => {
    dispatch(setModalAuth({ type: "login", modal: true }));
  };

  // submit comment
  const handleSubmit = async () => {
    const newValue = filterWords(value); // new initialisasi value and proccess sensor

    // filter comment by email to take only comment from user login
    const filterCommentByEmail = await listComment.filter(
      (data) => data.email === isLOgin().email
    );

    // filter comment by text comment for check Have you ever commented in the same word?
    const filterCommentByTextComment = await filterCommentByEmail.filter(
      (data) => data.text === newValue
    );
    // user non login
    if (!dataUser.firstName) {
      openModalLogin();
      resetValue();
    }
    // Total comments have reached the limit.
    else if (filterCommentByEmail.length === 35) {
      setIsLimit(true);
      resetValue();

      setTimeout(() => {
        setIsLimit(false);
      }, 2000);
    }
    //  For the word I've ever used.
    else if (filterCommentByTextComment.length > 0) {
      setInvalidComment(true);
      resetValue();

      setTimeout(() => {
        setInvalidComment(false);
      }, 2000);
    }
    // comment is valid
    else if (newValue && !isSUbmit) {
      setIsSubmit(true);

      const getId = getRandomInt();

      // submit to db
      await createComment({ value: newValue, book_title, id: getId });

      // submit to state
      await setListComment([
        ...listComment,
        {
          name: dataUser.firstName,
          text: newValue,
          date: new Date(),
          id: getId,
          email: isLOgin().email,
        },
      ]);

      resetValue();
      refreshData();
      setIsSubmit(false);
    }
  };

  // reset field
  const resetValue = () => {
    setValue("");
  };

  // delete comment
  const deleteComment = async (id) => {
    setLoadDeleteComment({ id });
    const filter = listComment.filter((value) => value.id !== id);

    // delete comment in database
    await newDeleteComment({ id, book_title });

    // delete comment in state
    setListComment(filter);

    // update load delete comment
    setLoadDeleteComment({});
  };

  const toggleLikeDislikeComment = (data, type) => {
    fireToggleLikeDislikeComment(data, refreshData);
  };
  const hybridLikeDislikeComment = (data, type) => {
    if (isLOgin()) {
      const filter = data.likeDislike
        ? data.likeDislike[data.type]
          ? Object.values(data.likeDislike[data.type].user).filter(
              (data) => data.email === isLOgin().email
            )
          : []
        : [];
      if (filter.length > 0) {
        toggleLikeDislikeComment({ ...data, email: isLOgin().email });
      } else {
        fireHybridLikeDislikeComment(
          {
            ...data,
            email: isLOgin().email,
          },
          refreshData
        );
      }
    }
  };

  return (
    <>
      <Typography>{listComment.length} komentar</Typography>
      {/* field input comment */}
      <form
        onSubmit={(e) => {
          e.preventDefault();
        }}
        className={classes.root}
        noValidate
        autoComplete="off"
      >
        {/* for user login */}
        <>
          <Input
            value={value}
            onChange={(e) => setValue(e.target.value)}
            fullWidth
            onFocus={() => setButton(!button)}
            onBlur={() =>
              setTimeout(() => {
                setButton(!button);
              }, 250)
            }
            placeholder="tulis komentar publik..."
            inputProps={{ "aria-label": "description" }}
            endAdornment={
              button && (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => handleSubmit()}
                    type="submit"
                    aria-label="comment book"
                  >
                    {!isSUbmit ? <SendIcon /> : <CircularProgress size={25} />}
                  </IconButton>
                </InputAdornment>
              )
            }
            startAdornment={
              <>
                <Avatar
                  style={{ margin: "5px", marginRight: "10px" }}
                  alt={
                    dataUser.firstName ? dataUser.firstName.toUpperCase() : "O"
                  }
                  src="/static/images/avatar/1.jpg"
                />
              </>
            }
          />
          {invalidComment && (
            <Alert severity="warning">
              Maaf, anda tidak bisa memberikan komentar yang sama secara
              berulang
            </Alert>
          )}
          {isLimit && (
            <Alert severity="warning">
              Maaf, anda tidak bisa memberikan komentar dikarenakan sudah
              mencapai batasan/limit berkomentar
            </Alert>
          )}
        </>
      </form>
      {/* list comment */}
      <List className={classes.root}>
        {cekArray(listComment) &&
          listComment
            .sort((a, b) => b.date - a.date)
            .map((data, index) => {
              return (
                <React.Fragment key={index}>
                  <Commentary
                    loadComment={{
                      loadDeleteComment,
                    }}
                    refreshData={() => refreshData()}
                    data={{ ...data, ...dataBook }}
                    deleteComment={(id) => deleteComment(id)}
                    likeDislikeComment={{
                      likeComment: hybridLikeDislikeComment,
                      dislikeComment: hybridLikeDislikeComment,
                    }}
                  />
                </React.Fragment>
              );
            })}
      </List>
    </>
  );
}
