import React from "react";

// Material-UI components
import { usePagination } from "@material-ui/lab/Pagination";
import { makeStyles } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";

// Custom components
import { secondaryColor } from "../../../styles/Style";

const useStyles = makeStyles({
  ul: {
    listStyle: "none",
    padding: 0,
    margin: 0,
    display: "flex",
  },
  buttonStyle: {
    color: secondaryColor,
    padding: "0px",
  },
});

const newText = (txt) => {
  txt.toLowerCase();
  if (txt === "previous") {
    return "« Previous";
  } else {
    return "Next »";
  }
};

export default function PaginationBlog({ setPage, count }) {
  const classes = useStyles();
  const { items } = usePagination({
    count: count || 1,
    onChange: (obj, page) => setPage && setPage(page),
  });

  return (
    <nav>
      <ul className={classes.ul}>
        {items.map(({ page, type, selected, ...item }, index) => {
          let children = null;

          if (type === "start-ellipsis" || type === "end-ellipsis") {
            children = "…";
          } else if (type === "page") {
            children = (
              <Button
                style={{ color: selected ? "#C3BDBD" : secondaryColor }}
                {...item}
                className={classes.buttonStyle}
              >
                {page}
              </Button>
            );
          } else {
            children = (
              <Button className={classes.buttonStyle} {...item} color="primary">
                {newText(type)}
              </Button>
            );
          }

          return <li key={index}>{children}</li>;
        })}
      </ul>
    </nav>
  );
}
