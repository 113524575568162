import * as React from "react";
import * as ReactDOM from "react-dom";
import {
  ExcelExport,
  ExcelExportColumn
} from "@progress/kendo-react-excel-export";
import { DateToFormattedString } from "../../../utils/dateFormatted";
import { Button } from "@material-ui/core";

export default function ExportListUserSubsUnSubs({
  data,
  titleButton,
  fileName
}) {
  const [newData, setNewData] = React.useState([]);

  const _exporter = React.createRef();

  const excelExport = () => {
    if (_exporter.current) {
      _exporter.current.save();
    }
  };

  React.useEffect(async () => {
    const initials = [];

    if (data && Array.isArray(data)) {
      await data.map((nd) => {
        const { email, phoneNumber, firstName } = nd.doc.data();

        const ni = {
          // date: nd.date
          //   ? DateToFormattedString(nd.date.toDate())
          //   : DateToFormattedString(new Date()),
          email: email || "-",
          first_name: firstName || "-",
          phoneNumber: phoneNumber || "-"
          // items: nd.info ? nd.info.items[0].title : "",
          // total_cost: nd.info ? nd.info.total_cost : 0,
          // is_procces: nd.is_processed ? "yes" : "no"
        };
        return initials.push(ni);
      });
      setNewData(initials);
    }
  }, []);

  return (
    <div>
      <Button onClick={excelExport} variant="text">
        {titleButton}
      </Button>

      <ExcelExport data={newData} fileName={fileName} ref={_exporter}>
        {/* <ExcelExportColumn field="date" title="date" /> */}
        <ExcelExportColumn field="email" title="email" width={350} />
        <ExcelExportColumn field="first_name" title="first name" width={350} />

        <ExcelExportColumn
          field="phoneNumber"
          title="phone number"
          width={350}
        />
        {/* <ExcelExportColumn field="items" title="items" width={350} />
        <ExcelExportColumn field="total_cost" title="total cost" width={350} />
        <ExcelExportColumn field="is_procces" title="is proccess" width={350} /> */}
      </ExcelExport>
    </div>
  );
}
