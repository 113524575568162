import React, { useEffect, useState } from "react";

// Firebase components
import fire from "../../firebase/fire";

import * as firebaseGetUserDataById from "../../firebase/firebaseGetUserDataById";

// Custom components
import Loading from "../../pages/Utilities/Loading";

// Redux
import { useDispatch } from "react-redux";
import { setUser } from "../../feature/userSlice";

// encrypt decrypt data
import crypto from "crypto-js";
import { getInvoices } from "../../pages/Payment/server";
import { getDetailUser, updateUser } from "../../firebase/firebaseUser";
import { firebaseTrackPayment } from "../../firebase/firebaseDashboard";
import { listSubscription } from "../../utils";
import { updatePayments } from "../../firebase/firePayments";

export const AuthContext = React.createContext();

// decrypt token
const getToken = localStorage.getItem("isLogedIn");
const isLogin = () => {
  if (getToken && getToken !== "true") {
    var bytes = crypto.AES.decrypt(getToken, "sekilasAjaSecret");
    var decrypted = bytes.toString(crypto.enc.Utf8);
    if (decrypted) {
      return JSON.parse(decrypted);
    } else {
      return false;
    }
  } else {
    return false;
  }
};

export const AuthProvider = ({ children }) => {
  // Auth
  const dispatch = useDispatch();
  const firestore = fire.firestore();

  // useState Hooks
  const [currentUser, setCurrentUser] = useState(isLogin());
  const [currentUserData, setCurrentUserData] = useState(null);
  const [pending, setPending] = useState(true);

  useEffect(() => {
    fire.auth().onAuthStateChanged((user) => {
      setCurrentUser(user);
      setPending(false);
    });
  }, []);

  useEffect(() => {
    if (currentUser) {
      const fetchData = async () => {
        const results = await firebaseGetUserDataById.getUserDataById(
          currentUser.uid
        );
        if (results) {
          const encrypt = crypto.AES.encrypt(
            JSON.stringify(results),
            "sekilasAjaSecret"
          );
          localStorage.setItem("isLogedIn", encrypt);
          setCurrentUserData(results);
          dispatch(setUser(results));
        } else {
          localStorage.removeItem("isLogedIn");
        }
      };
      fetchData();
    } else {
      localStorage.removeItem("isLogedIn");

      setPending(false);
    }
  }, [currentUser]);

  useEffect(() => {
    if (currentUserData) {
      const unsDate = new Date("9/9/99"); // Default year for unsubscribers
      const today = new Date(); // Today's date

      // Check if user is subcribed or not.
      // If false, user is subscribed. Otherwise, true.
      var isEndDate =
        currentUserData.end_date.toDate().toString() === unsDate.toString();

      // If user is subscribed then...
      if (!isEndDate) {
        // If user is subscribed and subscription date is ending...
        if (today > currentUserData.end_date.toDate()) {
          firestore
            .collection("users")
            .doc(currentUser.uid)
            .update({
              is_subscribed: false,
              end_date: new Date("9/9/99"),
              start_date: new Date("9/9/99")
            });
        }
      }
    }
  }, [currentUserData]);

  useEffect(() => {
    const start_date = new Date();

    let end_date = new Date();

    if (currentUser.email) {
      getDetailUser(currentUser.email).then((detailuser) => {
        const { email, phoneNumber, promo_code_invoice, id } = detailuser;

        const userPromoCodes = promo_code_invoice
          ? [...detailuser.promo_codes_used, promo_code_invoice]
          : detailuser.promo_codes_used;

        if (detailuser.email && detailuser.id_incoive) {
          getInvoices(detailuser.id_incoive).then((res) => {
            if (listSubscription.includes(res.description)) {
              if (["SETTLED", "PAID"].includes(res.status)) {
                // done
                if (res.description == "Subscription 12 Bulan") {
                  end_date.setMonth(end_date.getMonth() + 12);
                } else if (res.description == "Subscription 1 Bulan") {
                  end_date.setMonth(end_date.getMonth() + 1);
                } else if (res.description == "Subscription 1 Bulan") {
                  end_date.setMonth(end_date.getMonth() + 1);
                }
                firebaseTrackPayment({
                  email,
                  date: new Date(),
                  phoneNumber: phoneNumber,
                  item: res.description,
                  kode_promo: promo_code_invoice ? promo_code_invoice : "",
                  payment: "Xendit"
                });
                updateUser(detailuser.email, {
                  is_subscribed: true,
                  end_date: end_date,
                  start_date: start_date,
                  id_incoive: "",
                  promo_code_invoice: "",
                  promo_codes_used: userPromoCodes
                }).then((res) => {
                  updatePayments({
                    is_subscribed: true,
                    date_subscribe: new Date(),
                    user: id,
                    type: "tracking"
                  });
                });
              }
            }
          });
        }
      });
    }
  }, []);

  if (pending) {
    return (
      <>
        <Loading />
      </>
    );
  }

  return (
    <AuthContext.Provider
      value={{
        currentUser
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
