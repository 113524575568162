// Carousel
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { CustomArrow } from "../../components/Carousel/CustomArrow";

// Custom components
import ComingSoonCard from "../../components/Cards/ComingSoonCard";

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 6,
    slidesToSlide: 6, // optional, default to 1.
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 4,
    slidesToSlide: 4, // optional, default to 1.
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 2,
    slidesToSlide: 2, // optional, default to 1.
  },
};

export default ({ content }) => (
  <Carousel
    swipeable={true}
    draggable={false}
    showDots={false}
    responsive={responsive}
    ssr={true}
    infinite={true}
    autoPlay={false}
    autoPlaySpeed={2500}
    keyBoardControl={true}
    customRightArrow={<CustomArrow />}
    customLeftArrow={
      <CustomArrow className="react-multiple-carousel__arrow react-multiple-carousel__arrow--left" />
    }
  >
    {content &&
      content.map((categorisedProduct, index) => {
        return (
          <ComingSoonCard
            chosenCategory={"Coming Soon!"}
            key={index}
            product={categorisedProduct}
          />
        );
      })}
  </Carousel>
);
