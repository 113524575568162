import React, { useState, useEffect } from "react";

// Custom components
import CategoriesStyle from "../../styles/CategoriesStyle";

//nodejs library to set properties for components
import classNames from "classnames";

// Material UI components
import { Grid } from "@material-ui/core";
import BusinessIcon from "@material-ui/icons/Business";
import AccessibilityNewIcon from "@material-ui/icons/AccessibilityNew";
import PresentToAllIcon from "@material-ui/icons/PresentToAll";
import SupervisedUserCircleIcon from "@material-ui/icons/SupervisedUserCircle";
import FitnessCenter from "@material-ui/icons/FitnessCenter";
import NewReleasesIcon from "@material-ui/icons/NewReleases";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import { Casino } from "@material-ui/icons";

function CategoryButton({
  chosenCategory,
  categoryName,
  setChosenCategory,
  setIsChosenCategory,
  categoryIcon,
  hideCategory,
}) {
  // styles
  const classes = CategoriesStyle();

  // useState hooks
  const [isSelected, setIsSelected] = useState(false);

  useEffect(() => {
    if (chosenCategory === categoryName) {
      setIsSelected(true);
    } else {
      setIsSelected(false);
    }
  }, [chosenCategory]);

  var btnClass = classNames(classes.button, {
    [classes.selectedButton]: isSelected,
  });

  function categoryIcon() {
    if (categoryName == "All") return <PresentToAllIcon />;
    if (categoryName == "New Release!") return <NewReleasesIcon />;
    if (categoryName == "Coming Soon!") return <ArrowForwardIcon />;
    if (categoryName == "Bisnis & Investasi") return <BusinessIcon />;
    if (categoryName == "Pengembangan Diri") return <AccessibilityNewIcon />;
    if (categoryName == "Biografi") return <SupervisedUserCircleIcon />;
    if (categoryName == "Kesehatan & Olahraga") return <FitnessCenter />;
    if (categoryName == "Non-fiksi") return <Casino />;
  }

  return (
    <div hidden={hideCategory}>
      <Grid item>
        <div
          className={btnClass}
          color="secondary"
          onClick={(e) => {
            e.preventDefault();
            setChosenCategory(categoryName);
            setIsChosenCategory(true);
          }}
        >
          {categoryIcon()}
          <div className={classes.text}>{categoryName}</div>
        </div>
      </Grid>
    </div>
  );
}

export default CategoryButton;
