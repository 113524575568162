import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import { Box, Button, Grid } from "@material-ui/core";
import { CheckBox } from "@material-ui/icons";
import FormCategory from "./FormCategory";
import { Remove } from "@material-ui/icons";
const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
      width: "100%"
    }
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120
  }
}));

const names = [
  "Oliver Hansen",
  "Van Henry",
  "April Tucker",
  "Ralph Hubbard",
  "Omar Alexander",
  "Carlos Abbott",
  "Miriam Wagner",
  "Bradley Wilkerson",
  "Virginia Andrews",
  "Kelly Snyder"
];

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP
      // width: 250
    }
  }
};

let initialValue = {
  kilas: 1,
  title: "",
  details: [""]
};

export default function FormKilas({ detailBook, submitData }) {
  const classes = useStyles();

  const [personName, setPersonName] = React.useState([]);

  const [category, setCategory] = React.useState([]);

  const [loadRender, setLoadRender] = useState(false);

  const [descBook, setDeskBook] = useState(
    detailBook || [
      {
        kilas: 1,
        title: "",
        details: [""]
      }
    ]
  );

  const defaultVal = descBook;

  const handleChangeCategory = (event) => {
    setPersonName(event.target.value);
  };

  const handleChange = async (e, index) => {
    // setLoadRender(true);
    const { name, value } = e.target;

    let titleKilas = descBook;
    titleKilas[index].title = value;
    await setDeskBook(titleKilas);
    // setLoadRender(false);

    // let element = window.document.getElementById(`title-kilas-${index}`);
    // if (element) {
    //   element.focus();
    // }
  };

  const handleChangeDeskTitle = async (e, id_kilas, id_desc_kilas) => {
    // setLoadRender(true);
    const { name, value } = e.target;

    let titleKilas = descBook;
    titleKilas[id_kilas].details[id_desc_kilas] = value;
    await setDeskBook(titleKilas);
    // await setLoadRender(false);

    // let element = window.document.getElementById(
    //   `kilas-${id_kilas}-desc-${id_desc_kilas}`
    // );
    // if (element) {
    //   element.focus();
    // }

    // setDeskBook()
  };

  return (
    <form className={classes.root} noValidate autoComplete="off">
      {descBook.map((data, index) => {
        return (
          <>
            {!loadRender && (
              <Box
                style={{
                  border: "1px solid #2196F3",
                  padding: "10px",
                  borderRadius: 10
                }}
              >
                <h3>kilas {index + 1}</h3>
                <TextField
                  style={{
                    width: "100%",
                    marginBottom: 10
                  }}
                  onChange={(e) => handleChange(e, index)}
                  defaultValue={data.title}
                  name={"title_kilas"}
                  size="small"
                  id={`title-kilas-${index}`}
                  label={`Title Kilas ${index + 1}`}
                  variant="outlined"
                  // multiline
                  // rows={4}
                />
                {data.details.map((details, indexDetail) => {
                  return (
                    <>
                      <TextField
                        style={{
                          width: "100%",
                          marginBottom: 10
                        }}
                        onChange={(e) =>
                          handleChangeDeskTitle(e, index, indexDetail)
                        }
                        defaultValue={details}
                        name={"detail_kilas"}
                        size="small"
                        id={`kilas-${index}-desc-${indexDetail}`}
                        label={`Deskripsi Kilas ${index + 1}`}
                        variant="outlined"
                        multiline
                        rows={4}
                      />

                      {indexDetail > 0 && (
                        <>
                          <div style={{}}>
                            <Button
                              style={{
                                float: "right",
                                marginBottom: 10
                              }}
                              onClick={async () => {
                                await setLoadRender(true);
                                let newDescKilas = descBook;

                                delete newDescKilas[index].details[indexDetail];

                                await setDeskBook(newDescKilas);
                                setLoadRender(false);
                              }}
                              variant="contained"
                              color="primary"
                            >
                              <Remove />
                            </Button>
                          </div>
                        </>
                      )}
                    </>
                  );
                })}
                <div>
                  <Button
                    onClick={async () => {
                      await setLoadRender(true);

                      let newDescKilas = descBook;

                      newDescKilas[index].details.push("");

                      await setDeskBook(newDescKilas);
                      setLoadRender(false);
                    }}
                    variant="contained"
                    color="primary"
                  >
                    Tambah Detail Kilas
                  </Button>
                </div>
                {index > 0 && (
                  <>
                    <br />
                    <Grid spacing={3}>
                      <Grid item xs={12} sm={6}>
                        <div>
                          <Button
                            style={
                              {
                                // float: "right"
                              }
                            }
                            onClick={() => {
                              setDeskBook(
                                descBook.filter((v, i) => i !== index)
                              );
                            }}
                            variant="contained"
                            color="primary"
                          >
                            <Remove />
                          </Button>
                        </div>
                      </Grid>
                    </Grid>
                  </>
                )}
              </Box>
            )}
          </>
        );
      })}
      <div>
        <Button
          onClick={() => {
            let newKilas = [
              ...descBook,
              {
                kilas: descBook.length + 1,
                title: "",
                details: [""]
              }
            ];

            setDeskBook(newKilas);
          }}
          variant="contained"
          color="primary"
        >
          Tambah Kilas
        </Button>
      </div>

      <Button
        onClick={() => {
          submitData && submitData(descBook);
        }}
        variant="contained"
        color="primary"
      >
        Edit Kilas
      </Button>
    </form>
  );
}
