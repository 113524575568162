import React, { useState, useEffect } from "react";
import { useHistory, useParams, Redirect } from "react-router-dom";

// Whatsapp Button
import MultiUseMobile from "../../styles/MultiUseMobile";

// Custom components
import Footer from "../../components/Footer";
import Header from "../../components/NavBar/Header";
import HeaderLinks from "../../components/NavBar/HeaderLinks";
import HeaderLinksMobile from "../../components/NavBar/HeaderLinksMobile";
import Loading from "../Utilities/Loading";
import { beigeColor } from "../../styles/Style";
import BookCard from "../../components/Cards/BookCard";
import Typography from "../../components/Typography";
import CombiningTextWithUnderline from "../../components/CombiningTextWithUnderline";
import ComingSoonCard from "../../components/Cards/ComingSoonCard";

// Firebase components
import fire from "../../firebase/fire";

// Material-UI components
import { Container, Grid } from "@material-ui/core";
import { ContextReact } from "../../context";
import { setBook } from "../../context/action";

// Images
const Whatsapp =
  "https://firebasestorage.googleapis.com/v0/b/sekilasaja-999fd.appspot.com/o/Website_Images%2FWeb_Picture_Components%2FWhatsapp.png?alt=media&token=88483bb9-b9d3-4aa8-9f14-9b7f91682861";

export default function ShowAllByCategory() {
  // router
  const history = useHistory();
  const params = useParams();
  const multi = MultiUseMobile();

  // Auth
  const db = fire.firestore();

  // useState Hooks
  const [pending, setPending] = useState(true);

  const context = React.useContext(ContextReact);
  const { data } = context;

  useEffect(() => {
    // Get books
    db.collection("books").onSnapshot((snapshot) => {
      context.dispatch(
        setBook(
          snapshot.docs.map((doc) => ({
            ...doc.data(),
          }))
        )
      );
    });
  }, []);

  const filterBook = (category) => {
    const isArray = Array.isArray(data.book);
    if (isArray) {
      return data.book.filter((data) => data.category.includes(category));
    } else {
      return false;
    }
  };

  const resFilter = filterBook(params.category_name);

  if (resFilter.length == 0) return <Redirect to="/404page" />;

  if (data.book && pending) return setPending(false);

  if (pending) {
    return (
      <>
        <Loading />
      </>
    );
  }

  return (
    <div style={{ backgroundColor: beigeColor }}>
      <div style={{ marginTop: "100px" }} />
      <Header
        history={history}
        rightLinks={<HeaderLinks history={history} />}
        rightLinksMobile={<HeaderLinksMobile history={history} />}
        fixed
        color="white"
      />
      <Container>
        <Typography style={{ textAlign: "center" }} size="heading">
          <CombiningTextWithUnderline
            text={{ underline: params.category_name }}
            position="back"
          />
        </Typography>
        <Grid container justifyContent="center">
          {params.category_name === "Coming Soon!" ? (
            <>
              {Array.isArray(resFilter) &&
                resFilter.map((data, index) => {
                  return (
                    <ComingSoonCard
                      isTimeHidden={true}
                      chosenCategory={"All"}
                      coverTitle={data.book_title}
                      key={index}
                      product={data}
                    />
                  );
                })}
            </>
          ) : (
            <>
              {Array.isArray(resFilter) &&
                resFilter.map((data, index) => {
                  return (
                    <BookCard
                      isNotPremium={data.video_link === "" ? true : false}
                      chosenCategory={"All"}
                      coverTitle={data.book_title}
                      key={index}
                      product={data}
                    />
                  );
                })}
            </>
          )}
        </Grid>
      </Container>

      {/*---------------------------------------------------------------*/}
      {/*---------------------- WHATSAPP FIXED NAV ---------------------*/}
      {/*---------------------------------------------------------------*/}
      <a href="https://wa.me/6281291176795" target="_blank">
        <div className={multi.whatsapp}>
          <Grid container spacing={3}>
            <Grid item xs={4}>
              <img
                style={{
                  width: "60px",
                }}
                src={Whatsapp}
              />
            </Grid>

            <Grid item xs={4}>
              <Typography type="bold">Butuh Bantuan?</Typography>
            </Grid>
          </Grid>
        </div>
      </a>
      <Footer />
    </div>
  );
}
