import React from "react";

// Custom components
import Typography from "../../../components/Typography";
import Button from "../../../components/Button";
import MultiUseMobile from "../../../styles/MultiUseMobile";

// Material UI components
import { Grid } from "@material-ui/core";

// Images
const IconBisnis =
  "https://firebasestorage.googleapis.com/v0/b/sekilasaja-999fd.appspot.com/o/Website_Images%2FWeb_Picture_Components%2Ficon-bisnis.png?alt=media&token=7ac0d576-20b2-4092-9881-70783d96849e";
const IconPengembangan =
  "https://firebasestorage.googleapis.com/v0/b/sekilasaja-999fd.appspot.com/o/Website_Images%2FWeb_Picture_Components%2Ficon-pengembangan.png?alt=media&token=6102f7d9-f7a2-4542-a935-9f7db48b59b3";
const IconBiografi =
  "https://firebasestorage.googleapis.com/v0/b/sekilasaja-999fd.appspot.com/o/Website_Images%2FWeb_Picture_Components%2Ficon-biografi.png?alt=media&token=7504e544-ec4f-4537-b13d-3db9c9efa035";
const IconKesehatan =
  "https://firebasestorage.googleapis.com/v0/b/sekilasaja-999fd.appspot.com/o/Website_Images%2FWeb_Picture_Components%2Ficon-kesehatan.png?alt=media&token=041e0666-6e2e-4d81-a7f2-c186e97dd358";

export default function CategoryNavigate({}) {
  // Styles
  const multi = MultiUseMobile();

  return (
    <Grid container justifyContent="center" alignItems="center" spacing={3}>
      <Grid item xs={12} md={6}>
        <img src={IconBisnis} className={multi.picSize} />
        <Typography type="bold">Bisnis dan Investasi</Typography>
        <Typography>
          Bisnis dan Investasi merupakan kedua cara yang sangat efektif untuk
          mengembangkan uangmu.
        </Typography>
        <br />
        <Button round href="/show-by-category/Bisnis & Investasi">
          Eksplor →
        </Button>
      </Grid>
      <Grid item xs={12} md={6}>
        <img src={IconPengembangan} className={multi.picSize} />
        <Typography type="bold">Pengembangan Diri</Typography>
        <Typography>
          Berkembang 1% setiap hari selama satu tahun akan membuatmu 37 kali
          lebih baik dari sebelumnya.
        </Typography>
        <br />
        <Button round href="/show-by-category/Pengembangan Diri">
          Eksplor →
        </Button>
      </Grid>
      <Grid item xs={12} md={6}>
        <img src={IconBiografi} className={multi.picSize} />
        <Typography type="bold">Biografi</Typography>
        <Typography>
          Pengalaman adalah guru terbaik. Belajar dari kesuksesan orang-orang
          terkaya dan tersukses di dunia!
        </Typography>
        <br />
        <Button round href="/show-by-category/Biografi">
          Eksplor →
        </Button>
      </Grid>
      <Grid item xs={12} md={6}>
        <img src={IconKesehatan} className={multi.picSize} />
        <Typography type="bold">Kesehatan & Olahraga</Typography>
        <Typography>
          Kesehatan merupakan hal yang sangat penting bagi semua orang. Cari
          tahu cara yang tepat untuk terus hidup sehat!
        </Typography>
        <br />
        <Button round href="/show-by-category/Kesehatan & Olahraga">
          Eksplor →
        </Button>
      </Grid>
    </Grid>
  );
}
