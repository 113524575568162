import React from "react";

// @material-ui/core components
import { Grid, Box, makeStyles } from "@material-ui/core";

// Custom components
import Typography from "../../components/Typography";
import Button from "../../components/Button";
import CombiningTextWithUnderline from "../../components/CombiningTextWithUnderline";
import InfoAreaStyle from "../../styles/InfoAreaStyle";

// Images
const introBlockImages =
  "https://firebasestorage.googleapis.com/v0/b/sekilasaja-999fd.appspot.com/o/Website_Images%2FWeb_Picture_Components%2Fmentor-page.png?alt=media&token=c68f2677-b814-4a08-a62f-1f774de840c7";

// Styles
const useStyles = makeStyles(InfoAreaStyle);

export function IntroBlock({ history, isSubscribed, currentUser }) {
  const classes = useStyles();

  return (
    <Grid container alignItems="center" justifyContent="center" spacing={3}>
      <Box clone order={{ xs: 2, md: 1 }}>
        <Grid item md={6} xs={12}>
          <Typography size="heading">
            Belajar & Interaksi Langsung Dengan{" "}
            <CombiningTextWithUnderline
              text={{ underline: "Mentor-Mentor Hebat" }}
              position="back"
            />
          </Typography>

          <Typography>
            Punya pertanyaan yang belum dijawab? Dapatkan kesempatan untuk{" "}
            <strong>bertanya kepada orang-orang hebat</strong> di sini!
          </Typography>

          {/* {isSubscribed && currentUser ? (
            <></>
          ) : (
            <Button
              onClick={() => {
                history.push("/pricing");
                window.scrollTo(0, 0);
              }}
              round
            >
              Berlanggan Sekarang!
            </Button>
          )} */}
        </Grid>
      </Box>

      <Box clone order={{ xs: 1, md: 2 }}>
        <Grid item md={6} xs={12}>
          <img src={introBlockImages} className={classes.imgMentor} />
        </Grid>
      </Box>
    </Grid>
  );
}
