import React, { useContext } from "react";

// Whatsapp Button
import MultiUseMobile from "../../styles/MultiUseMobile";

// Custom components
import Typography from "../../components/Typography";
import Footer from "../../components/Footer";
import Header from "../../components/NavBar/Header";
import HeaderLinks from "../../components/NavBar/HeaderLinks";
import HeaderLinksMobile from "../../components/NavBar/HeaderLinksMobile";
import Button from "../../components/Button";
import { beigeColor, primaryColor, secondaryColor } from "../../styles/Style";
import PricingStyles from "../../styles/PricingStyle";
import TestimonialBlockTwo from "./TestimonialBlock/TestimonialBlockTwo";
import Video from "../KilasPage/Video";
import TextReadingStyle from "../../styles/TextReadingStyle";
import BooksBlock from "./CategoryBlock/BooksBlock";

// Redux & Auth
import { useSelector } from "react-redux";
import { selectUser } from "../../feature/userSlice";
import { AuthContext } from "../../components/Routing/Auth";
// import { AuthenticationArea } from "../../utils";

// Context
import { ContextReact } from "../../context";
// import { setModalAuth, setRedirectTo } from "./../../context/action";

// Material-UI components
import {
  Container,
  Grid,
  Card,
  CardHeader,
  CardContent,
} from "@material-ui/core";
import CategoryNavigate from "./CategoryBlock/CategoryNavigate";

// Images
import Testimoni1 from "../../assets/image/testimoni1.png";
import Testimoni2 from "../../assets/image/testimoni2.png";

const Whatsapp =
  "https://firebasestorage.googleapis.com/v0/b/sekilasaja-999fd.appspot.com/o/Website_Images%2FWeb_Picture_Components%2FWhatsapp.png?alt=media&token=88483bb9-b9d3-4aa8-9f14-9b7f91682861";
const ProblemRead =
  "https://firebasestorage.googleapis.com/v0/b/sekilasaja-999fd.appspot.com/o/Website_Images%2FWeb_Picture_Components%2Fproblem-read.png?alt=media&token=3396eded-3f1f-4990-8383-87489b7c6c37";
const ProblemTime =
  "https://firebasestorage.googleapis.com/v0/b/sekilasaja-999fd.appspot.com/o/Website_Images%2FWeb_Picture_Components%2Fproblem-time.png?alt=media&token=cb17e855-9c03-4535-b883-86880bdd3afa";
const ProblemEnglish =
  "https://firebasestorage.googleapis.com/v0/b/sekilasaja-999fd.appspot.com/o/Website_Images%2FWeb_Picture_Components%2Fproblem-english.png?alt=media&token=f5bb47d2-1788-439f-9fde-7cbdb6043f62";
const ProductArticle =
  "https://firebasestorage.googleapis.com/v0/b/sekilasaja-999fd.appspot.com/o/Website_Images%2FWeb_Picture_Components%2Fproduct-article.png?alt=media&token=1e2a7e44-1a01-4fb6-814c-81f49187dd2a";
const ProductVideo =
  "https://firebasestorage.googleapis.com/v0/b/sekilasaja-999fd.appspot.com/o/Website_Images%2FWeb_Picture_Components%2Fproduct-video.png?alt=media&token=e1fdf1db-3b02-40d1-8dd8-e0e2fb705a49";
const ProductRangkuman =
  "https://firebasestorage.googleapis.com/v0/b/sekilasaja-999fd.appspot.com/o/Website_Images%2FWeb_Picture_Components%2Fproduct-rangkuman.png?alt=media&token=0bd9c38b-2518-431c-bea4-0a913bc7de1f";
const ProductMentoring =
  "https://firebasestorage.googleapis.com/v0/b/sekilasaja-999fd.appspot.com/o/Website_Images%2FWeb_Picture_Components%2Fproduct-mentoring.png?alt=media&token=e31d8b87-725d-4da7-be0d-5ae7eb866240";
const ProductAudio =
  "https://firebasestorage.googleapis.com/v0/b/sekilasaja-999fd.appspot.com/o/Website_Images%2FWeb_Picture_Components%2Fproduct-audio.png?alt=media&token=3ca37ce5-49f5-4215-82e1-12a8bb9fa0db";
const ProductKomunitas =
  "https://firebasestorage.googleapis.com/v0/b/sekilasaja-999fd.appspot.com/o/Website_Images%2FWeb_Picture_Components%2Fproduct-komunitas.png?alt=media&token=82f4f823-73fa-4416-b745-37f47dd6b5d4";

export default function ProductLanding({ history }) {
  // Styles
  const multi = MultiUseMobile();
  const classes = PricingStyles();
  const video = TextReadingStyle();

  // Auth
  const userData = useSelector(selectUser);
  const { currentUser } = useContext(AuthContext);

  // Context
  const context = useContext(ContextReact);
  const { dispatch } = context;

  return (
    <Container maxWidth={"md"} style={{ textAlign: "center" }}>
      <Typography
        style={{ fontWeight: 400, marginBottom: 0 }}
        size="subheading"
      >
        Jadi VERSI TERBAIK dirimu dengan
      </Typography>
      <Typography style={{ marginTop: 0 }} size="heading">
        Menyelesaikan buku terbaik dunia hanya dalam 15 MENIT!
      </Typography>

      <div
        style={{
          padding: 10,
          border: "1px solid #41444b",
          backgroundColor: primaryColor,
          borderRadius: 5,
        }}
      >
        <Typography>
          Belajar bersama <strong>7518++ USER LAINNYA</strong>, dan gabung di
          dalam <strong>KOMUNITAS EKSKLUSIF!</strong>
        </Typography>
      </div>

      <br />

      <Typography size="heading">Pengen Sukses Dan Berwawasan Luas?</Typography>
      <Grid container justifyContent="center" alignItems="center" spacing={3}>
        <Grid item xs={12} md={6}>
          <img src={ProblemRead} className={multi.picSize} />
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography size="subheading">Tapi..</Typography>
          <Typography>
            ❌ <strong>BINGUNG</strong> mau belajar apa dan di mana.
          </Typography>
          <Typography>
            ❌ <strong>NGGAK ADA MODAL</strong> untuk belajar.
          </Typography>
          <Typography>
            ❌ <strong>MOTIVASI BELAJAR</strong> nggak ada.
          </Typography>
          <Typography>
            ❌ <strong>MAU BELI KELAS ONLINE</strong> tapi mahal banget.
          </Typography>
          <Typography>
            ❌ <strong>MAU IKUT WEBINAR</strong> mahal juga.
          </Typography>
        </Grid>
      </Grid>

      <br />

      <Typography size="heading">
        Pengen Belajar Tapi Nggak Ada Waktu?
      </Typography>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        spacing={3}
        direction="row-reverse"
      >
        <Grid item xs={12} md={6}>
          <img src={ProblemTime} className={multi.picSize} />
        </Grid>

        <Grid item xs={12} md={6}>
          <Typography size="subheading">Karena..</Typography>
          <Typography>
            ❌ <strong>KULIAH</strong> sibuk banyak tugas.
          </Typography>
          <Typography>
            ❌ <strong>KERJA KANTORAN</strong> pergi pagi pulang sore.
          </Typography>
          <Typography>
            ❌ <strong>SIBUK NGURUSIN RUMAH TANGGA</strong> siang dan malam.
          </Typography>
          <Typography>
            ❌ <strong>SIBUK MERINTIS</strong> usaha baru.
          </Typography>
          <Typography>
            ❌ <strong>SUKA REBAHAN</strong> scrolling Instagram.
          </Typography>
        </Grid>
      </Grid>

      <br />

      <Typography size="heading">Mau Baca Buku Tapi Males?</Typography>
      <Grid container justifyContent="center" alignItems="center" spacing={3}>
        <Grid item xs={12} md={6}>
          <img src={ProblemEnglish} className={multi.picSize} />
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography size="subheading">Soalnya, kalo Beli Buku..</Typography>
          <Typography>
            ❌ <strong>MAHAL!</strong> harganya 100-200 ribu per bukunya!
          </Typography>
          <Typography>
            ❌ <strong>HABIS BELI TAKUT</strong> bukunya jelek atau nggak
            dibaca.
          </Typography>
          <Typography>
            ❌ <strong>TEBEL BANGET</strong> butuh waktu lama untuk diabisin.
          </Typography>
          <Typography>
            ❌ <strong>KATA-KATANYA SUSAH</strong> dimengerti.
          </Typography>
          <Typography>
            ❌ <strong>NGGAK NGERTI BAHASA INGGRIS!</strong> banyak buku bagus
            dalam bahasa inggris.
          </Typography>
        </Grid>
      </Grid>

      <br />

      <Typography size="heading">Tenang! Kita Punya Solusinya.</Typography>
      <div
        style={{
          border: "2px solid #41444b",
          borderRadius: 30,
          padding: 20,
        }}
        elevation={0}
      >
        <Typography size="subheading">
          Kami sudah menyiapkan ini semua untuk kamu:
        </Typography>

        <Grid container justifyContent="center" spacing={5}>
          <Grid item xs={12} md={6}>
            <img src={ProductRangkuman} className={multi.picSize} />
            <Typography size="subheading">
              ✅ 100+ RANGKUMAN BUKU
            </Typography>{" "}
            <Typography>
              Kami sudah merangkum 100+ buku bisnis, investasi, pengembangan
              diri, dan berbagai kategori lainnya terbaik dunia hanya dalam 15
              menit!
            </Typography>
            <Typography type="bold">Rp. 8.799.000</Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <img src={ProductVideo} className={multi.picSize} />
            <Typography size="subheading">✅ 50+ VIDEO</Typography>{" "}
            <Typography>
              Buat kamu yang visual learner, kami juga menyediakan versi video
              di buku top seller dunia!
            </Typography>
            <Typography type="bold">Rp. 5.000.000</Typography>
          </Grid>

          <Grid item xs={12} md={6}>
            <img src={ProductAudio} className={multi.picSize} />
            <Typography size="subheading">✅ 100+ AUDIO</Typography>{" "}
            <Typography>
              Dengan 100+ audio, kamu bisa habiskan satu buku samabil nyetir,
              nge-gym, masak, lari, ngapain aja deh pokoknya!
            </Typography>
            <Typography type="bold">Rp. 2.000.000</Typography>
          </Grid>
          {/* <Grid item xs={12} md={6}>
            <img src={ProductMentoring} className={multi.picSize} />
            <Typography size="subheading">✅ GROUP MENTORING</Typography>
            <Typography>
              Group mentoring secara LIVE dan bisa tanya jawab langsung bersama
              orang-orang hebat. Rekamannya juga disimpen kok kalo kamu nggak
              bisa dateng!
            </Typography>
            <Typography type="bold">Rp. 3.000.000</Typography>
          </Grid> */}

          <Grid item xs={12} md={6}>
            <img src={ProductKomunitas} className={multi.picSize} />
            <Typography size="subheading">
              ✅ KOMUNITAS EKSKLUSIF
            </Typography>{" "}
            <Typography>
              Males belajar? Ada komunitas eksklusif yang bisa mendorong
              motivasi kamu setiap hari. Kesuksesan dipengaruhi oleh dengan
              siapa kamu bergaul!
            </Typography>
            <Typography type="bold">Rp. 1.000.000</Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <img src={ProductArticle} className={multi.picSize} />
            <Typography size="subheading">
              ✅ 50+ ARTIKEL PEMBELAJARAN
            </Typography>
            <Typography>
              Selain baca buku, terdapat berbagai artikel yang bisa menambah
              wawasan kamu juga!
            </Typography>
            <Typography type="bold">Rp. 500.000</Typography>
          </Grid>

          <Grid item xs={12}>
            <Typography
              style={{
                marginTop: 0,
                textDecoration: "line-through",
                textDecorationColor: "red",
              }}
              size="heading"
            >
              GRAND TOTAL RP. 17.299.000
            </Typography>
            <Typography style={{ marginBottom: 0 }} size="heading">
              HARGA SPESIAL
            </Typography>
            <Typography size="subheading">
              <strong style={{ backgroundColor: secondaryColor, color: primaryColor, padding: 5, borderRadius: 10 }}>
                Harga bisa naik sewaktu-waktu!
              </strong>
            </Typography>
            <Typography style={{ marginTop: 0 }} size="heading" type="bold">
              <strong
                style={{
                  backgroundColor: secondaryColor,
                  color: primaryColor,
                  padding: 5,
                  borderRadius: 10,
                }}
              >
                Rp. 448.000/12 Bulan
              </strong>
            </Typography>
            <Button
              onClick={
                !!currentUser
                  ? () => {
                    history.push("/payment-twelve");
                    window.scrollTo(0, 0);
                  }
                  : () => {
                    history.push("/payment-twelve-new-user");
                    window.scrollTo(0, 0);
                  }
              }
              round
            >
              Berlanggan Sekarang!
            </Button>
          </Grid>
        </Grid>
      </div>

      {/* <br />

      <Typography size="heading">Contoh Video Kami!</Typography>
      <div className={video.containerVideo}>
        <Video
          className={video.iframe + " sproutvideo-player"}
          src={
            "https://videos.sproutvideo.com/embed/069dd8b0181fe6c08f/54cbce85df89c93d"
          }
        />
      </div>

      <br />

      <Typography size="heading">Berbagai Kategori!</Typography>
      <CategoryNavigate />

      <br />

      <Typography size="heading">Ratusan Judul Buku!</Typography>
      <BooksBlock /> */}

      <br />

      <Typography size="heading">Testimoni Member</Typography>
      <img style={{ width: "100%" }} src={Testimoni1} />
      <img style={{ width: "100%" }} src={Testimoni2} />

      <br />

      <Typography size="heading">Lihat Juga Apa Kata Mereka...</Typography>
      <TestimonialBlockTwo />

      <br />
    </Container>
  );
}
