import fire from "./fire";
import { getRandomInt, isLOgin } from "../utils/index";
const fireComment = fire.firestore().collection("comment");

export const getListComment = async (data) => {
  const { book_title } = data;
  return await fireComment
    .doc(book_title)
    .get()
    .then((res) => {
      if (res.data()) {
        return Object.values(res.data()).length;
      } else {
        return 0;
      }
    });
};

export const createComment = async (data) => {
  const { book_title, value, id } = data;
  if (value) {
    await fireComment.doc(book_title).set(
      {
        [id]: {
          name: isLOgin().firstName,
          text: value,
          date: new Date(),
          id,
          email: isLOgin().email
        }
      },
      {
        merge: true
      }
    );
  }
};

export const newDeleteComment = async (data) => {
  const { id, book_title } = data;
  await fireComment
    .doc(book_title)
    .get()
    .then((res) => {
      if (typeof res.data === "function") {
        const asArray = Object.entries(res.data());

        const filtered = asArray.filter(([key, value]) => value.id !== id);
        const justStrings = Object.fromEntries(filtered);

        fireComment.doc(book_title).set(justStrings);
      } else {
      }
    });
};

export const createSubCOmment = async (data) => {
  const { dataReply, book_title, idComment } = data;
  await fireComment.doc(book_title).set(
    {
      [idComment]: dataReply
    },
    { merge: true }
  );
};

export const newDeleteSubComment = async (data) => {
  const { book_title, idComment, name, text, date, idSubComment } = data;
  await fireComment
    .doc(book_title)
    .get()
    .then((res) => {
      const asArray = Object.entries(res.data()[idComment].reply);

      const filtered = asArray.filter(
        ([key, value]) => value.id !== idSubComment
      );
      let dataResponse = res.data();

      delete dataResponse[idComment].reply[idSubComment];

      fireComment
        .doc(book_title)
        .set(dataResponse)
        .then((res) => {
          console.log("response", res);
        })
        .catch((err) => {
          console.log("error", err);
        });
    });
};

export const fireHybridLikeDislikeComment = (data, update) => {
  const { book_title, id, type, likeDislike } = data;
  const total = likeDislike
    ? likeDislike[type]
      ? likeDislike[type].total
      : 0
    : 0;
  const generateId = getRandomInt();
  const dataToSet = {
    [id]: {
      likeDislike: {
        [type]: {
          total: total + 1,
          user: { [generateId]: { email: data.email, id: generateId } }
        }
      }
    }
  };
  fireComment
    .doc(book_title)
    .set(dataToSet, { merge: true })
    .then((res) => {
      update && update();
      fireToggleLikeDislikeComment(data, update, true);
    });
};

export const fireToggleLikeDislikeComment = (data, update, switchs) => {
  const { book_title, id, type, email } = data;

  fireComment
    .doc(book_title)
    .get()
    .then((res) => {
      let response = res.data();
      if (switchs) {
        const newType = type === "like" ? "dislike" : "like";
        const findSwitch = data.likeDislike
          ? data.likeDislike[newType]
            ? Object.values(data.likeDislike[newType].user).find(
                (data) => data.email === isLOgin().email
              )
            : false
          : false;
        const result =
          findSwitch &&
          response[id].likeDislike[newType].user[findSwitch && findSwitch.id];
        if (result) {
          delete response[id].likeDislike[newType].user[findSwitch.id];
          if (response[id].likeDislike[newType].total > 0) {
            response[id].likeDislike[newType].total -= 1;
          }
        }
      } else {
        const find = Object.values(data.likeDislike[type].user).find(
          (data) => data.email === email
        ).id;
        delete response[id].likeDislike[type].user[find];
        if (response[id].likeDislike[type].total > 0) {
          response[id].likeDislike[type].total -= 1;
        }
      }

      fireComment
        .doc(book_title)
        .set(response)
        .then((res) => {
          update && update();
        });
    });
};

export const fireHybridLikeDislikeSubComment = (data, update) => {
  const { dataComment, dataSubComment, type } = data;
  const total = dataSubComment.likeDislike
    ? dataSubComment.likeDislike[type]
      ? dataSubComment.likeDislike[type].total
      : 0
    : 0;

  const totalLike = total + 1;
  const idLike = getRandomInt();

  const dataToSet = {
    [dataComment.id]: {
      reply: {
        [dataSubComment.id]: {
          likeDislike: {
            [type]: {
              total: totalLike,
              user: { [idLike]: { email: data.email, id: idLike } }
            }
          }
        }
      }
    }
  };
  fireComment
    .doc(data.book_title)
    .set(dataToSet, { merge: true })
    .then((res) => {
      update && update();
      fireToggleLikeSubComment(data, update, true);
    });
};

export const fireToggleLikeSubComment = (data, update, switchs) => {
  const { dataComment, dataSubComment, type, idLike } = data;
  fireComment
    .doc(data.book_title)
    .get()
    .then((res) => {
      var response = res.data();

      if (switchs) {
        let newId =
          response[dataComment.id].reply[dataSubComment.id].likeDislike[
            type === "like" ? "dislike" : "like"
          ];
        let arr = [];
        if (newId) {
          arr = Object.values(newId.user);
        }
        let find = arr.find((data) => data.email === isLOgin().email);
        if (find) {
          let objToDelete = find.id;
          delete response[dataComment.id].reply[dataSubComment.id].likeDislike[
            type === "like" ? "dislike" : "like"
          ].user[objToDelete];
          if (
            response[dataComment.id].reply[dataSubComment.id].likeDislike[
              type === "like" ? "dislike" : "like"
            ].total > 0
          ) {
            response[dataComment.id].reply[dataSubComment.id].likeDislike[
              type === "like" ? "dislike" : "like"
            ].total -= 1;
          }
        }
      } else {
        delete response[dataComment.id].reply[dataSubComment.id].likeDislike[
          type
        ].user[idLike.id];
        if (
          response[dataComment.id].reply[dataSubComment.id].likeDislike[type]
            .total > 0
        ) {
          response[dataComment.id].reply[dataSubComment.id].likeDislike[
            type
          ].total -= 1;
        }
      }

      fireComment
        .doc(data.book_title)
        .set(response)
        .then((res) => {
          update && update();
        });
    })
    .then((res) => {
      update && update();
    });
};
