import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

// Whatsapp Button
import MultiUseMobile from "../../styles/MultiUseMobile";

// momentjs
import moment from "moment";

// Custom components
import Typography from "../../components/Typography";
import Footer from "../../components/Footer";
import Header from "../../components/NavBar/Header";
import HeaderLinks from "../../components/NavBar/HeaderLinks";
import HeaderLinksMobile from "../../components/NavBar/HeaderLinksMobile";
import PaginationBlog from "./component/PaginationBlog";
import Loading from "../Utilities/Loading";
import InfoStyle from "../../styles/InfoAreaStyle";
import CombiningTextWithUnderline from "../../components/CombiningTextWithUnderline";

// Material-UI components
import { Container, Grid, Paper, makeStyles } from "@material-ui/core";

// Sanity
import client from "../../sekilasajablog/client";

// Images
const Whatsapp =
  "https://firebasestorage.googleapis.com/v0/b/sekilasaja-999fd.appspot.com/o/Website_Images%2FWeb_Picture_Components%2FWhatsapp.png?alt=media&token=88483bb9-b9d3-4aa8-9f14-9b7f91682861";

// Styles
const useStyles = makeStyles(InfoStyle);

export default function BlogPage({ history }) {
  // Styles
  const card = useStyles();
  const multi = MultiUseMobile();

  // useStates
  const [posts, setPosts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    client
      .fetch(
        `*[_type == "post"] {
          title,
          slug,
          body,
          mainImage {
              asset -> {
                  _id,
                  url
              },
              alt
          }, 
          releaseDate, 
          "name": author->name
      }`
      )
      .then((data) => {
        setPosts(data);
        setIsLoading(false);
      })
      .catch(console.error);
  }, []);

  // Pagination
  const [page, setPage] = React.useState(1);
  const newPost = [...posts];
  const showData = {
    start: page * 9 - 9,
    end: page * 9,
  };
  const { start, end } = showData;

  if (isLoading) {
    return <Loading />;
  }

  return (
    <div>
      <div style={{ marginTop: "100px" }} />
      <Header
        history={history}
        rightLinks={<HeaderLinks history={history} />}
        rightLinksMobile={<HeaderLinksMobile history={history} />}
        fixed
        color="white"
      />

      <Container maxWidth="md">
        <Typography style={{ textAlign: "center" }} size="heading">
          <CombiningTextWithUnderline
            text={{ underline: "Artikel Pembelajaran SekilasAja!" }}
            position="back"
          />
        </Typography>

        <Grid container direction="row" justifyContent="flex-start" spacing={3}>
          {newPost
            .sort((a, b) => new Date(b.releaseDate) - new Date(a.releaseDate))
            .slice(start, end)

            .map((post) => (
              <Grid item md={4} xs={12} key={post.slug.current}>
                <Paper square className={card.cardHover}>
                  <Link
                    to={`/artikel/${post.slug.current}`}
                    style={{
                      textDecoration: "none",
                      color: "black",
                      "&:hover": {
                        filter: "brightness(150%)",
                      },
                    }}
                    onClick={() => {
                      window.scrollTo(0, 0);
                    }}
                  >
                    <div>
                      <img
                        style={{
                          width: "100%",
                          height: 180,
                          objectFit: "cover",
                        }}
                        src={post.mainImage.asset.url}
                        alt={post.title}
                      />
                    </div>
                    <div style={{ padding: 10 }}>
                      <Typography
                        className={card.lineClamp}
                        type="bold"
                        style={{ margin: "0px" }}
                      >
                        {post.title}
                      </Typography>
                      <Typography
                        style={{ fontSize: 14 }}
                        className={card.lineClampAuthor}
                      >
                        Penulis Konten <b>{post.name}</b>
                      </Typography>
                      <Typography
                        className={card.lineClampAuthor}
                        style={{ marginTop: 0 }}
                        size="small"
                        type="italic"
                      >
                        {moment(post.releaseDate).format("LL")}
                      </Typography>
                    </div>
                  </Link>
                </Paper>
              </Grid>
            ))}
        </Grid>

        <br />
        <div style={{ margin: "auto", width: "max-content" }}>
          <PaginationBlog
            setPage={setPage}
            count={Math.ceil(newPost.length / 9)}
          />
        </div>
      </Container>

      {/*---------------------------------------------------------------*/}
      {/*---------------------- WHATSAPP FIXED NAV ---------------------*/}
      {/*---------------------------------------------------------------*/}
      <a href="https://wa.me/6281291176795" target="_blank">
        <div className={multi.whatsapp}>
          <Grid container spacing={3}>
            <Grid item xs={4}>
              <img
                style={{
                  width: "60px",
                }}
                src={Whatsapp}
              />
            </Grid>

            <Grid item xs={4}>
              <Typography type="bold">Butuh Bantuan?</Typography>
            </Grid>
          </Grid>
        </div>
      </a>
      <Footer />
    </div>
  );
}
