import fire from "./fire";

export const getDetailUser = async (email) => {
  const fetchUser = await fire
    .firestore()
    .collection("users")
    .where("email", "==", email)
    .get();
  let result = {};

  fetchUser.docs.map((data) => {
    const { id } = data;

    result = { ...data.data(), uid: id, id };
  });
  return result;
};

export const updateUser = (email, data) => {
  return new Promise(async (resolve, reject) => {
    try {
      await fire
        .firestore()
        .collection("users")
        .where("email", "==", email)
        .get()
        .then((resGetuser) => {
          fire
            .firestore()
            .collection("users")
            .doc(resGetuser.docs[0].id)
            .update(data)
            .then((res) => {
              resolve({
                status: "success",
                isSuccess: true,
                isFailed: false,
                data: {
                  ...resGetuser.docs[0].data,
                  id: resGetuser.docs[0].id
                }
              });
            })
            .catch((err) => {
              var errorCode = err.code;
              var errorMessage = err.message;
              reject({
                status: "failed",
                isSuccess: false,
                isFailed: true,
                message: `update user failed ${errorCode || ""} ${
                  errorMessage || ""
                }`,
                data,
                error: err
              });
            });
        });
    } catch (err) {
      reject({
        status: "failed",
        isSuccess: false,
        isFailed: true,
        message: "update user failed",
        data,
        error: err
      });
    }
  });
};

export const newUpdateUser = (id, data) => {
  return new Promise(async (resolve, reject) => {
    try {
      await fire
        .firestore()
        .collection("users")
        .doc(id)
        .get()
        .then((resGetuser) => {
          fire
            .firestore()
            .collection("users")
            .doc(resGetuser.id)
            .update(data)
            .then((res) => {
              resolve({
                status: "success",
                isSuccess: true,
                isFailed: false,
                data: {
                  ...resGetuser.data,
                  id: resGetuser.id
                }
              });
            })
            .catch((err) => {});
        });
    } catch (err) {
      reject({
        status: "failed",
        isSuccess: false,
        isFailed: true,
        message: "update user failed",
        data,
        error: err
      });
    }
  });
};

export const checkUserSubscribe = (email) => {
  return new Promise(async (resolve, reject) => {
    try {
      const fetchUser = await fire
        .firestore()
        .collection("users")
        .where("email", "==", email)
        .get();
      let result = {};
      const { is_subscribed, id_incoive } = fetchUser.docs[0].data();
      result = is_subscribed;
      if (id_incoive) {
        resolve({
          data: {
            is_subscribed,
            id_incoive,
            dataUser: fetchUser.docs[0].data()
          },
          message: "success",
          status: 200
        });
      } else {
        reject({
          data: false,
          message: "tagihan tidak ditemukan",
          status: 400
        });
      }
    } catch {
      reject({
        data: false,
        message: "email tidak terdaftar",
        status: 400
      });
    }
  });
};
