import Typography from "../components/Typography";
import fire from "../firebase/fire";
import crypto from "crypto-js";
import moment from "moment";
const firestore = fire.firestore();

// add 0 if number <10
export const revisiNumber = (number) => {
  if (number) {
    if (number < 10) {
      return "0" + number;
    } else {
      return number;
    }
  } else {
    return "00";
  }
};

export const countingDown = ({ setHours, releaseDate, bookTitle }) => {
  var countDownDate = releaseDate
    ? new Date(releaseDate).getTime()
    : new Date().getTime();
  let openIn = 0;
  // Update the count down every 1 second
  // Get today's date and time
  var now = new Date().getTime();

  // Find the distance between now and the count down date
  var distance = countDownDate - now;

  // Time calculations for days, hours, minutes and seconds
  var days = Math.floor(distance / (1000 * 60 * 60 * 24));
  var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));

  const newHours = days * 24 + hours;

  var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
  var seconds = Math.floor((distance % (1000 * 60)) / 1000);

  const countedTime = (
    <>
      {days}d {hours}h {minutes}m {seconds}s
    </>
  );
  // If the count down is finished, write some text
  if (distance < 0) {
    firestore
      .collection("books")
      .doc(bookTitle)
      .update({
        category: ["All", "New Release!"]
      });
    return (
      <Typography
        style={{
          marginTop: 0,
          fontSize: 18,
          color: newHours < 24 ? "red" : ""
        }}
      >
        00:00:00
      </Typography>
    );
  } else {
    setHours && setHours(newHours);
    return (
      <Typography
        style={{
          marginTop: 0,
          fontSize: 18,
          color: newHours < 24 ? "red" : ""
        }}
        type="bold"
      >
        {countedTime}
      </Typography>
    );
  }
};

export const { pathname } = window.location;
export const AuthenticationArea = () => {
  const area = ["/login", "/signup"];
  if (area.includes(pathname)) {
    return true;
  } else {
    return false;
  }
};

export const setHistoryReadKilas = (email, data) => {
  let action = fire
    .firestore()
    .collection("lastReadBook")
    .doc(email.toLowerCase());
  if (data.kilas < 1) {
    action.delete();
  } else {
    action.set({
      book: data
    });
  }
};

export function encrypt(pw) {
  var encrypted = crypto.AES.encrypt(pw, "p@sw0rd");
  return encrypted.toString();
}

export function decrypt(encrypted) {
  var bytes = crypto.AES.decrypt(encrypted, "p@sw0rd");
  var decrypted = bytes.toString(crypto.enc.Utf8);
  return decrypted;
}

export const isLOgin = () => {
  const getToken = localStorage.getItem("isLogedIn");

  if (getToken && getToken !== "true") {
    var bytes = crypto.AES.decrypt(getToken, "sekilasAjaSecret");
    var decrypted = bytes.toString(crypto.enc.Utf8);
    if (decrypted) {
      return JSON.parse(decrypted);
    } else {
      return false;
    }
  } else {
    return false;
  }
};

// cek array is true
export const cekArray = (arr) => {
  if (Array.isArray(arr) && arr.length > 0) {
    return true;
  } else {
    return false;
  }
};

export const dateFormatted = (date) => {
  return moment("12-25-1995", "MM-DD-YYYY");
};

export const getRandomInt = (max) => {
  return Math.floor(Math.random() * 9876787654342121234);
};
const kataKasar = [
  "Anjing",
  "Babi",
  "Monyet",
  "Kunyuk",
  "bajingan",
  "Asu",
  "Bangsat",
  "Perek",
  "Pecun",
  "Bencong",
  "Banci",
  "Jablay",
  "Bego",
  "Goblok",
  "Idiot",
  "Geblek",
  "Gila",
  "Sinting",
  "Tolol",
  "Sarap",
  "Buta",
  "Budek",
  "Bolot",
  "Jelek",
  "Setan",
  "Keparat",
  "Ngehe",
  "Bejad",
  "Gembel",
  "Brengsek",
  "Tai"
].map((txt) => txt.toLowerCase());
export const filterWords = (value) => {
  let newValue = [];
  const split = value.toLowerCase().split(" ");
  split.map((nv) => {
    if (kataKasar.includes(nv)) {
      // .replaceAll(nv, "*");
      const replace = kataKasar
        .find((v) => v === nv)
        .replaceAll(nv, "*".repeat(nv.length));

      newValue.push(replace);
    } else {
      newValue.push(nv);
    }
  });
  return newValue.join(" ");
};

export const strSubs = {
  bulan12: "Subscription 12 Bulan",
  bulan3: "Subscription 1 Bulan",
  bulan1: "Subscription 1 Bulan"
};

export const listSubscription = [
  "Subscription 12 Bulan",
  "Subscription 1 Bulan",
  "Subscription 1 Bulan"
];
