import React from "react";

// Custom components
import CombiningTextWithUnderline from "../../components/CombiningTextWithUnderline";
import MentorCard from "../../components/Cards/MentorCard";
import Typography from "../../components/Typography";
import InfoStyle from "../../styles/InfoAreaStyle";

// Material-UI components
import { Container, Grid, makeStyles } from "@material-ui/core";

// Images
const Logo =
  "https://firebasestorage.googleapis.com/v0/b/sekilasaja-999fd.appspot.com/o/Website_Images%2FWeb_Picture_Components%2Fdark-logo.png?alt=media&token=cfd7dc4d-1687-473e-a272-4d7c66b97467";

// Styles
const useStyles = makeStyles(InfoStyle);

export default function MentorBlock({
  history,
  mentor,
  currentUser,
  isSubscribed
}) {
  console.log("mentor", mentor);

  // Styles
  const books = useStyles();

  const newDataMentoring = () => {
    if (
      Array.isArray(mentor)

      // && typeof mentor[0].spesific_date.toDate === "function"
    ) {
      return mentor;
    } else {
      return mentor;
    }
  };

  return (
    <>
      <Typography style={{ textAlign: "center" }} size="heading">
        <CombiningTextWithUnderline
          text={{ underline: "Jadwal & Rekaman Ulang" }}
          position="back"
        />{" "}
        Group Mentoring
        <img
          src={Logo}
          style={{ marginBottom: -23 }}
          className={books.imgLogoText}
        />
      </Typography>

      <Grid container spacing={4}>
        {newDataMentoring().map((mentor) => (
          <MentorCard
            mentor={mentor}
            history={history}
            currentUser={currentUser}
            isSubscribed={isSubscribed}
          />
        ))}
      </Grid>
    </>
  );
}
