import React, { useEffect, useState } from "react";

// material-ui
import { Grid, Button, TextField } from "@material-ui/core";

import { Add } from "@material-ui/icons";

// project imports
import { gridSpacing } from "../../store/constant"; // done
import TableListBook from "./child/TableListBook";
import fire from "../../../firebase/fire";
import { ModalAddBook } from "./modalAction";

const BookDashboard = () => {
  const [dataBook, setDataBook] = useState(false);
  const [openModalAdd, setOpenModalAdd] = React.useState(false);
  const [search, setSearch] = React.useState(false);

  const getAllBook = () => {
    fire
      .firestore()
      .collection("books")
      .get()
      .then(async (res) => {
        const results = await res.docs.map((cb) => {
          return { ...cb.data(), id: cb.id };
        });
        setDataBook(results);
      });
  };

  useEffect(() => {
    getAllBook();
  }, []);

  const onSearch = (e) => {
    setSearch(e.target.value);
  };

  return (
    <>
      <Grid container spacing={gridSpacing}>
        <br />
        <br />
        {/* payment BCA */}
        <Grid item xs={12} md={12}>
          <Button
            onClick={() => {
              setOpenModalAdd(true);
            }}
            variant="contained"
            color="primary"
          >
            <Add /> Tambah Buku
          </Button>
          <TextField
            onChange={onSearch}
            style={{
              float: "right"
            }}
            id="searach"
            label="Search"
            size="small"
          />
          <br />
          <br />

          <TableListBook
            refreshData={() => getAllBook()}
            data={
              search
                ? dataBook.filter((data) => data.book_title.includes(search))
                : dataBook
            }
          />
        </Grid>
      </Grid>

      <ModalAddBook
        refreshData={() => getAllBook()}
        open={openModalAdd}
        setOpen={setOpenModalAdd}
      />
    </>
  );
};

export default BookDashboard;
