import React from "react";

// Custom components
import ChapterButton from "./ChapterButton";

function TableOfContent({
  chapterContent,
  chosenChapter,
  setChosenChapter,
  classes,
  type,
  coverLink,
}) {
  return (
    <div className={classes}>
      {type === "video" ? (
        <>
          <ChapterButton
            type={"video"}
            chosenChapter={chosenChapter}
            setChosenChapter={setChosenChapter}
            key={0}
            chapter={chapterContent}
            coverLink={coverLink}
          />
        </>
      ) : (
        <>
          {chapterContent.map((chapter, index) => (
            <ChapterButton
              chosenChapter={chosenChapter}
              setChosenChapter={setChosenChapter}
              key={index}
              chapter={chapter}
              coverLink={coverLink}
            ></ChapterButton>
          ))}
        </>
      )}
    </div>
  );
}

export default TableOfContent;
