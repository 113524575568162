import * as React from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { DateToFormattedString } from "../../../utils/dateFormatted";
import ExampleDataToExport from "./ExampleDataToExport";

export default function TableSubscribe({ content }) {
  const rows = content || false;
  const Header = [
    "date",
    "email",
    "phone number",
    "first name",
    "items",
    "total cost",
    "processed"
  ];
  return (
    <>
      <ExampleDataToExport data={rows} />

      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              {Header.map((txt) => {
                return <TableCell>{txt}</TableCell>;
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows &&
              rows.map((row) => (
                <TableRow
                  key={row.name}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell>
                    {DateToFormattedString(row.date.toDate())}
                  </TableCell>
                  <TableCell>{row.email}</TableCell>
                  <TableCell>{row.phoneNumber}</TableCell>
                  <TableCell>{row.fn || row.firstName}</TableCell>
                  <TableCell>{row.info.items[0].title || ""}</TableCell>
                  <TableCell>{row.info.total_cost || ""}</TableCell>
                  <TableCell>{row.is_processed ? "yes" : "no"}</TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
