import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import TablePagination from "@material-ui/core/TablePagination";
import CircularProgress from "@material-ui/core/CircularProgress";
import { DateToFormattedString } from "../../../utils/dateFormatted";
import ExportListPayment from "../exported/ListPayment";
import { Button, Typography } from "@material-ui/core";
import { getAllPaymentBca, getDetailUserByUid } from "../../../serverDashboard";
import fire from "../../../../firebase/fire";
import { updateUser } from "../../../../firebase/firebaseUser";
import { firebaseTrackPayment } from "../../../../firebase/firebaseDashboard";
import DoneIcon from "@material-ui/icons/Done";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";
import ReplayIcon from "@material-ui/icons/Replay";
import { updatePayments } from "../../../../firebase/firePayments";

const useStyles = makeStyles({
  table: {
    minWidth: 650
  },
  root: {
    width: "100%"
  },
  container: {
    maxHeight: 440
  },
  button: {
    margin: 2,
    width: "100%"
  },
  bgDanger: {
    backgroundColor: "red"
  },
  bgSuccess: {
    backgroundColor: "green"
  }
});

// export function PaymentBCA(name, code, population, size) {
//   const density = population / size;
//   return { name, code, population, size, density };
// }

let isMounted = false;

let dataFirstFetch = false;

const Action = ({ param, Approve, Reject }) => {
  const classes = useStyles();
  const { status } = param;

  const [loading, setLoading] = useState(false);
  if (loading) {
    return <CircularProgress color="#ffcf33" />;
  } else {
    if (status === "PENDING") {
      return (
        <>
          <Button
            onClick={() => {
              setLoading(true);
              Approve(param);
            }}
            className={[classes.button, classes.bgSuccess]}
            variant="contained"
            color="secondary"
          >
            Approve
          </Button>
          <Button
            onClick={() => {
              setLoading(true);

              Reject(param);
            }}
            className={[classes.button, classes.bgDanger]}
            variant="contained"
            color="secondary"
          >
            Reject
          </Button>
        </>
      );
    } else if (status === "APPROVE") {
      return <DoneIcon color="primary" />;
    } else if (status === "REJECT") {
      return <CloseIcon color="error" />;
    }
  }
};

export default function PaymentBCA({}) {
  const [reload, setReload] = useState(false);

  const classes = useStyles();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [data, setData] = useState(dataFirstFetch);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  useEffect(() => {
    if (!isMounted) {
      getAllPaymentBca().then((res) => {
        setData(res.data);
        dataFirstFetch = res.data;
        isMounted = true;
      });
    } else if (dataFirstFetch) {
      setData(dataFirstFetch);
    }
  }, []);

  const refreshData = () => {
    getAllPaymentBca().then((res) => {
      setReload(false);
      setData(res.data);
    });
  };
  const ApprovePayment = (res) => {
    getDetailUserByUid(res.uid).then((detailuser) => {
      const { phoneNumber, email, kode_promo } = res;

      const start_date = new Date();

      const userPromoCodes = kode_promo
        ? [...detailuser.data.promo_codes_used, kode_promo]
        : detailuser.data.promo_codes_used;

      let end_date = new Date();
      // done
      if (res.item == "Subscription 12 Bulan") {
        end_date.setMonth(end_date.getMonth() + 12);
      } else if (res.item == "Subscription 1 Bulan") {
        end_date.setMonth(end_date.getMonth() + 1);
      } else if (res.item == "Subscription 1 Bulan") {
        end_date.setMonth(end_date.getMonth() + 1);
      }
      firebaseTrackPayment({
        email,
        date: new Date(),
        phoneNumber: phoneNumber,
        item: res.item,
        kode_promo: kode_promo || "",
        payment: "BCA"
      });
      updateUser(detailuser.data.email, {
        is_subscribed: true,
        end_date: end_date,
        start_date: start_date,
        id_incoive: "",
        promo_code_invoice: "",
        promo_codes_used: userPromoCodes
      }).then(() => {
        updatePayments({
          is_subscribed: true,
          date_subscribe: new Date(),
          user: res.uid,
          type: "tracking"
        });
      });

      fire
        .firestore()
        .collection("paymentsBCA")
        .doc(res.id)
        .update({
          status: "APPROVE"
        })
        .then((res) => {
          refreshData();
        });
    });
  };

  // done
  const RejectPayment = (res) => {
    getDetailUserByUid(res.uid).then((detailuser) => {
      let end_date = new Date();
      // done
      if (res.item == "Subscription 12 Bulan") {
        end_date.setMonth(end_date.getMonth() + 12);
      } else if (res.item == "Subscription 1 Bulan") {
        end_date.setMonth(end_date.getMonth() + 1);
      } else if (res.item == "Subscription 1 Bulan") {
        end_date.setMonth(end_date.getMonth() + 1);
      }

      fire
        .firestore()
        .collection("paymentsBCA")
        .doc(res.id)
        .update({
          status: "REJECT"
        })
        .then((res) => {
          refreshData();
        });
    });
  };

  const colorStatus = (status) => {
    if (status === "PENDING") {
      return "#ffcf33";
    } else if (status === "APPROVE") {
      return "#76ff03";
    } else if (status === "REJECT") {
      return "#ff6333";
    }
  };

  return (
    <>
      <Paper className={classes.root}>
        <br />
        <Typography style={{ margin: 10 }} variant="h2" gutterBottom>
          List Payment BCA
          <IconButton
            onClick={() => {
              if (!reload) {
                setReload(true);
                refreshData();
              }
            }}
            aria-label="delete"
            // className={classes.margin}
            size="medium"
          >
            {reload ? (
              <CircularProgress size={25} />
            ) : (
              <ReplayIcon fontSize="inherit" />
            )}
          </IconButton>
        </Typography>
        <br />

        {/* <Button
        style={{
          background: "lightblue",
          padding: "0px",
          margin: "1px 1px 3px 1px"
        }}
      >
        <ExportListPayment data={dataPayment} />
      </Button> */}
        <TableContainer component={Paper} className={classes.container}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                <TableCell>tanggal</TableCell>
                <TableCell align="center">email</TableCell>
                <TableCell align="center">nomor telepon</TableCell>
                <TableCell align="center">nama</TableCell>
                <TableCell align="center">item</TableCell>
                <TableCell align="center">Gambar</TableCell>
                <TableCell align="center">Status</TableCell>
                <TableCell align="center">Aksi</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data &&
                data
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row) => {
                    const ni = {
                      date: DateToFormattedString(row.date.toDate()),
                      email: row.email,
                      phoneNumber: row.phoneNumber,
                      first_name: row.fn || row.firstName,
                      items: row.item || "-",
                      image: row.bukti || "",
                      status: row.status || "-"
                    };

                    const {
                      email,
                      first_name,
                      date,
                      phoneNumber,
                      items,
                      status,
                      image
                    } = ni;

                    return (
                      <TableRow key={Math.random()}>
                        <TableCell component="th" scope="row">
                          {date}
                        </TableCell>
                        <TableCell align="center">{email}</TableCell>
                        <TableCell align="center">{phoneNumber}</TableCell>
                        <TableCell align="center">{first_name}</TableCell>
                        <TableCell align="center">{items}</TableCell>
                        <TableCell align="center">
                          <img
                            style={{
                              width: "30%",
                              height: "30%",
                              border: "1px solid #ffcf33",
                              borderRadius: 5
                            }}
                            src={image}
                            alt={first_name}
                          />
                          <br />
                          <Button
                            onClick={() => {
                              if (image) {
                                window.open(image);
                              }
                            }}
                          >
                            Preview
                          </Button>
                          {/* {image} */}
                        </TableCell>

                        <TableCell align="center">
                          <b style={{ color: colorStatus(status) }}>{status}</b>
                        </TableCell>
                        <TableCell align="center">
                          <Action
                            param={row}
                            Approve={ApprovePayment}
                            Reject={RejectPayment}
                          />
                        </TableCell>
                      </TableRow>
                    );
                  })}
            </TableBody>
          </Table>
        </TableContainer>
        {data && (
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={data.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        )}
      </Paper>
    </>
  );
}
