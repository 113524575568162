import React, { useContext, useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";

// Custom components
import Typography from "../../components/Typography";
import Footer from "../../components/Footer";
import MultiUseMobile from "../../styles/MultiUseMobile";
import Button from "../../components/Button";
import SubscriptionPlan from "./SubscriptionPlan";
import Header from "../../components/NavBar/Header";
import HeaderLinks from "../../components/NavBar/HeaderLinks";
import HeaderLinksMobile from "../../components/NavBar/HeaderLinksMobile";
import Loading from "../Utilities/Loading";

// Context
import { setLogoutModal } from "../../context/action";
import { ContextReact } from "../../context";

// firebase components
import fire from "../.././firebase/fire";
import { AuthContext } from "../../components/Routing/Auth";
import * as firebaseGetUserDataById from "../../firebase/firebaseGetUserDataById";

// Material-UI components
import { Container, Paper, Divider, TextField, Grid } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { beigeColor, secondaryColor } from "../../styles/Style";
import { newUpdateUser, updateUser } from "../../firebase/firebaseUser";
import { AES } from "crypto-js";
import { encrypt } from "../../utils";

// Images
const Whatsapp =
  "https://firebasestorage.googleapis.com/v0/b/sekilasaja-999fd.appspot.com/o/Website_Images%2FWeb_Picture_Components%2FWhatsapp.png?alt=media&token=88483bb9-b9d3-4aa8-9f14-9b7f91682861";

export default function AccountsPage({ history }) {
  // Auth
  const firestore = fire.firestore();
  const { currentUser } = useContext(AuthContext);

  // Styles
  const classes = MultiUseMobile();

  // useState Hooks
  const [userData, setUserData] = useState(null);
  const [bookNum, setBookNum] = useState([]);
  const [endDate, setEndDate] = useState("");
  const [error, setError] = useState("");
  const [profileError, setProfileError] = useState("");
  const [profileSuccess, setProfileSuccess] = useState("");
  const [success, setSuccess] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [newPasswordConfirm, setNewPasswordConfirm] = useState("");
  const [pending, setPending] = useState(true);

  // useRef hooks
  const passwordRef = useRef();
  const passwordConfirmRef = useRef();
  const firstNameRef = useRef();
  const lastNameRef = useRef();
  const phoneNumberRef = useRef();

  // Context
  const context = React.useContext(ContextReact);
  const { dispatch } = context;

  useEffect(() => {
    if (currentUser !== null) {
      const fetchData = async () => {
        const results = await firebaseGetUserDataById.getUserDataById(
          currentUser.uid
        );
        if (results) {
          setUserData(results);
          setBookNum(results.owned_books);
          // Set Date
          var month = results.end_date.toDate().getMonth();
          var year = results.end_date.toDate().getFullYear();
          var day = results.end_date.toDate().getDate();
          setEndDate(day + "/" + (month + 1) + "/" + year);
        }
      };
      fetchData();
    } else {
      console.log("Not logged in");
    }
  }, []);

  function updatePassword(password) {
    return new Promise((resolve, reject) => {
      let newAuth = fire.auth();

      const { email } = currentUser;

      return currentUser
        .updatePassword(password)
        .then((res) => {
          newAuth.signInWithEmailAndPassword(email, password);
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  function handleUpdateUserInformation(e) {
    e.preventDefault();

    setProfileError("");
    setProfileSuccess("");

    try {
      if (currentUser !== null) {
        firestore.collection("users").doc(currentUser.uid).update({
          firstName: firstNameRef.current.value,
          // lastName: lastNameRef.current.value,
          phoneNumber: phoneNumberRef.current.value
        });
      }

      if (
        userData.firstName === firstNameRef.current.value &&
        userData.phoneNumber === phoneNumberRef.current.value
        // && useData.lastName ===lastNameRef.current.value
      ) {
        setProfileError("Tidak terjadi perubahan!");
        setProfileSuccess("");
      } else {
        setProfileSuccess("Profil berhasil diganti!");
        setProfileError("");
      }
    } catch (err) {
      var errorCode = err.code;
      var errorMessage = err.message;
      setProfileError("Error (" + errorCode + "): " + errorMessage);
      setProfileSuccess("");
    }
  }

  function handleChangePassword(e) {
    e.preventDefault();

    setError("");
    setSuccess("");

    if (passwordRef.current.value !== passwordConfirmRef.current.value) {
      setSuccess("");
      return setError("Password tidak sama!");
    }

    if (passwordRef.current.value.length === 0) {
      setSuccess("");
      return setError("Proses Gagal!");
    }

    if (passwordRef.current.value) {
      updatePassword(passwordRef.current.value)
        .then((res) => {
          setError("");
          setSuccess("Proses berhasil!");

          const { uid } = currentUser;
          const enc = encrypt(passwordRef.current.value);
          newUpdateUser(uid, {
            password: enc
          });
        })
        .catch((err) => {
          setError(err.message);
        });
    }
  }

  if (userData && bookNum && pending) {
    setPending(false);
  }

  if (pending) {
    return (
      <>
        <Loading />
      </>
    );
  }

  return (
    <div style={{ backgroundColor: beigeColor }}>
      <div style={{ marginTop: "100px" }} />
      <Header
        history={history}
        rightLinks={<HeaderLinks history={history} />}
        rightLinksMobile={<HeaderLinksMobile history={history} />}
        fixed
        color="white"
      />
      {(userData !== null) === true && (
        <Container maxWidth={"sm"}>
          <div className={classes.extraSpace} />
          <Paper className={classes.paddedContent}>
            <Typography className={classes.sectionTitle} size="heading">
              Accounts
            </Typography>
            <div className={classes.extraSpace} />
            <Typography size="subheading">Layanan Berlangganan</Typography>
            <SubscriptionPlan
              subscriptionType={"Belum Berlanggan"}
              number={bookNum.length}
              endDate={endDate}
            />

            <div className={classes.extraSpace} />
            <Divider />
            <div className={classes.extraSpace} />

            <Typography size="subheading">Profil</Typography>
            <form
              className={classes.textFieldRoot}
              onSubmit={handleUpdateUserInformation}
            >
              {profileError && (
                <div className={classes.alertRoot}>
                  <Alert severity="error">{profileError}</Alert>
                </div>
              )}
              {profileSuccess && (
                <div className={classes.alertRoot}>
                  <Alert severity="success">{profileSuccess}</Alert>
                </div>
              )}
              <TextField
                required
                defaultValue={userData.firstName}
                id="filled-basic"
                label="Nama Lengkap"
                variant="filled"
                inputRef={firstNameRef}
                fullWidth
              />
              <TextField
                required
                disabled
                defaultValue={currentUser.email}
                id="filled-basic"
                label="Email"
                variant="filled"
                fullWidth
              />
              <TextField
                required
                defaultValue={userData.phoneNumber}
                id="filled-basic"
                label="Nomor HP"
                variant="filled"
                inputRef={phoneNumberRef}
                fullWidth
              />

              <Button type="submit" fullWidth round>
                Update Profile
              </Button>
            </form>

            <div className={classes.extraSpace} />
            <Divider />
            <div className={classes.extraSpace} />

            <Typography size="subheading">Ganti Password</Typography>
            <form
              className={classes.textFieldRoot}
              onSubmit={handleChangePassword}
            >
              {error && (
                <div className={classes.alertRoot}>
                  <Alert severity="error">{error}</Alert>
                </div>
              )}
              {success && (
                <div className={classes.alertRoot}>
                  <Alert severity="success">{success}</Alert>
                </div>
              )}
              <TextField
                id="filled-basic"
                label="Password Baru"
                variant="filled"
                inputRef={passwordRef}
                // value={newPassword}
                type="password"
                fullWidth
              />
              <TextField
                id="filled-basic"
                label="Ketik Ulang Password Baru"
                variant="filled"
                type="password"
                inputRef={passwordConfirmRef}
                // value={newPasswordConfirm}
                fullWidth
              />
              <Button disabled={pending} type="submit" fullWidth round>
                Change Password
              </Button>
            </form>
            <div className={classes.extraSpace} />

            <div className={classes.center}>
              <Link
                to="/home"
                style={{ color: secondaryColor }}
                onClick={() => {
                  dispatch(setLogoutModal(true));
                  fire.auth().signOut();
                  localStorage.removeItem("isLogedIn");
                  window.scrollTo(0, 0);
                }}
              >
                <Typography style={{ fontSize: 20 }} type="bold">
                  {/* <ExitToAppIcon /> */}
                  Logout
                </Typography>
              </Link>
            </div>
          </Paper>
        </Container>
      )}

      {/*---------------------------------------------------------------*/}
      {/*---------------------- WHATSAPP FIXED NAV ---------------------*/}
      {/*---------------------------------------------------------------*/}
      <a href="https://wa.me/6281291176795" target="_blank">
        <div className={classes.whatsapp}>
          <Grid container spacing={3}>
            <Grid item xs={4}>
              <img
                style={{
                  width: "60px"
                }}
                src={Whatsapp}
              />
            </Grid>

            <Grid item xs={4}>
              <Typography type="bold">Butuh Bantuan?</Typography>
            </Grid>
          </Grid>
        </div>
      </a>
      <Footer />
    </div>
  );
}
