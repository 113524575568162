import { getRandomInt } from "../utils";
import fire from "./fire";

const firePayments = fire.firestore().collection("payments");

export const createPayments = (data) => {
  return new Promise((resolve, reject) => {
    firePayments
      .doc(`${data.email}-${Math.random()}`)
      .set({ ...data, type: "maksimalin diri" })
      .then((res) => {
        resolve({
          is_success: true
        });
      })
      .catch((err) => {
        reject({
          is_success: false
        });
      });
  });
};

export const updatePayments = (doc, data) => {
  return new Promise((resolve, reject) => {
    firePayments
      .doc(doc || getRandomInt())
      .set(data, { merge: true })
      .then((res) => {
        resolve({
          is_success: true
        });
      })
      .catch((err) => {
        reject({
          is_success: false
        });
      });
  });
};
