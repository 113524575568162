import { makeStyles } from "@material-ui/core/styles";
import { primaryColor, secondaryColor } from "./Style";

const PricingStyles = makeStyles((theme) => ({
  // small: 600px; md, medium: 960px; lg, large: 1280px
  sectionDesktop: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "block",
    },
  },
  // small: 600px; md, medium: 960px; lg, large: 1280px
  sectionMobile: {
    display: "block",
    justifyContent: "center",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  cross: {
    backgroundColor: "transparent",
    backgroundImage:
      "gradient(linear, 19.1% -7.9%, 81% 107.9%, color-stop(0, transparent), color-stop(.48, transparent), color-stop(.5, #000), color-stop(.52, transparent), color-stop(1, transparent))",
    backgroundImage:
      "repeating-linear-gradient(168deg, transparent 0%, transparent 48%, red 50%, transparent 52%, transparent 100%)",
  },
  cardHover: {
    position: "relative",
    top: 0,
    transition: "top ease 0.5s",
    "&:hover": {
      top: "-10px",
    },
  },
  orLabel: {
    width: "100%",
    textAlign: "center",
    borderBottom: "1px solid #41444b",
    lineHeight: "0.1em",
    margin: "10px 0 20px",
  },
  middle: {
    fontSize: "30px",
    fontWeight: 500,
    background: "#FAFAFA",
    padding: "0 10px",
  },
  link: {
    margin: theme.spacing(1, 1.5),
  },
  heroContent: {
    padding: theme.spacing(8, 0, 6),
  },
  cardHeader: {
    backgroundColor: primaryColor,
    borderBottom: "1px solid #D3D3D3",
  },
  cardHeaderWhite: {
    backgroundColor: "transparent",
    borderBottom: "1px solid #D3D3D3",
  },
  cardHeaderKilas: {
    backgroundColor: secondaryColor,
  },
  cardPricing: {
    display: "flex",
    justifyContent: "center",
    alignItems: "baseline",
    // marginBottom: theme.spacing(2),
  },
  underline: {
    borderBottom: "7px solid #FFE05D",
    display: "inline-block",
    lineHeight: 0.45,
  },
}));

export default PricingStyles;
