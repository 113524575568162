import React, { useState, useEffect } from "react";
import { useParams, Redirect } from "react-router";

// momentjs
import moment from "moment";

// Custom components
import Typography from "../../components/Typography";
import Header from "../../components/NavBar/Header";
import HeaderLinks from "../../components/NavBar/HeaderLinks";
import HeaderLinksMobile from "../../components/NavBar/HeaderLinksMobile";
import Loading from "../Utilities/Loading";

// Material-UI components
import { Container } from "@material-ui/core";

// Sanity
import client from "../../sekilasajablog/client";
import BlockContent from "@sanity/block-content-to-react";

export default function Post({ history }) {
  // useStates
  const [singlePost, setSinglePost] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { slug } = useParams();

  useEffect(() => {
    sessionStorage.setItem("modal", "false");
    client
      .fetch(
        `*[slug.current =="${slug}"] {
        title,
        body,
        mainImage {
          asset -> {
            _id,
            url
          },
          alt
        },
        "authorName": author -> name,
        "publishedDate": releaseDate
      }`
      )
      .then((data) => {
        setSinglePost(data[0]);
        setIsLoading(false);
      });
  }, [slug]);

  if (!singlePost) {
    return <Redirect to="/404page" />;
  }

  if (isLoading) {
    return <Loading />;
  }

  return (
    <>
      {isLoading ? (
        <div>
          <Loading />
        </div>
      ) : (
        <Container maxWidth="md">
          <Typography size="heading">{singlePost.title}</Typography>

          {singlePost.mainImage && singlePost.mainImage.asset && (
            <img
              style={{
                width: "100%",
              }}
              src={singlePost.mainImage.asset.url}
              alt={singlePost.title}
            />
          )}

          <Typography>
            {singlePost.authorName} |{" "}
            {moment(singlePost.releaseDate).format("LL")}
          </Typography>
          <Typography>
            <BlockContent
              blocks={singlePost.body}
              projectId="2hp9gld0"
              dataset="production"
            />
          </Typography>
        </Container>
      )}
    </>
  );
}
