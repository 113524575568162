import React from "react";

// import component from mui
import {
  Avatar,
  IconButton,
  ListItem,
  ListItemText,
  ListItemAvatar,
  ListItemSecondaryAction,
  makeStyles,
  InputAdornment,
  TextField,
  Typography,
  CircularProgress
} from "@material-ui/core";

// import icon
import { ThumbDown, ThumbUp, Delete, Send } from "@material-ui/icons";

// cek data login && get data login
import { isLOgin, revisiNumber } from "../../../utils";
import { Alert } from "@material-ui/lab";

// create styling from mui
const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1)
    }
  }
}));

export default function SubCommentary({
  data,
  deleteSubComment,
  setValue,
  value,
  replyComment,
  firstName,
  load,
  setLoad,
  invalidComment,
  likeDslike
}) {
  const classes = useStyles();

  const { loadDelete, loadcreate } = load;
  const { idDelete, statusDelete } = loadDelete;
  const { idCreate, statusCreate } = loadcreate;
  const { setLoadDelete, setLoadcreate } = setLoad;

  // hook
  const [fieldReply, setFieldReply] = React.useState(false);

  // get define param
  const { name, text, date, id, likeDislike } = data;

  // getDate
  const getDate = () => {
    const isFunction = typeof date.getDate === "function";

    const year = isFunction ? date.getFullYear() : date.toDate().getFullYear(),
      month = isFunction ? date.getMonth() + 1 : date.toDate().getMonth() + 1,
      day = isFunction ? date.getDate() : date.toDate().getDate(),
      minuts = isFunction ? date.getMinutes() : date.toDate().getMinutes(),
      hours = isFunction ? date.getHours() : date.toDate().getHours();

    return `${day}/${month}/${year}  ${revisiNumber(hours)} : ${revisiNumber(
      minuts
    )}`;
  };

  const toggleField = () => {
    setTimeout(() => {
      setFieldReply(!fieldReply);
    }, 2000);
  };

  const { toggleLikeDislikeSubComment, likeSubComment, dislikeSubComment } =
    likeDslike;

  return (
    <>
      <ListItem alignItems="flex-start">
        <ListItemAvatar>
          <Avatar alt={name.toUpperCase()} src="/static/images/avatar/1.jpg" />
        </ListItemAvatar>
        <ListItemText
          primary={
            <Typography>
              <b>{name} </b> <small>{getDate()}</small>
            </Typography>
          }
          secondary={
            <React.Fragment>
              <Typography>{text}</Typography>
              <br />
              <IconButton
                aria-label="delete"
                className={classes.margin}
                size="small"
                onClick={() => {
                  likeSubComment(data, "like");
                }}
              >
                <ThumbUp style={{ marginRight: 3 }} fontSize="inherit" />{" "}
                {likeDislike &&
                  likeDislike.like &&
                  likeDislike.like.total > 0 &&
                  likeDislike.like.total}
              </IconButton>
              <IconButton
                aria-label="delete"
                className={classes.margin}
                size="small"
                onClick={() => {
                  dislikeSubComment(data, "dislike");
                }}
              >
                <ThumbDown style={{ marginRight: 3 }} fontSize="inherit" />
                {likeDislike &&
                  likeDislike.dislike &&
                  likeDislike.dislike.total > 0 &&
                  likeDislike.dislike.total}
              </IconButton>
              <IconButton
                style={{ fontSize: "small" }}
                onClick={async () => {
                  await setFieldReply(!fieldReply);

                  setTimeout(() => {
                    if (!fieldReply) {
                      const element = document.getElementById(
                        "sub-input-with-icon-textfield" + id
                      );
                      if (element) {
                        element.focus();
                      }
                    }
                  }, 1000);
                }}
              >
                Reply
              </IconButton>
              {fieldReply && (
                <>
                  {
                    <form
                      onBlur={() => toggleField()}
                      onSubmit={(e) => {
                        e.preventDefault();
                      }}
                      className={classes.root}
                      noValidate
                      autoComplete="off"
                    >
                      <TextField
                        onBlur={() => {
                          replyComment(false);
                          toggleField(false);
                          setLoadcreate({});
                        }}
                        value={value}
                        fullWidth
                        onChange={(e) => setValue(e.target.value)}
                        id={"sub-input-with-icon-textfield" + id}
                        label=""
                        InputProps={{
                          startAdornment: (
                            <Avatar
                              style={{ margin: "5px", marginRight: "10px" }}
                              alt={firstName}
                              src="/static/images/avatar/1.jpg"
                            />
                          ),
                          endAdornment: fieldReply && (
                            <InputAdornment position="end">
                              <IconButton
                                onClick={() => {
                                  replyComment(true);
                                  toggleField();
                                  setLoadcreate({ id });
                                }}
                                type="submit"
                                aria-label="comment book"
                              >
                                {loadcreate.id === id ? (
                                  <CircularProgress size={25} />
                                ) : (
                                  <Send />
                                )}
                              </IconButton>
                            </InputAdornment>
                          )
                        }}
                      />
                    </form>
                  }

                  {invalidComment && (
                    <Alert severity="warning">
                      Maaf, anda tidak bisa memberikan balasan komentar yang
                      sama secara berulang
                    </Alert>
                  )}
                </>
              )}
            </React.Fragment>
          }
        />
        {isLOgin().email === data.email && (
          <ListItemSecondaryAction>
            <IconButton
              onClick={() => {
                deleteSubComment(id);
                setLoadDelete({ idDelete: id, status: true });
              }}
              edge="end"
              aria-label="delete"
            >
              {idDelete && idDelete === id ? (
                <CircularProgress size={25} />
              ) : (
                <Delete />
              )}
            </IconButton>
          </ListItemSecondaryAction>
        )}
      </ListItem>
    </>
  );
}
