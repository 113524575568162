import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import { Button } from "@material-ui/core";
import { ModalEditBook, ModalEditKilas } from "../modalAction";
import { deleteBook, getKilas } from "../../../serverDashboard";
import { red, lime, yellow, green } from "@material-ui/core/colors";
import { ConfirmationDelete } from "../form";

const columns = [
  {
    id: "book_title",
    label: "Title Book"
    //  minWidth: 170
  },
  {
    id: "author",
    label: "Author"

    // minWidth: 100
  },
  {
    id: "book_cover",
    label: "Cover Book",
    // minWidth: 170,
    align: "center",
    format: (value) => value.toLocaleString("en-US")
  },
  {
    id: "descriptions",
    label: "Descriptions",
    // minWidth: 170,
    align: "center",

    format: (value) => value.toLocaleString("en-US")
  },
  {
    id: "category",
    label: "Category",
    // minWidth: 170,
    align: "center",

    format: (value) => value.toFixed(2)
  },
  {
    id: "action",
    label: "Aksi",
    // minWidth: 170,
    align: "center",

    format: (value) => value.toFixed(2)
  }
];

function createData(
  book_title,
  author,
  book_cover,
  descriptions,
  category,
  created_date
) {
  const newCategory = category || book_cover / descriptions;
  return {
    book_title,
    author,
    book_cover,
    descriptions,
    category: newCategory,
    created_date: created_date || ""
  };
}

const useStyles = makeStyles({
  root: {
    width: "100%"
  },
  container: {
    maxHeight: 440
  },
  m5: {
    margin: 5
  },
  bgDanger: {
    background: red[500]
  },
  bgWarning: {
    background: yellow[500]
  },
  bgSuccess: {
    background: green[500]
  }
});

export default function TableListBook({ data, refreshData }) {
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const [modalEdit, setModalEdit] = React.useState(false);
  const [modalEditKilas, setModalEditKilas] = React.useState(false);

  const [detailBook, setDetailBook] = React.useState(false);
  const [detailKilas, setDetailKilas] = React.useState(false);

  const [openConfirm, setOpenConfirm] = React.useState(false);

  const [idBookDelete, setIdBookDelete] = React.useState(false);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const dummy = [
    createData("India", "IN", 1324171354, 3287263),
    createData("China", "CN", 1403500365, 9596961),
    createData("Italy", "IT", 60483973, 301340),
    createData("United States", "US", 327167434, 9833520),
    createData("Canada", "CA", 37602103, 9984670),
    createData("Australia", "AU", 25475400, 7692024),
    createData("Germany", "DE", 83019200, 357578),
    createData("Ireland", "IE", 4857000, 70273),
    createData("Mexico", "MX", 126577691, 1972550),
    createData("Japan", "JP", 126317000, 377973),
    createData("France", "FR", 67022000, 640679),
    createData("United Kingdom", "GB", 67545757, 242495),
    createData("Russia", "RU", 146793744, 17098246),
    createData("Nigeria", "NG", 200962417, 923768),
    createData("Brazil", "BR", 210147125, 8515767)
  ];

  const rows = Array.isArray(data)
    ? data.map((cb) => {
        let {
          book_title,
          author,
          book_cover,
          descriptions,
          category,
          created_date
        } = cb;

        return createData(
          book_title,
          author,
          book_cover,
          descriptions[0],
          category[0],
          created_date
        );
      })
    : dummy;

  const handleOpenModal = async (id) => {
    if (Array.isArray(data)) {
      const dataDefault = data.slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
      )[id];
      await setDetailBook(dataDefault);
      refreshData && refreshData();
      setModalEdit(true);
    }
  };

  const handleDeleteBook = () => {
    deleteBook(idBookDelete)
      .then((res) => {
        setOpenConfirm(false);
        refreshData && refreshData();
      })
      .catch((err) => {
        alert("failed remove book");
      });
  };

  const handleOpenModalKilas = async (id) => {
    if (Array.isArray(data)) {
      getKilas(id.book_title)
        .then((res) => {
          if (res.data.length > 0) {
            setDetailKilas({ kilas: res.data, book_title: id.book_title });
          } else {
            setDetailKilas({ kilas: false, book_title: id.book_title });
          }
          setModalEditKilas(true);
        })
        .catch((err) => {
          setDetailKilas({ kilas: false, book_title: id.book_title });

          setModalEditKilas(true);
        });
    }
  };

  let filterWithDate = rows.filter((cb) => cb.created_date),
    sortByDate =
      filterWithDate.length > 0
        ? filterWithDate.sort(
            (a, b) => b.created_date.toDate() - a.created_date.toDate()
          )
        : filterWithDate;
  let filterWithoutDate = rows.filter((cb) => !cb.created_date);

  return (
    <Paper className={classes.root}>
      <ConfirmationDelete
        approve={() => handleDeleteBook()}
        open={openConfirm}
        setOpen={setOpenConfirm}
      />
      <TableContainer className={classes.container}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {[...sortByDate, ...filterWithoutDate]
              // .sort((a, b) => s.created_date.toDate() - b.created_date.toDate())
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                    {columns.map((column, indexColom) => {
                      const value = row[column.id];
                      return (
                        <TableCell key={indexColom} align={column.align}>
                          {column.id === "action" ? (
                            <>
                              <Button
                                className={[classes.m5, classes.bgSuccess]}
                                onClick={() => {
                                  handleOpenModal(index);
                                }}
                                variant="contained"
                                color="primary"
                              >
                                Edit Buku
                              </Button>

                              <Button
                                className={[classes.m5, classes.bgWarning]}
                                onClick={() => {
                                  handleOpenModalKilas(row);
                                }}
                                variant="contained"
                                color="primary"
                              >
                                Edit Kilas
                              </Button>
                              <Button
                                className={[classes.m5, classes.bgDanger]}
                                onClick={() => {
                                  setIdBookDelete(row.book_title);
                                  setOpenConfirm(true);
                                }}
                                variant="contained"
                                color="primary"
                              >
                                Hapus Buku
                              </Button>
                            </>
                          ) : (
                            <>
                              {column.id === "book_cover" ? (
                                <>
                                  {value ? (
                                    <>
                                      <img
                                        style={{
                                          width: "30%",
                                          height: "30%",
                                          border: "1px solid #ffcf33",
                                          borderRadius: 5
                                        }}
                                        src={value}
                                        alt={value}
                                      />
                                      <br />
                                      <Button
                                        onClick={() => {
                                          if (value) {
                                            window.open(value);
                                          }
                                        }}
                                      >
                                        Preview
                                      </Button>
                                    </>
                                  ) : (
                                    "-"
                                  )}
                                </>
                              ) : (
                                <>
                                  {column.format && typeof value === "number"
                                    ? column.format(value)
                                    : value}
                                </>
                              )}
                            </>
                          )}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />

      <ModalEditBook
        open={modalEdit}
        setOpen={setModalEdit}
        detailBook={detailBook}
      />

      <ModalEditKilas
        open={modalEditKilas}
        setOpen={setModalEditKilas}
        detailBook={detailKilas}
      />
    </Paper>
  );
}
