import {
  riset_data,
  set_audio_link,
  set_book,
  set_book_detail,
  set_cover_link,
  set_modal_auth,
  set_redirect_to,
  set_logout_modal,
} from "./type";

export const setBookDetail = (data) => {
  return { type: set_book_detail, payload: data };
};
export const setCoverLink = (data) => {
  return { type: set_cover_link, payload: data };
};

export const setAudioLink = (data) => {
  return { type: set_audio_link, payload: data };
};

export const risetData = (data) => {
  return { type: riset_data, payload: data };
};

export const setBook = (data) => {
  return { type: set_book, payload: data };
};

export const setModalAuth = (data) => {
  return { type: set_modal_auth, payload: data };
};

export const setRedirectTo = (data) => {
  return { type: set_redirect_to, payload: data };
};

export const setLogoutModal = (data) => {
  return { type: set_logout_modal, payload: data };
};
