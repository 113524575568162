import fire from "../firebase/fire";

export const getAllPaymentBca = () => {
  return new Promise((resolve, reject) => {
    fire
      .firestore()
      .collection("paymentsBCA")
      .get()
      .then((res) => {
        const result = res.docs.map((data) => {
          return { ...data.data(), id: data.id };
        });
        resolve({
          data: result,
          message: "success",
          isSuccess: true,
          isFailed: false
        });
      })
      .catch((err) => {
        reject({
          data: null,
          message: "failed",
          isSuccess: false,
          isFailed: true
        });
      });
  });
};

export const getDetailUserByUid = (uid) => {
  return new Promise((resolve, reject) => {
    fire
      .firestore()
      .collection("users")
      .doc(uid)
      .get()
      .then((res) => {
        resolve({
          data: { ...res.data(), uid },
          message: "success fetching detail user",
          isSuccess: true,
          isFailed: false
        });
      })
      .catch((err) => {
        reject({
          data: null,
          message: "failed fetching detail user",
          isSuccess: false,
          isFailed: true
        });
      });
  });
};

export const addBook = (value) => {
  return new Promise((resolve, reject) => {
    let fireNewBook = fire
      .firestore()
      .collection("books")
      .doc(value.book_title);
    fireNewBook
      .set({ ...value, created_date: new Date() })
      .then(async (res) => {
        await fireNewBook
          .collection("kilasan")
          .doc("fake")
          .set({ is_fake: true });
        resolve({
          is_success: true,
          is_failed: false
        });
      })
      .catch((err) => {
        reject({
          is_success: false,
          is_failed: true
        });
      });
  });
};

export const editBook = (value) => {
  return new Promise((resolve, reject) => {
    fire
      .firestore()
      .collection("books")
      .doc(value.book_title)
      .set(value, { merge: true })
      .then((res) => {
        resolve({
          is_success: true,
          is_failed: false
        });
      })
      .catch((err) => {
        reject({
          is_success: false,
          is_failed: true
        });
      });
  });
};

export const getListCategory = () => {
  return new Promise((resolve, reject) => {
    fire
      .firestore()
      .collection("listCategory")
      .doc("category")
      .get()
      .then((res) => {
        let results = res.data().list;

        resolve({
          data: results,
          is_success: true,
          is_failed: false
        });
      })
      .catch((err) => {
        resolve({
          data: null,
          is_success: false,
          is_failed: true
        });
      });
  });
};
export const getKilas = (id) => {
  return new Promise((resolve, reject) => {
    try {
      fire
        .firestore()
        .collection("books")
        .doc(id)
        .collection("kilasan")
        .get()
        .then((res) => {
          let results = res.docs
            .filter((data) => data.id !== "fake")
            .map((data) => {
              return { ...data.data() };
            });

          resolve({
            data: results,
            is_success: true,
            is_failed: false
          });
        })
        .catch((err) => {
          resolve({
            data: null,
            is_success: false,
            is_failed: true
          });
        });
    } catch {
      resolve({
        data: null,
        is_success: false,
        is_failed: true
      });
    }
  });
};

export const editKilas = (book_id, value) => {
  let fireKilas = fire
    .firestore()
    .collection("books")
    .doc(book_id)
    .collection("kilasan");

  return new Promise((resolve, reject) => {
    if (Array.isArray(value)) {
      let filterValue = value.filter(
        (data) => data.title && data.details.length > 0
      );
      filterValue.map((data, index) => {
        fireKilas
          .doc(data.kilas.toString())
          .set({ ...data, details: data.details.filter((data) => data) })
          .then((res) => {
            if (index + 1 === filterValue.length) {
              fireKilas.doc("fake").delete();
              resolve({
                is_success: true,
                is_failed: false
              });
            }
          })
          .catch((err) => {
            reject({
              is_success: true,
              is_failed: false
            });
          });
      });
    }
  });
};

export const deleteBook = (book_id) => {
  let fireBook = fire.firestore().collection("books").doc(book_id);

  return new Promise((resolve, reject) => {
    fireBook
      .delete()
      .then((res) => {
        resolve({
          is_success: true,
          is_failed: false
        });
      })
      .catch((err) => {
        reject({
          is_success: false,
          is_failed: true
        });
      });
  });
};
