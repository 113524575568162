import React, { useContext, useEffect } from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import VpnKeyIcon from "@material-ui/icons/VpnKey";
import PeopleIcon from "@material-ui/icons/People";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import BookRoundedIcon from "@material-ui/icons/BookRounded";
import HelpIcon from "@material-ui/icons/Help";
import MentoringIcon from "@material-ui/icons/EmojiPeople";

// Custom components
import Button from "../Button";
import styles from "../../styles/HeaderLinksStyle";
import { secondaryColor } from "../../styles/Style";

//Firebase components
import fire from "../.././firebase/fire";
import { AuthContext } from "../Routing/Auth";
import { ContextReact } from "../../context";
import { setModalAuth } from "../../context/action";

const useStyles = makeStyles(styles);

export default function HeaderLinksMobile({ history }) {
  // Styles
  const header = useStyles();

  // Auth
  const { currentUser } = useContext(AuthContext);

  //Handle event to navigate to accounts page
  const goToAccounts = () => {
    // console.log(history);
    history.push(`/accounts`);
    window.scrollTo(0, 0);
  };

  //Handle event to navigate to pricing page
  const goToPricing = () => {
    // console.log(history);
    history.push(`/pricing`);
    window.scrollTo(0, 0);
  };

  //Handle event to navigate to pricing page
  const goToFAQ = () => {
    // console.log(history);
    history.push(`/faq`);
    window.scrollTo(0, 0);
  };

  //Handle event to navigate to pricing page
  const goToBlog = () => {
    history.push(`/artikel`);
    window.scrollTo(0, 0);
  };

  //Handle event to navigate to pricing page
  const goToMentoring = () => {
    history.push(`/mentoring`);
    window.scrollTo(0, 0);
  };

  const context = React.useContext(ContextReact);
  const { dispatch } = context;
  const openmodalAuth = (type) => {
    dispatch(setModalAuth({ type: type, modal: true }));
  };

  const page = window.location.pathname;
  const redirect = (type) => {
    const patchname = ["/signup", "/login"];
    if (patchname.includes(page)) {
      history.push("/" + type);
    } else {
      openmodalAuth(type);
    }
  };

  return (
    <div>
      {!!currentUser ? (
        <List className={header.list}>
          <ListItem className={header.listItem}>
            <Button fullWidth onClick={goToAccounts} round color="transparent">
              <div style={{ color: secondaryColor }}>
                <AccountCircleIcon /> Profil
              </div>
            </Button>
          </ListItem>

          <ListItem className={header.listItem}>
            <Button fullWidth onClick={goToMentoring} round color="transparent">
              <div style={{ color: secondaryColor }}>
                <MentoringIcon /> Group Mentoring
              </div>
            </Button>
          </ListItem>

          {/* <ListItem className={header.listItem}>
            <Button fullWidth onClick={goToFAQ} round color="transparent">
              <div style={{ color: secondaryColor }}>
                <HelpIcon /> FAQ
              </div>
            </Button>
          </ListItem> */}

          <ListItem className={header.listItem}>
            <Button fullWidth onClick={goToBlog} round color="transparent">
              <div style={{ color: secondaryColor }}>
                <BookRoundedIcon /> Artikel
              </div>
            </Button>
          </ListItem>

          <ListItem className={header.listItem}>
            <Button fullWidth onClick={goToPricing} round color="transparent">
              <div style={{ color: secondaryColor }}>
                <MonetizationOnIcon /> Harga
              </div>
            </Button>
          </ListItem>
        </List>
      ) : (
        <List className={header.list}>
          <ListItem className={header.listItem}>
            <Button
              onClick={() => {
                redirect("signup");
              }}
              fullWidth
              round
              color="transparent"
            >
              <div style={{ color: secondaryColor }}>
                <PeopleIcon /> Daftar
              </div>
            </Button>
          </ListItem>
          <ListItem className={header.listItem}>
            <Button
              onClick={() => {
                redirect("login");
              }}
              fullWidth
              round
              color="transparent"
            >
              <div style={{ color: secondaryColor }}>
                <VpnKeyIcon /> Login
              </div>
            </Button>
          </ListItem>

          <ListItem className={header.listItem}>
            <Button fullWidth onClick={goToMentoring} round color="transparent">
              <div style={{ color: secondaryColor }}>
                <MentoringIcon /> Group Mentoring
              </div>
            </Button>
          </ListItem>

          {/* <ListItem className={header.listItem}>
            <Button fullWidth onClick={goToFAQ} round color="transparent">
              <div style={{ color: secondaryColor }}>
                <HelpIcon /> FAQ
              </div>
            </Button>
          </ListItem> */}

          <ListItem className={header.listItem}>
            <Button fullWidth onClick={goToBlog} round color="transparent">
              <div style={{ color: secondaryColor }}>
                <BookRoundedIcon /> Artikel
              </div>
            </Button>
          </ListItem>

          <ListItem className={header.listItem}>
            <Button fullWidth onClick={goToPricing} round color="transparent">
              <div style={{ color: secondaryColor }}>
                <MonetizationOnIcon /> Harga
              </div>
            </Button>
          </ListItem>
        </List>
      )}
    </div>
  );
}
