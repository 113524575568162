import React from "react";

// form login & signup
import LoginForm from "./LoginForm";
import SignUpForm from "../SignUp/SignUpForm";

// context
import { ContextReact } from "../../context/index";
import { setModalAuth } from "../../context/action";

// material ui comp
import { IconButton, Paper } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import Dialog from "@material-ui/core/Dialog";

// component utama
const ModalDialogAuth = () => {
  // context
  const context = React.useContext(ContextReact);
  const { dispatch, data } = context;
  const { type, modal } = data.modalAuth;

  // close modal
  const closeModal = () => {
    dispatch(setModalAuth({ type, modal: false }));
  };

  // icon close modal
  const CompClose = () => {
    return (
      <div
        style={{
          textAlign: "right",
          marginBottom: "-15px",
        }}
      >
        <IconButton
          onClick={() => {
            closeModal();
          }}
          style={{ margin: "0px" }}
          aria-label="delete"
          size="small"
        >
          <CloseIcon fontSize="inherit" />
        </IconButton>
      </div>
    );
  };

  // switch modal login & signup
  const switchModal = (type) => {
    dispatch(setModalAuth({ type, modal: true }));
  };

  return (
    <Dialog scroll={"body"} open={modal} onClose={() => closeModal()}>
      <Paper
        style={{
          padding: 20,
          maxWidth: 350,
          height: "auto",
        }}
      >
        {type === "login" ? (
          <LoginForm
            switchModal={switchModal}
            IconCloseModal={() => <CompClose />}
            handleClose={() => closeModal()}
          />
        ) : (
          <SignUpForm
            switchModal={switchModal}
            IconCloseModal={() => <CompClose />}
          />
        )}
      </Paper>
    </Dialog>
  );
};

export default ModalDialogAuth;
