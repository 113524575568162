import React, { useContext, useEffect } from "react";

// @material-ui/core components
import { makeStyles, List, ListItem, Badge } from "@material-ui/core";
import LibraryBooksIcon from "@material-ui/icons/LibraryBooks";

// Custom components
import Button from "../Button";
import styles from "../../styles/HeaderLinksStyle";
import { secondaryColor } from "../../styles/Style";
import NavbarStyle from "../../styles/NavbarStyle";

//Firebase components
import fire from "../.././firebase/fire";
import { AuthContext } from "../Routing/Auth";

//Redux
import { useSelector } from "react-redux";
import { selectCart } from "../../feature/cartSlice";
import { ContextReact } from "../../context";
import { setModalAuth } from "../../context/action";
import { selectUser } from "../../feature/userSlice";

const useStyles = makeStyles(styles);

export default function HeaderLinks({ history }) {
  // Styles
  const header = useStyles();
  const classes = NavbarStyle();

  // Auth
  const { currentUser } = useContext(AuthContext);
  const userData = useSelector(selectUser);

  // Redux
  const cart = useSelector(selectCart).cart;

  //Handle event to navigate to accounts page
  const goToAccounts = () => {
    history.push(`/accounts`);
    window.scrollTo(0, 0);
  };

  //Handle event to navigate to pricing page
  const goToPricing = () => {
    history.push(`/pricing`);
    window.scrollTo(0, 0);
  };

  //Handle event to navigate to pricing page
  const goToFAQ = () => {
    history.push(`/faq`);
    window.scrollTo(0, 0);
  };

  //Handle event to navigate to pricing page
  const goToBlog = () => {
    history.push(`/artikel`);
    window.scrollTo(0, 0);
  };

  //Handle event to navigate to pricing page
  const goToMentoring = () => {
    history.push(`/mentoring`);
    window.scrollTo(0, 0);
  };

  const context = React.useContext(ContextReact);
  const { dispatch, data } = context;

  const openModalAuth = (type) => {
    dispatch(setModalAuth({ type: type, modal: true }));
  };

  const page = window.location.pathname;
  const redirect = (type) => {
    const patchname = ["/signup", "/login"];
    if (patchname.includes(page)) {
      history.push("/" + type);
    } else {
      openModalAuth(type);
    }
  };

  return (
    <div>
      {!!currentUser ? (
        <List className={header.list}>

          {/* <ListItem className={header.listItem}>
            <Button onClick={goToPricing} round color="transparent">
              <div style={{ color: secondaryColor }}>Harga</div>
            </Button>
          </ListItem> */}

          {userData.user.is_subscribed && currentUser ? (
            <>
              <ListItem className={header.listItem}>
                <Button onClick={goToBlog} round color="transparent">
                  <div style={{ color: secondaryColor }}>Artikel</div>
                </Button>
              </ListItem>

              <ListItem className={header.listItem}>
                <Button onClick={goToMentoring} round color="transparent">
                  <div style={{ color: secondaryColor }}>Mentoring</div>
                </Button>
              </ListItem></>) : (
            <>
              <ListItem className={header.listItem}>
                <Button onClick={goToPricing} round color="transparent">
                  <div style={{ color: secondaryColor }}>Berlanggan Sekarang</div>
                </Button>
              </ListItem>
            </>)}

          <ListItem className={header.listItem}>
            <Button onClick={goToAccounts} round color="transparent">
              <div style={{ color: secondaryColor }}>Profil</div>
            </Button>
          </ListItem>

          <ListItem style={{ display: "flex" }} className={header.listItem}>
            <Button
              onClick={() => {
                history.push("/library");
                window.scrollTo(0, 0);
              }}
              round
              color="primary"
            >
              <div style={{ color: secondaryColor }}>
                <LibraryBooksIcon /> My Library
              </div>
            </Button>
          </ListItem>
        </List>
      ) : (
        <List className={header.list}>
          <ListItem className={header.listItem}>
            <Button onClick={goToBlog} round color="transparent">
              <div style={{ color: secondaryColor }}>Artikel</div>
            </Button>
          </ListItem>

          {/* <ListItem className={header.listItem}>
            <Button onClick={goToFAQ} round color="transparent">
              <div style={{ color: secondaryColor }}>FAQ</div>
            </Button>
          </ListItem> */}

          <ListItem className={header.listItem}>
            <Button onClick={goToMentoring} round color="transparent">
              <div style={{ color: secondaryColor }}>Mentoring</div>
            </Button>
          </ListItem>

          {/* <ListItem className={header.listItem}>
            <Button onClick={goToPricing} round color="transparent">
              <div style={{ color: secondaryColor }}>Harga</div>
            </Button>
          </ListItem> */}

          <ListItem className={header.listItem}>
            <Button
              onClick={() => {
                goToPricing();
              }}
              round
              color="transparent"
            >
              <div style={{ color: secondaryColor }}>Berlanggan Sekarang</div>
            </Button>
          </ListItem>

          <ListItem className={header.listItem}>
            <Button
              onClick={() => {
                redirect("login");
              }}
              round
              color="primary"
            >
              <div style={{ color: secondaryColor }}>Login</div>
            </Button>
          </ListItem>
        </List>
      )}
    </div>
  );
}
